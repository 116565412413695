import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CoreFeature } from 'src/app/shared/constants/enums';
import { PeriodService } from 'src/app/shared/services/period.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SellerService } from 'src/app/shared/services/seller.service';
import { XactionService } from 'src/app/shared/services/xaction.service';

@Component({
	selector: 'app-core-custom-tool-panel',
	templateUrl: './core-custom-tool-panel.component.html',
	styleUrls: ['./core-custom-tool-panel.component.scss']
})
export class CoreCustomToolPanelComponent implements IToolPanelAngularComp {
	gridApi: GridApi;
	columnApi: ColumnApi;
	deleteProcedure: () => any;
	addRowProcedure: () => any;
	duplicateRowProcedure: () => any;
	finalizeEdits: () => any;
	showBulkEditPopup: () => any;
	showPeriodFilterRangePopup: () => any;
	showTraceOutputPopup: () => any;
	showExportOutputPopup: (withImport: boolean) => any;
	openDiagramAudit: () => any;
	toggleTheme: (e: boolean) => any;
	toggleMode: (e: boolean) => any;
	refreshProcedure: () => any;
	filterBuilderValueChanged: (value, fromLayoutChange, fromToolPanelChange) => any;
	isPeriodLocked: boolean = false;
	isGridEditable: BehaviorSubject<boolean> = new BehaviorSubject(true);
	isAddingAllowed: BehaviorSubject<boolean> = new BehaviorSubject(true);
	isDeletingAllowed: BehaviorSubject<boolean> = new BehaviorSubject(true);
	isBulkEditAllowed: BehaviorSubject<boolean> = new BehaviorSubject(true);
	datasourceId: number;
	functionId: number;
	isProcessedOutput: boolean;
	columnState: any;
	darkMode: boolean = false;
	rapidLoadModeToggle: boolean = true;
	loadAllModeWarningRowCount: number;
	filteredRecordCount: number;
	hasPendingEdits: boolean;
	traceOutputDisabled: boolean = true;
	diagramAuditDisabled: boolean = true;
	permissionAddRecord: boolean;
	permissionDeleteRecord: boolean;
	permissionEditRecord: boolean;
	exportAllOptions: any[] = [{ name: 'With Import' }];

	private _rapidLoadMode: boolean = true;

	constructor(private permissionService: PermissionService, private sellerService: SellerService) {}

	async agInit(params): Promise<void> {
		await this.permissionService.initializePermissions().then(x => {
			if (params.isProcessedOutput){
				this.permissionDeleteRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteProcessedRecords.toString());
				this.permissionAddRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.AddProcessedRecords.toString());
				this.permissionEditRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.EditProcessedRecords.toString());
			}
			else{
				this.permissionDeleteRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteImportedRecords.toString());
				this.permissionAddRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.AddImportedRecords.toString());
				this.permissionEditRecord = this.permissionService.checkCurrentUserPermission(CoreFeature.EditImportedRecords.toString());
			}
		}).then(x => {
		this.isProcessedOutput = params.isProcessedOutput;
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.datasourceId = params.datasourceId;
		this.functionId = params.functionId;
		this._rapidLoadMode = params.rapidLoadMode === true;
		this.rapidLoadModeToggle = params.rapidLoadMode === true;
		this.loadAllModeWarningRowCount = params.loadAllModeWarningRowCount;
		this.isGridEditable = new BehaviorSubject<boolean>((this.permissionEditRecord || this.permissionAddRecord || this.permissionDeleteRecord) && !this.isProcessedOutput);
		this.isAddingAllowed = new BehaviorSubject<boolean>(this.permissionAddRecord && !this.isProcessedOutput);
		this.isDeletingAllowed = new BehaviorSubject<boolean>(this.permissionDeleteRecord);
		this.isBulkEditAllowed = new BehaviorSubject<boolean>(this.permissionEditRecord);
		this.showBulkEditPopup = params.showBulkEditPopup;
		this.deleteProcedure = params.deleteProcedure;
		this.addRowProcedure = params.addRowProcedure;
		this.finalizeEdits = params.finalizeEdits;
		this.toggleTheme = params.toggleTheme;
		this.toggleMode = params.toggleMode;
		this.duplicateRowProcedure = params.duplicateRowProcedure;
		this.refreshProcedure = params.refreshProcedure;
		this.showPeriodFilterRangePopup = params.showPeriodFilterRangePopup;
		this.showTraceOutputPopup = params.showTraceOutputPopup;
		this.showExportOutputPopup = params.showExportOutputPopup;
		this.openDiagramAudit = params.openDiagramAudit;
		this.filterBuilderValueChanged = params.filterBuilderValueChanged;

		params.valueChangedEvent.subscribe((value: boolean) => {
			this.hasPendingEdits = value;
		});
		params.traceOutputDisabledChangedEvent.subscribe((value: boolean) => {
			this.traceOutputDisabled = value;
		});
		params.diagramAuditDisabledChangedEvent.subscribe((value: boolean) => {
			this.diagramAuditDisabled = value;
		});
	});
	}

	clearFilters() {
		this.filterBuilderValueChanged([], false, true);
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
	}

	clearGroups() {
		this.columnApi.resetColumnState();
		this.columnApi.resetColumnGroupState();
	}

	confirmToggleMode(e: any): void {
		if (e.value !== undefined && e.value !== this._rapidLoadMode) {
			if (!e.value && this.filteredRecordCount > this.loadAllModeWarningRowCount) {
				let msg = 'Are you sure you want to switch to Load All mode? ';
				msg += `This could take several minutes due to the large number of rows (${String.format('{0:#,0}', this.filteredRecordCount)}).`;
				confirm(msg, 'Load All Mode').then(response => {
					this.handleToggleModeConfirmation(e.value, response);
				});
			} else {
				this.handleToggleModeConfirmation(e.value, true);
			}
		}
	}

	handleToggleModeConfirmation(value: boolean, response: boolean): void {
		this.rapidLoadModeToggle = value || !response;
		if (response && this.rapidLoadModeToggle !== this._rapidLoadMode) {
			this._rapidLoadMode = this.rapidLoadModeToggle;
			this.toggleMode(this._rapidLoadMode);
		}
	}
}
