import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { DxDataGridComponent, DxToolbarComponent } from 'devextreme-angular';
import { GridProps } from '../../models/core-data-grid-properties';
import { CoreColumn } from '../../models/core-column';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CoreDynamicCellEditorProps } from '../../models/core-dynamic-cell-editor-props';
import { HelperService } from '../../services/helper.service';
import { CoreDataGridEditTemplates, CoreDataGridCellTemplates, DynamicCellEditorTemplates, ScrollingModes, EditModes, devExtremeDMLStrings } from '../../constants/dev-extreme-enums';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupStep } from '../../models/core-popup-step';
import { CoreDynamicInputProperties } from '../../models/core-dynamic-input-properties';
import { toastrConstants } from '../../constants/constants';
import { UiViewService } from '../../services/ui-view.service';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { UiView } from '../../models/ui-view';
import { CorePopupComponent } from '../core-popup/core-popup.component';
import { CoreDropdownItem } from '../../models/core-dropdown-item';
import { ToastrService } from 'ngx-toastr';
import Guid from 'devextreme/core/guid';
import { off, on } from 'devextreme/events';
import DataGrid from 'devextreme/ui/data_grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { AuthService } from '../../services/auth.service';
import { coreComponentInfo, coreResponseCodes } from '../../constants/enums';
import CustomStore from 'devextreme/data/custom_store';
import { ServerSideDataProcessingService } from '../../services/server-side-data-processing.service';
import Popup from 'devextreme/ui/popup';
import { AppElementsService } from '../../services/app-element.service';

(DataGrid as any).registerModule('columnChooserSorting', {
    extenders: {
        controllers: {
            columns: {
                getChooserColumns(loadAllColumns: boolean) {
                    const result = this.callBase(loadAllColumns);
                    return result.sort((column1, column2) => column1.caption.localeCompare(column2.caption));
                }
            }
        }
    }
});

@Component({
    selector: 'core-data-grid',
    templateUrl: './core-data-grid.component.html',
    styleUrls: ['./core-data-grid.component.scss']
})

export class CoreGridComponent implements OnInit {
    @Input() set dataSource(value: any[]) {
        this._dataSource = value;
        this.dataStore = value;
    }
    @Input() columns: CoreColumn[] = [];
    @Input() editColumns: CoreColumn[] = [];
    @Input() props: GridProps = new GridProps();

    // Non-required inputs
    @Input() focusedRowKey: any;
    @Input() parentTemplate: any;

    @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
    @ViewChild('footerToolbar', { static: false }) footerToolbar: DxToolbarComponent;
    @ViewChild('coreDataGridPopup', { static: false }) coreDataGridPopup: CorePopupComponent;

    dataStore: any[] | CustomStore;
    helperService: HelperService = new HelperService();
    dataGridPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly('500', '250', true, '');
    dataGridPopupSteps: CorePopupStep[] = [];
    initialLayoutApplied: boolean = false;
    uiViews: UiView[] = [];
    dummyLayout: UiView = new UiView().createDummy();
    toastConsts = toastrConstants;
    indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';
    timerId: any;
    revertButton: any;
    bulkDeleteButton: any;
    duplicateButton: any;
    footerToolbarItems: any[];
    DUPLICATED_ROW: string = coreComponentInfo.DuplicatedRow;
    hoverTooltipAnimation: any = {
        show: {
            type: 'fade',
            from: 0,
            to: 1,
            delay: 1000
        }
    };
    focusedRowData: any;

    private _dataSource: any[] = [];

    constructor(private uiViewService: UiViewService,
        private appElementsService: AppElementsService,
        private serverSideDataProcessingService: ServerSideDataProcessingService,
        private toast: ToastrService, private authService: AuthService) {
        this.onCoreGridButtonClick = this.onCoreGridButtonClick.bind(this);
        this.onCoreGridButtonItemClick = this.onCoreGridButtonItemClick.bind(this);
        this.dragHandler = this.dragHandler.bind(this);
    }

    @HostListener('document:keydown.control.c', ['$event'])
    copyShortcutHandler(event: KeyboardEvent) {
        const doc = document as any;
        if (!this.helperService.isNullOrEmpty(this.props.gridId)) {
            const gridElement = doc.querySelector(`#${this.props.gridId}`);
            const isGridElementFocused = gridElement?.contains(document.activeElement);
            const isGridCellBeingEdited = gridElement?.querySelector(`#${this.props.gridId} .dx-datagrid-rowsview td.dx-editor-cell.dx-focused`) !== null;
            const hasSelectedRows = this.grid.selectedRowKeys?.length > 0;

            if (isGridElementFocused && hasSelectedRows && isGridCellBeingEdited !== true) {
                if (!doc.queryCommandSupported('copy')) {
                    this.toast.error('Copy command is not supported by the browser.');
                    return;
                }

                const copyData = this.getSelectedRowDataWithHeadersForCopy();
                const textAreaElement = this.getHiddenTextAreaElementForCopy(copyData);

                try {
                    const successful = doc.execCommand('copy');
                    if (successful) {
                        this.toast.success(`${this.props.gridLabel} data copied to the clipboard`);
                    } else {
                        throw new Error();
                    }
                } catch (err) {
                    this.toast.error('Unable to copy data to clipboard');
                }

                doc.body.removeChild(textAreaElement);
                event.preventDefault();
            } else {
                // Copy whatever the user was initially trying to copy
                if (doc.queryCommandSupported('copy')) {
                    try {
                        doc.execCommand('copy');
                    } catch (err) {
                        this.toast.error('Default Ctrl+C copy attempt failed');
                    }
                }
            }
        }
    }

    ngOnInit() {
        if (this.props.isServerSideDataProcessingEnabled) {
            this.configureServerSideDataProcessing();
        }
    }

    getPopupFields(): CoreColumn[] {
        return this.columns ? this.columns.concat(this.editColumns ? this.editColumns : [])
            : (this.editColumns ? this.editColumns : []);
    }

    onContentReady(e: any): void {
        if (this.revertButton) {
            this.revertButton.option('disabled', !e.component.hasEditData());
        }

        if (this.props.isCommandColumnLeft) {
            e.component.columnOption('command:edit', 'visibleIndex', -1);
        }

        // auto scroll for moving column logic
        const headers = e.element.querySelectorAll('.dx-header-row > td');
        const t = this;
        headers.forEach((element) => {
            off(element, 'dxdrag', t.dragHandler);
            on(element, 'dxdrag', { component: e.component }, t.dragHandler);
        });

        // Set position of the column chooser
        // TODO: if/when we upgrade to DevExpress 23, should remove this and use the new column chooser properties to accomplish this
        const columnChooserView = e.component.getView('columnChooserView');
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option('position', { of: e.element, my: 'center top', at: 'center top', offset: { y: 70 }});
        }

        // Set dragAndResizeArea of the column filter popups - to avoid weird drag/resize behavior
        setTimeout(() => {
            const doc = document as any;
            const headerFilters = [ ...doc.querySelectorAll('.dx-header-filter') ];
            headerFilters.forEach(headerFilterElement => {
                headerFilterElement.addEventListener('click', () => {
                    requestAnimationFrame(() => {
                        const popupElement = doc.querySelector('.dx-header-filter-menu.dx-popup');
                        if (popupElement) {
                            const dxViewport = doc.querySelector('.dx-viewport');
                            const popup = Popup.getInstance(popupElement) as Popup;
                            popup.option({
                                dragEnabled: true,
                                dragAndResizeArea: dxViewport,
                                showTitle: true
                            });
                        }
                    });
                });
            });
        }, 1000);

        if (this.props.onContentReadyFunction) {
            this.props.onContentReadyFunction(new CoreEventArguments(e, null, this));
        }
    }

    onToolbarPreparing(e: any): void {
        if (this.props.isAddingAllowed || this.props.isDeletingAllowed || this.props.isUpdatingAllowed) {
            const revertButtonIndex = e.toolbarOptions.items.findIndex(x => x.name === 'revertButton');
            if (revertButtonIndex > 0  && this.props.editMode === EditModes.Batch) {
                e.toolbarOptions.items[revertButtonIndex] = {
                    widget: 'dxButton',
                    showText: 'inMenu',
                    name: 'revertButton',
                    location: 'after',
                    locateInMenu: 'auto',
                    sortIndex: 22,
                    options: {
                        icon: 'undo',
                        disabled: true,
                        hint: 'Discard Changes',
                        onInitialized: (event: any) => {
                            this.revertButton = event.component;
                        },
                        onClick: (event: any) => {
                            this.grid.instance.cancelEditData(); // this reverts the data
                            event.component.option('disabled', true);
                        }
                    }
                };
            }
        }

        if (this.props.isRowSelectionVisible) {
            e.toolbarOptions.items.unshift({
                    location: 'left',
                    widget: 'dxButton',
                    name: 'columnSelection',
                    locateInMenu: 'never',
                    options: {
                        icon: 'selectall',
                        hint: 'Show/Hide row selection',
                        onClick: (event: any) => {
                            this.props.isRowSelectionEnabled = !this.props.isRowSelectionEnabled;

                            if (this.duplicateButton) {
                                this.duplicateButton.option('disabled', !this.props.isRowSelectionEnabled);
                            }

                            if (this.bulkDeleteButton) {
                                this.bulkDeleteButton.option('disabled', !this.props.isRowSelectionEnabled);
                            }
                        }
                    }
                }
            );
        }

        const elementAttributes = {
            class: 'custom-toolbar-text-button'
        };

        if (this.props.isDuplicateEnabled && this.props.editMode === EditModes.Batch) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    widget: 'dxButton',
                    name: 'duplicate',
                    locateInMenu: 'never',
                    options: {
                        text: 'Duplicate',
                        hint: 'Duplicates selected rows',
                        disabled: !this.props.isAddingAllowed,
                        stylingMode: 'outlined',
                        elementAttr: elementAttributes,
                        onInitialized: (event: any) => {
                            this.duplicateButton = event.component;
                        },
                        onClick: this.duplicateSelectedRows.bind(this)
                    }
                }
            );
        }

        if (this.props.isBulkEditEnabled && this.props.editMode === EditModes.Batch && !this.props.hideBulkEditButton) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    widget: 'dxButton',
                    name: 'bulkEdit',
                    locateInMenu: 'never',
                    options: {
                        text: 'Bulk Edit',
                        disabled: !this.props.isUpdatingAllowed,
                        stylingMode: 'outlined',
                        elementAttr: elementAttributes,
                        onClick: this.openAndPopulateBulkEditPopup.bind(this)
                    }
                }
            );
        }

        if (this.props.isBulkDeleteEnabled && this.props.editMode === EditModes.Batch) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    widget: 'dxButton',
                    name: 'bulkDelete',
                    locateInMenu: 'never',
                    options: {
                        text: 'Bulk Delete',
                        hint: 'Deletes selected rows',
                        disabled: !this.props.isDeletingAllowed,
                        stylingMode: 'outlined',
                        elementAttr: elementAttributes,
                        onInitialized: (event: any) => {
                            this.bulkDeleteButton = event.component;
                        },
                        onClick: this.deleteSelectedRows.bind(this)
                    }
                }
            );
        }

        if (this.props.hideSaveButton) {
            e.toolbarOptions.items.forEach(item => {
                if (item.name === 'saveButton') {
                    item.visible = false;
                }
            });
        }

        if (this.props.isLayoutsEnabled && this.props.uiViewContextCode) {
            const layoutToolbarItem = {
                    location: 'after',
                    widget: 'dxButton',
                    name: 'layouts',
                    locateInMenu: 'never',
                    options: {
                        text: 'Layouts',
                        stylingMode: 'outlined',
                        elementAttr: elementAttributes,
                        onClick: this.openLayoutsPopup.bind(this)
                    }
                };

            if (!this.props.displayLayoutsInFooter) {
                e.toolbarOptions.items.unshift(layoutToolbarItem);
            } else {
                if (!this.props.footerToolbarItems) {
                    this.props.footerToolbarItems = [];
                }
                this.props.footerToolbarItems = this.props.footerToolbarItems.filter(x => x.name !== 'layouts');
                this.props.footerToolbarItems.unshift(layoutToolbarItem);
            }
        }

        if (this.props.toolBarItems?.length > 0) {
            for (const toolBarItem of this.props.toolBarItems) {
                e.toolbarOptions.items.unshift(toolBarItem);
            }
        }
    }

    onCoreGridButtonClick(e: any, data?: any): void {
        if (e && e.column && e.column.dataField && e.column.type === 'buttons'
            && this.columns.find(x => x.dataField === e.column.dataField)) {
            const clickFunction = this.columns.find(x => x.dataField === e.column.dataField).columnType.clickFunction;

            if (clickFunction) {
                clickFunction(new CoreEventArguments(e, null));
            }
        } else if (data && data.data && data.column) {
            const column = this.columns.find(x => x.dataField === data.column.dataField);
            if (column && column.columnType && column.columnType.clickFunction) {
                column.columnType.clickFunction(new CoreEventArguments(data, null));
            }
        }
    }

    onCoreGridButtonItemClick(e: any, data?: any): void {
        if (data && data.data && data.column) {
            const column = this.columns.find(x => x.dataField === data.column.dataField);
            if (column && column.columnType && column.columnType.itemClickFunction) {
                column.columnType.itemClickFunction(new CoreEventArguments(e, null, data));
            }
        }
    }

    onOptionChanged(e: any): void {
        if (this.props.onOptionChangedFunction) {
            this.props.onOptionChangedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowValidating(e: any): void {
        if (this.props.onRowValidatingFunction) {
            this.props.onRowValidatingFunction(new CoreEventArguments(e, null, this));
        }
    }

    onInitialized(e: any): void {
        // Allow the column chooser to be moved/dragged by user
        // TODO: if/when we upgrade to DevExpress 23, should remove this and use the new column chooser properties to accomplish this
        e.component.option('columnChooser', {
            container: '.dx-viewport'
        });

        if (this.props.onInitializedFunction) {
            this.props.onInitializedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onInitNewRow(e: any): void {
        if (this.props.onInitNewRowFunction) {
            this.props.onInitNewRowFunction(new CoreEventArguments(e, null, this));
        }
    }

    onEditingStart(e: any): void {
        if (this.props.onEditingStartFunction) {
            this.props.onEditingStartFunction(new CoreEventArguments(e, null, this));
        }
    }

    onEditCanceled(e: any): void {
        if (this.props.onEditCanceledFunction) {
            this.props.onEditCanceledFunction(new CoreEventArguments(e, null, this));
        }
    }

    onSaving(e: any): void {
        if (this.props.dataChangesFunction) {
            this.props.dataChangesFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowPrepared(e: any): void {
        if (this.props.onRowPreparedFunction) {
            this.props.onRowPreparedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowInserting(e: any): void {
        if (this.props.onRowInsertingFunction) {
            this.props.onRowInsertingFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowInserted(e: any): void {
        if (this.props.onRowInsertedFunction) {
            this.props.onRowInsertedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowUpdating(e: any): void {
        if (this.props.onRowUpdatingFunction) {
            this.props.onRowUpdatingFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowUpdated(e: any): void {
        if (this.props.onRowUpdatedFunction) {
            this.props.onRowUpdatedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowRemoving(e: any): void {
        if (this.props.onRowRemovingFunction) {
            this.props.onRowRemovingFunction(new CoreEventArguments(e, null, this));
        }
    }

    onRowRemoved(e: any): void {
        if (this.props.onRowRemovedFunction) {
            this.props.onRowRemovedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onEditorPreparing(e: any): void {
        const priorOnValueChanged = e.editorOptions.onValueChanged;
        e.editorOptions.onValueChanged = (event: any) => {
            priorOnValueChanged(event);
            if (this.revertButton) {
                this.revertButton.option('disabled', !e.component.hasEditData());
            }
        };

        // For lookup fields, this sets the hover text of each item in dropdown
        if (e.parentType === 'dataRow') {
            const column = this.columns.find(x => x.dataField === e.dataField && x.columnType?.columnType === 'lookup');
            if (column) {
                e.editorOptions.itemTemplate = (itemData, itemIndex, itemElement) => {
                    itemElement.innerText = itemData[column.columnType.lookupDisplayValue];
                    itemElement.setAttribute('title', itemData[column.columnType.lookupDisplayValue]);
                };
            }
        }

        if (this.props.onEditorPreparingFunction) {
            this.props.onEditorPreparingFunction(new CoreEventArguments(e, null, this));
        }
    }

    onSelectionChanged(e: any): void {
        this.checkForAndAssignValueToCellForDynamicEditorGrid(e);

        if (this.props.onSelectionChangedFunction) {
            this.props.onSelectionChangedFunction(new CoreEventArguments(e, null, this));
        }
    }

    onFocusedRowChanged(e: any): void {
        if (this.props.onFocusedRowChangedFunction) {
            this.props.onFocusedRowChangedFunction(new CoreEventArguments(e, null, this));
        }

        if (e.row?.data) {
            this.focusedRowData = e.row.data;
        }
    }

    onKeyDown(e: any): void {
        if (this.props.isTableSelectCellEditorPopup && e.event.keyCode === 13) {
            // When the user hits ENTER, select the item and close the editor
            this.appElementsService.provideCoreTableSelectValueChanged(this.focusedRowData.name);
        } else if (this.props.isTableSelectCellEditorPopup && e.event.keyCode === 27) {
            // When the user hits ESCAPE, close the editor without changing the selection
            this.appElementsService.provideCoreTableSelectValueChanged('{ESCAPE}');
        }
    }

    getColumnByDataField(dataField: string, data?: any): CoreColumn {
        if (dataField) {
            const column = this.columns.filter(x => x.dataField === dataField);
            if (column && column.length === 1) {
                return column[0];
            }
        }
    }

    getDataForCellDropDownButton(column: CoreColumn, datapoint: any): any[] {
        if (column.columnType.cellTemplateName === CoreDataGridCellTemplates.DropDownButtonCellTemplate && column.columnType.getDataFunction) {
            return column.columnType.getDataFunction(column, datapoint);
        }
    }

    getColumnEditorProps(cellInfo: any): CoreDynamicCellEditorProps {
        if (cellInfo && cellInfo.column && cellInfo.column.dataField !== undefined && cellInfo.column.dataField !== null) {
            const column = this.getColumnByDataField(cellInfo.column.dataField);
            if (column) {
                return column.getEditorPropsForCellFunction(cellInfo, column, column.isDataSourceReturnedToCellEditor ? this._dataSource : null,
                    column.additionalColumnData ? column.additionalColumnData : null);
            }
        }
    }

    dynamicCellEditorValueChange(event: any, cellInfo: any, editorProps?: CoreDynamicCellEditorProps): void {
        if (event && event.value !== undefined && cellInfo && cellInfo.setValue) {
            let value = event.value;

            if (editorProps.template === DynamicCellEditorTemplates.DropDownTableTemplate) {
                const key = editorProps.mockCoreColumn.columnType.lookupValue;
                if(!editorProps.mockCoreColumn.columnType.lookupData.some(lookup => lookup[key] === value)) {
                    value = null;
                }
            }

            if (editorProps.template === DynamicCellEditorTemplates.DatetimeTemplate) {
                const dateString = value instanceof Date ? String.format('{0:d}', value) : value;
                const dateValue = this.helperService.parseDate(dateString, false, false, true);
                value = this.helperService.formatDate(dateValue, editorProps.mockCoreColumn.format);
            }

            if (event.closeEditor === true) {
                // This will close the editor and allow the user to continue keyboard-navigating through the grid
                setTimeout(() => {
                    cellInfo.component.saveEditData();
                });
            }

            cellInfo.setValue(value);
        }
    }

    setGridLayout(newView: UiView = null): void {
        if (this.props) {
            if (!newView && !this.props.uiViewId) {
                newView = this.dummyLayout;
            }

            if (newView) {
                this.setGridLayoutState(newView.layoutString1 ? JSON.parse(newView.layoutString1) : {});
            } else {
                this.uiViewService.getUiViewById(this.props.uiViewId).subscribe(uiView => {
                    this.setGridLayoutState(JSON.parse(uiView?.layoutString1));
                });
            }
            this.initialLayoutApplied = true;
        }
    }

    setGridLayoutState(state: any): void {
        if (!state.selectedRowKeys) {
            state.selectedRowKeys = [];
        }
        if (!state.searchText || state.searchText === '') {
            // This currently does not clear previous searchText values - a DevExpress bug has been reported:
            // https://supportcenter.devexpress.com/ticket/details/t1046195/datagrid-the-search-panel-text-is-applied-in-state-storing-when-using-state-method
            state.searchText = '';

            // Workaround
            this.grid.instance.option('searchPanel.text', null);
        }
        if (!state.filterValue) {
            state.filterValue = null;
        }
        if (state.columns) {
            state.columns.forEach(column => {
                if (!column.filterValues) {
                    column.filterValues = [];
                }
            });
        }
        this.grid.instance.state(state);
        if (this.props.loadGridState) {
            this.props.loadGridState(state);
        }
    }

    onExporting(event: any) {
        event.fileName = this.props.exportFileName ? this.props.exportFileName : 'DataExport';
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet');

        exportDataGrid({
            component: event.component,
            worksheet,
            customizeCell: ({ gridCell, excelCell }) => {

                // removes the total footers from excel export
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.value = null;
                }
            }
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), event.fileName + '.xlsx');
            });
        });

        event.cancel = true;
    }

    repaintFooterToolbar(): void {
        this.footerToolbar.instance.repaint();
    }

    // BEGIN must be an arrow function to work properly
    loadState = () => {
        if (this.props.isStateSaveLocal) {
            const state = JSON.parse(localStorage.getItem(this.authService.getUserFromToken().split('\\')[0] + '-' + this.props.gridId + '-state'));
            if (state) {
                state.filterValue = [];
                this.grid.instance.state(state);
                if (this.props.loadGridState) {
                    this.props.loadGridState(state);
                }
                this.initialLayoutApplied = true;
            }
        }
    };

    saveState = (state) => {
        if (this.props.isStateSaveLocal) {
            this.saveStateLocal(state);
        } else if (this.props.isLayoutsEnabled && !this.initialLayoutApplied) {
            this.setGridLayout();
        }
    };

    triggerSaveState(): void {
        const state = this.grid.instance.state();
        this.saveStateLocal(state);
    }

    saveStateLocal(state: any) {
        if (this.props.saveGridState) {
            state = this.props.saveGridState(state);
        }
        localStorage.setItem(this.authService.getUserFromToken().split('\\')[0] + '-' + this.props.gridId + '-state', JSON.stringify(state));
    }

    toggleDataSourceType(isServerSideDataProcessing: boolean): void {
        this.props.isServerSideDataProcessingEnabled = isServerSideDataProcessing;
        if (isServerSideDataProcessing) {
            this.configureServerSideDataProcessing();
        } else {
            this.configureClientSideDataProcessing();
        }
    }

    // binds extra logic to delete button click
    onCellPrepared = (e) => {
        if (e.rowType === 'data' && e.column.command === 'edit') {
            const editLink = e.cellElement.querySelector('.dx-link-delete');
            off(editLink, 'dxclick');
            on(editLink, 'dxclick', (args) => {
            });
        }
        if (this.props.deleteEnabledColumn) {
            if (e.rowType === 'data' && e.column.command === 'edit' && e.data[this.props.deleteEnabledColumn] === false) {
                e.cellElement.querySelector('.dx-link-delete').remove();
            }
        }
    };
    // END must be an arrow function to work properly

    repaint() {
        this.grid.instance.repaint();
    }

    refreshSSDP() {
        if (this.props.isServerSideDataProcessingEnabled) {
            this.configureServerSideDataProcessing();
        }
    }

    getGroupCellText(data: any): string {
        const coreColumn: CoreColumn = this.columns.find(x => x.dataField === data.column.name);
        return coreColumn.customizeGroupCellTextFunction(data, coreColumn);
    }

    populateLayouts(): void {
        if (this.uiViews && this.uiViews.length < 1) {
            this.uiViewService.getUiViewsByContextCode(this.props.uiViewContextCode).subscribe(uiViews => {
                this.uiViews.push(this.dummyLayout);
                if (this.props.uiViewId === null || this.props.uiViewId === undefined) {
                    this.props.uiViewId = this.uiViews[0].id;
                }

                this.props.uiViewFilters.forEach(filter => {
                    uiViews.filter(view => view[filter.propertyName] === filter.propertyValue).forEach(view => {
                        if (!this.uiViews.find(x => x.id === view.id)) {
                            this.uiViews.push(view);
                        }
                    });
                });
                this.populateLayoutsPopupDropdown();
            });
        } else {
            this.populateLayoutsPopupDropdown();
        }
    }

    private checkForAndAssignValueToCellForDynamicEditorGrid(e: any): void {
        if (e.selectedRowKeys
            && this.parentTemplate
            && this.parentTemplate.cellInfo
            && this.parentTemplate.cellInfo.setValue
            && this.parentTemplate.editorProps
            && this.parentTemplate.editorProps.mockCoreColumn
            && this.parentTemplate.editorProps.mockCoreColumn.columnTemplateProps
            && this.parentTemplate.parentTemplate
            && this.parentTemplate.parentTemplate.component) {

            this.parentTemplate.cellInfo.setValue(e.selectedRowKeys[0]);
            if (this.parentTemplate.parentTemplate.component.close
                && this.parentTemplate.editorProps.mockCoreColumn.columnTemplateProps.isClosedOnSelection) {

                this.parentTemplate.parentTemplate.component.close();
            }
        } else if (e.selectedRowKeys && this.parentTemplate && this.parentTemplate.itemProps
            && this.parentTemplate.itemProps.hasOwnProperty('selectedValue')) {

            this.parentTemplate.itemProps.selectedValue = e.selectedRowKeys[0];
            this.parentTemplate.parentTemplate.component.close();
        } else if (e.selectedRowKeys && this.parentTemplate && this.parentTemplate.callbackArrowFunction) {
            this.parentTemplate.callbackArrowFunction(e.selectedRowKeys[0]);
            this.parentTemplate.parentTemplate.component.close();
        }
    }

    private duplicateSelectedRows(): void {
        throw new Error('Broken by Devextreme 21.1, 10/28/2021');

        if (this.grid.selectedRowKeys?.length < 1) {
            this.toast.warning('Please select rows before clicking duplicate');
        } else {
            const insertType = devExtremeDMLStrings.Insert;
            const updateType = devExtremeDMLStrings.Update;
            const rowCount = this.grid.selectedRowKeys.length;
            let counter = 1;
            for (const rowKey of this.grid.selectedRowKeys) {
                counter++;
                const isKey = (this.props.keyColumn !== null && this.props.keyColumn !== undefined);
                const rowIndex: number = (this.grid.dataSource as any[]).findIndex(x => (isKey ? x[this.props.keyColumn] === rowKey : x === rowKey));
                const cloned = {...this.grid.dataSource[rowIndex]};

                if (this.grid.editing.changes.some(x => x.type === updateType && x.key === rowKey)) {
                    const updatedValues = this.grid.editing.changes.find(x => x.type === updateType && x.key === rowKey).data;
                    Object.keys(updatedValues).forEach(prop => {
                        cloned[prop] = updatedValues[prop];
                    });
                }

                if (isKey) {
                    cloned[this.props.keyColumn] = null;
                }

                const newRowAdded = this.grid.instance.addRow();
                newRowAdded.then(x => {
                    const key = this.grid.editing.changes[this.grid.editing.changes.length - 1].key;
                    this.grid.editing.changes[this.grid.editing.changes.length - 1].data = {__KEY__: key, ...this.grid.dataSource[rowIndex]};
                    // this.grid.editing.changes[this.grid.editing.changes.length - 1].coreInfo = this.DUPLICATED_ROW;

                    if (rowCount === counter) {
                        this.grid.instance.refresh();
                    }
                });
            }
        }
    }

    private deleteSelectedRows(): void {
        if (this.grid.selectedRowKeys?.length < 1) {
            this.toast.warning('Please select rows before clicking delete');
        } else {
            const type = devExtremeDMLStrings.Remove;
            for (const rowKey of this.grid.selectedRowKeys) {
                if (!this.grid.editing.changes.some(x => x.type === type && x.key === rowKey)) {
                    this.grid.editing.changes.push({ key: rowKey, type, data: {} });
                }
            }

            this.grid.instance.refresh();
        }
    }

    private openAndPopulateBulkEditPopup(): void {
        this.resetGridPopup();
        this.dataGridPopupProps.createMessageOnly('500', '250', true, 'Bulk Edit');

        if (this.columns && this.columns.length > 0) {
        let ddOptions = this.columns.filter(x => x.isEditable).map(x => ({ name: x.columnHeader, dbColumn: x.dataField }));

            if (ddOptions && ddOptions.length > 0) {
                const areRows = this.grid.selectedRowKeys && this.grid.selectedRowKeys.length > 0;

                this.dataGridPopupSteps.push(new CorePopupStep('Note: you will have the opportunity to revert your change after this popup closes' +
                    (areRows ? '' : '<br><i class="red-text">No rows selected, please select the rows to be edited</i>'),
                    (e: CorePopupStep) => { this.applyBulkEditValueToColumn(e); }, null, this, 'Update Column Data', 'Cancel', false, false)
                    .appendItemProp(new CoreDynamicInputProperties('Column: ', true, ddOptions, 'name', 'dbColumn')
                    .createForLookup(this, this.bulkEditColumnSelectionChanged))
                );

                this.dataGridPopupProps.visible = true;
                ddOptions = ddOptions.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
                this.dataGridPopupSteps[0].appendItemProp(new CoreDynamicInputProperties('Value: ', false, null, null, null, null, true));
            }
        }
    }

    private bulkEditColumnSelectionChanged(e: CoreEventArguments, index: number): void {
        if (this.dataGridPopupSteps[0].itemsProps && this.dataGridPopupSteps[0].itemsProps.length > 1) {
            const column = this.columns.find(x => x.dataField === e.event.value.dbColumn);

            if (column.columnType && column.columnType.columnType === 'lookup' && column.editCellTemplateName === CoreDataGridEditTemplates.DropDownTableTemplate) {
                let data: any;
                if (column.columnType.lookupData && column.columnType.lookupData.store && column.columnType.lookupData.store._array) {
                    data = column.columnType.lookupData.store._array;
                } else {
                    data = column.columnType.lookupData;
                }

                this.dataGridPopupSteps[0].itemsProps[1].convertToTableDropDown(column.columnTemplateProps.columns, column.columnTemplateProps.gridProps.height,
                    column.columnTemplateProps.templateOptions.width, data, column.columnType.lookupDisplayValue, column.columnType.lookupValue);
            } else if (column.columnType && column.columnType.columnType === 'lookup') {
                let data: any;
                if (column.columnType.lookupData && column.columnType.lookupData.store && column.columnType.lookupData.store._array) {
                    data = column.columnType.lookupData.store._array;
                } else {
                    data = column.columnType.lookupData;
                }
                this.dataGridPopupSteps[0].itemsProps[1].convertToLookup(data, column.columnType.lookupDisplayValue, column.columnType.lookupValue);
            } else if (column.dataType === 'date' || column.dataType === 'datetime') {
                this.dataGridPopupSteps[0].itemsProps[1].convertToDateBox(column.editorOptions?.displayFormat ?
                    column.editorOptions.displayFormat : column.format, column.dataType === 'datetime');
            } else if (column.dataType === 'number') {
                this.dataGridPopupSteps[0].itemsProps[1].convertToNumberBox(column.format);
            } else if (column.dataType === 'string') {
                this.dataGridPopupSteps[0].itemsProps[1].convertToTextBox();
            }

            this.dataGridPopupSteps[0].itemsProps[1].setLeftPadding('12px');
        }
    }

    private applyBulkEditValueToColumn(e: CorePopupStep): void {
        const dataProp = this.dataGridPopupSteps[0].itemsProps[0].selectedValue;
        for (const rowKey of (this.grid.selectedRowKeys as any[])) {
            const isKey = (this.props.keyColumn !== null && this.props.keyColumn !== undefined);
            const rowData = this.grid.dataSource[(this.grid.dataSource as any[]).findIndex(x => (isKey ? x[this.props.keyColumn] === rowKey : x === rowKey))];

            if (this.grid.editing.changes.some(x => x.type === devExtremeDMLStrings.Update && (x.key === rowData || (isKey && x.key === rowData[this.props.keyColumn])))) {
                const index = this.grid.editing.changes.findIndex(x => x.type === devExtremeDMLStrings.Update && (x.key === rowData || x.key === rowData[this.props.keyColumn]));
                this.grid.editing.changes[index].data[dataProp] = this.dataGridPopupSteps[0].itemsProps[1].selectedValue;
            } else {
                const isSelectBox = this.dataGridPopupSteps[0].itemsProps[1].editorType === 'dxSelectBox';
                const selectedValue = this.dataGridPopupSteps[0].itemsProps[1].selectedValue;
                this.grid.editing.changes.push({
                    data: {[dataProp]: (selectedValue === -1 && isSelectBox) ? null : selectedValue},
                    key: (isKey ? rowData[this.props.keyColumn] : rowData),
                    type: devExtremeDMLStrings.Update
                });
            }
        }
        this.grid.instance.refresh();
    }

    private openLayoutsPopup(): void {
        this.resetGridPopup();
        this.dataGridPopupProps.createMessageOnly('400', '175', true, 'Layout Selection');
        this.dataGridPopupSteps.push(new CorePopupStep('', (e: CorePopupStep) => { this.applyDropdownLayout(); },
            null, this, 'Apply Layout', 'Cancel', false, false)
        );

        this.populateLayouts();

        this.dataGridPopupProps.visible = true;
    }

    private populateLayoutsPopupDropdown(): void {
        const dropdownProps = new CoreDropdownProperties().createAll(true, 'id', 'name', false, false, false, false, false, false, 200, 'layout',
            'deleteDisabled', 'deleteDisabled', 'deleteDisabled', false);
        this.sortUiViews();

        this.dataGridPopupSteps[0]?.enableCoreDropDown(this.props.uiViewId, 'gridPopupCoreDropdown', dropdownProps, null, this.uiViews, this,
            this.layoutSelectionChanged, null, this.deleteLayout, this.addLayout, this.renameLayout, this.saveLayout);

        if (this.coreDataGridPopup.popupCoreDropdown) {
            this.coreDataGridPopup.popupCoreDropdown.setSelectedValue(this.props.uiViewId);
        }
    }

    private addLayout(event: string): void {
        const newUiView: UiView = new UiView();
        let layout = this.grid.instance.state();

        if (this.props.saveGridState) {
            layout = this.props.saveGridState(layout);
        }

        newUiView.layoutString1 = JSON.stringify(layout);
        newUiView.contextCode = this.props.uiViewContextCode;
        newUiView.name = event;

        this.props.uiViewAddProperties.forEach(prop => {
            newUiView[prop.propertyName] = prop.propertyValue;
        });

        this.uiViewService.insertUiView(newUiView).subscribe(inserted => {
            this.uiViews.push(inserted);
            this.sortUiViews();
            this.coreDataGridPopup.popupCoreDropdown.addNewItemToDropdown(inserted, this.sortDropdownItems);
            this.layoutSelectionChanged(inserted.id);
            if (this.props.onLayoutAdded) {
                this.props.onLayoutAdded(inserted);
            }
        });
    }

    private layoutSelectionChanged(newValue: number): void {
        this.dataGridPopupSteps[0].coreDropdown.selectedValue = newValue;
        this.props.uiViewId = newValue;
    }

    private deleteLayout(event: any): void {
        const deletedId = this.dataGridPopupSteps[0].coreDropdown.selectedValue;
        this.uiViewService.deleteUiView(deletedId).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                const deletedUiView = this.uiViews.find(x => x.id === deletedId);
                this.uiViews.splice(this.uiViews.findIndex(x => x.id === deletedId), 1);
                this.layoutSelectionChanged(this.dataGridPopupSteps[0].coreDropdown.dataSource[0].id);
                this.coreDataGridPopup.popupCoreDropdown.deleteItemByValue(deletedId);
                if (this.props.onLayoutDeleted) {
                    this.props.onLayoutDeleted(deletedUiView);
                }
            }
        });
    }

    private renameLayout(event: string): void {
        const uiView = this.uiViews.find(x => x.id === this.props.uiViewId);
        uiView.name = event;

        this.uiViewService.updateUiView(uiView).subscribe(updatedView => {
            this.uiViews.find(x => x.id === updatedView.id).name = updatedView.name;
            this.sortUiViews();
            this.coreDataGridPopup.popupCoreDropdown.renameItemByValue(uiView.id, uiView.name, this.sortDropdownItems);
            this.coreDataGridPopup.popupCoreDropdown.repaint();
            if (this.props.onLayoutRenamed) {
                this.props.onLayoutRenamed(uiView);
            }
        });
    }

    private saveLayout(event: any): void {
        const index = this.uiViews.findIndex(x => x.id === this.dataGridPopupSteps[0].coreDropdown.selectedValue);
        const uiView = this.uiViews[index];
        let layout = this.grid.instance.state();

        if (this.props.saveGridState) {
            layout = this.props.saveGridState(layout);
        }

        uiView.layoutString1 = JSON.stringify(layout);
        this.uiViews[index].layoutString1 = uiView.layoutString1;
        if (this.props.onLayoutSaved) {
            this.props.onLayoutSaved(uiView);
        }

        this.uiViewService.updateUiView(uiView).subscribe(updatedView => { });
    }

    private sortDropdownItems(a: CoreDropdownItem, b: CoreDropdownItem): number {
        return a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1
            : (a.displayValue.toLowerCase() < b.displayValue.toLowerCase() ? -1
                : 0);
    }

    private sortUiViews(): void {
        this.uiViews.sort((a, b) => {
            if (a.systemCode !== null && b.systemCode === null) {
                return -1;
            } else if (a.systemCode === null && b.systemCode !== null) {
                return 1;
            } else {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1
                : (a.name.toLowerCase() < b.name.toLowerCase() ? -1
                    : 0);
            }
        });
    }

    private resetGridPopup(): void {
        this.dataGridPopupSteps = [];
    }

    private applyDropdownLayout(): void {
        this.props.uiViewId = this.dataGridPopupSteps[0].coreDropdown.selectedValue;
        this.setGridLayout(this.uiViews.find(view => view.id === this.props.uiViewId));
    }

    private dragHandler(e: any): void {
        const component = e.data.component;
        const header = component.element().querySelectorAll('.dx-datagrid-headers');
        const headerContent = header[0].querySelectorAll('.dx-datagrid-content:not(.dx-datagrid-content-fixed)')[0];
        const headerContentWidth = parseFloat(getComputedStyle(headerContent, null).width.replace('px', ''));
        const boundingClientRect = this.getBoundingRect(headerContent);
        clearInterval(this.timerId);

        if (e.pageX > boundingClientRect.right) {
            this.scrollTo(component, headerContentWidth);
        } else if (e.pageX < boundingClientRect.left) {
            this.scrollTo(component, 0);
        }
    }

    private getBoundingRect(headerContent: any): any {
        const transparentCell = headerContent.parentNode;
        const boundingElement = transparentCell.length
            ? transparentCell[0]
            : headerContent;

        return boundingElement.getBoundingClientRect();
    }

    private scrollTo(component: any, diff: number): void {
        const scrollable = component.getScrollable();
        let scrollLeft = scrollable.scrollLeft();
        const scrollWidth = scrollable.scrollWidth();
        const separator = component.element().querySelectorAll('.dx-datagrid-columns-separator')[0];
        const step = diff === 0 ? -10 : 10;

        if (separator !== undefined) {
            separator.classList.add('dx-datagrid-columns-separator-transparent');
        }

        this.timerId = setInterval(() => {
            const elems = document.getElementsByClassName('dx-datagrid-drag-header')[0];

            if ((scrollWidth - scrollLeft === diff) || getComputedStyle(elems).display === 'none' || getComputedStyle(elems).visibility === 'hidden') {
                clearInterval(this.timerId);
            } else {
                scrollLeft += step;
                const dragOptions = component.getView('draggingHeaderView')._dragOptions;

                if (dragOptions) {
                    dragOptions.pointsByTarget = null;
                }

                component.getController('draggingHeader').hideSeparators();
                scrollable.scrollTo({ left: scrollLeft });
            }
        });
    }

    private getSelectedRowDataWithHeadersForCopy(): string {
        let data = '';

        const columns = this.columns.filter(x => !this.helperService.isNullOrEmpty(x.columnHeader) && x.includeInCopyToClipboard === true);

        // Get headers
        const columnHeadersRow = columns.map(x => x.columnHeader).join('\t');
        data += columnHeadersRow + '\n';

        // Get data
        const sortedDataSourceItemStrings = this.grid.instance.getDataSource().items().map(item => JSON.stringify(item));
        const selectedRowsData = this.grid.instance.getSelectedRowsData();
        // Use the sort order from getDataSource().items() so that the copied data has the same order as what the user sees
        selectedRowsData.sort((a, b) => sortedDataSourceItemStrings.indexOf(JSON.stringify(a)) - sortedDataSourceItemStrings.indexOf(JSON.stringify(b)));

        selectedRowsData.forEach(rowData => {
            const rowValues = [];
            columns.forEach(column => {
                let columnValue = rowData[column.dataField];
                if(!Object.keys(rowData).includes(column.dataField)){
                    columnValue = rowData;
                    column.dataField.split('.').forEach(property => {
                        if(columnValue){
                            columnValue = columnValue[property];
                        }
                    });
                }

                const cellInfo = {
                    value: columnValue,
                    valueText: this.helperService.isNullOrUndefined(columnValue) ? '' : columnValue,
                    target: 'copy-export',
                    groupInterval: undefined
                };
                if (column.columnType?.columnType === 'lookup') {
                    const lookupData = column.columnType.lookupData?.store?._array !== undefined
                        ? column.columnType.lookupData.store._array :
                        column.columnType.lookupData?.staticItems !== undefined
                        ? column.columnType.lookupData.staticItems
                        : column.columnType.lookupData;
                    const lookupObject = lookupData.find(x => x[column.columnType.lookupValue] === cellInfo.value);
                    if (!this.helperService.isNullOrUndefined(lookupObject)) {
                        cellInfo.valueText = lookupObject[column.columnType.lookupDisplayValue];
                    }
                } else if (column.dataType === 'date' || column.dataType === 'datetime') {
                    const formatString = column.dataType === 'date' ? 'd' : 'g';
                    cellInfo.valueText = cellInfo.value === undefined || cellInfo.value === null ? '' : String.format(`{0:${formatString}}`, new Date(cellInfo.value));
                }

                if (column.getCustomText) {
                    rowValues.push(column.getCustomText(cellInfo));
                } else {
                    rowValues.push(cellInfo.valueText);
                }
            });
            data += rowValues.join('\t') + '\n';
        });

        return data;
    }

    private getHiddenTextAreaElementForCopy(copyData: string): any {
        const doc = document as any;
        const element = doc.createElement('textarea');

        // Place in the top-left corner of screen regardless of scroll position.
        element.style.position = 'fixed';
        element.style.top = 0;
        element.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        element.style.width = '2em';
        element.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        element.style.padding = 0;

        // Clean up any borders.
        element.style.border = 'none';
        element.style.outline = 'none';
        element.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        element.style.background = 'transparent';

        element.value = copyData;

        doc.body.appendChild(element);
        element.focus();
        element.select();

        return element;
    }

    private configureClientSideDataProcessing(): void {
        this.props.remoteOperations = false;
        this.dataStore = this._dataSource;
    }

    private configureServerSideDataProcessing(): void {
        const self = this;
        this.props.remoteOperations = true;
        const apiUrl = this.props.serverSideDataProcessingUrl;
        const keyProperty = this.props.keyColumn;
        this.dataStore = new CustomStore({
            key: keyProperty,
            load: (params: any) => {
                if (params.group !== undefined && params.group !== null && params.group.length > 0) {
                    params.requireGroupCount = true;
                }
                return this.serverSideDataProcessingService.getServerSideData(apiUrl, params)
                    .toPromise()
                    .then(response => {
                        let data = response.data;
                        if ((response.groupCount === undefined || response.groupCount === -1) && this.props.modifyServerDataFunction) {
                            data = this.props.modifyServerDataFunction(response.data);
                        }
                        return {
                            data,
                            totalCount: response.totalCount,
                            summary: response.summary,
                            groupCount: response.groupCount
                        };
                    })
                    .catch(() => {
                        // eslint-disable-next-line no-throw-literal
                        throw 'Data loading error';
                    });
            }
        });
    }
}
