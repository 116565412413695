import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BlockPropertyService } from 'src/app/pages/building-blocks/block-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-bb-field-grid',
  providers: [ {provide: SaveableBbProperty, useExisting: BbFieldGridComponent }],
  templateUrl: './bb-field-grid.component.html',
  styleUrls: ['./bb-field-grid.component.scss']
})
export class BbFieldGridComponent extends SaveableBbProperty implements OnInit {

  @Input() isEditable: boolean = true;
  @Input() set datasource(source: any[]) {
    this.gridDatasource = source;
  }
  get datasource() {
    return this.gridDatasource;
  }
  @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>(true);
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  isValid: boolean = true;
  gridDatasource: any[];
  blockPropertyMappings: Record<string, any>;
  fieldDropDownDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  aggregateFunctionDropDownDatasource = { dataSource: [
    {refName: 'AVG', value: 'AVG'},
    {refName: 'COUNT', value: 'COUNT'},
    {refName: 'COUNT DISTINCT', value: 'COUNT DISTINCT'},
    {refName: 'GROUP BY', value: 'GROUP BY'},
    {refName: 'MAX', value: 'MAX'},
    {refName: 'MIN', value: 'MIN'},
    {refName: 'SUM', value: 'SUM'}
    ],
    valueExpr: 'refName', displayExpr: 'value'};
  constructor(private blockPropertyService: BlockPropertyService, private bbHelper: BuildingBlockHelperService, private helperService: HelperService) {
    super();
  }

  ngOnInit(): void {
    const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
    this.gridDatasource = this.gridDatasource.map(col => {
      const colCopy = this.helperService.deepCopyTwoPointO(col);
      if(idCols.includes(colCopy['systemName'])){
          colCopy['systemName'] = this.bbHelper.convertIdToNameColumn(colCopy['systemName']);
      }
      return colCopy;
  });
    this.blockPropertyService.getMappings().subscribe(res => {
      if (this.isEditable === false){
        this.bbHelper.getDataColumns().subscribe(columns => {
          const outputCols = this.bbHelper.convertProcessDataColumnsToDropdownDatasource(columns);
          this.fieldDropDownDatasource = outputCols;
        });
      }
      else {
        this.blockPropertyMappings = res;
        this.fieldDropDownDatasource = this.blockPropertyMappings['aggregateFields'];
      }
    });
  }

  checkForChanges(){
    if(this.dataGrid.instance.hasEditData()){
      this.bbHelper.setGridPropertyAsUnsaved('aggregateFields');
    } else {
      this.bbHelper.removeGridPropertyFromUnsaved('aggregateFields');
    }
  }

  emitValue() {
    this.gridDatasource = this.gridDatasource.map(entry => {
      const entryCopy = this.helperService.deepCopyTwoPointO(entry);
      const isSourceConvertable = entryCopy['systemName'].endsWith('_name');
      if(isSourceConvertable) {
          entryCopy['systemName'] = this.bbHelper.convertNameToIdColumn(entryCopy['systemName']);
      }
      return entryCopy;
  });
    this.datasourceChange.emit(this.gridDatasource.map(entry => ({systemName: entry['systemName'], aggregateFunction: entry['aggregateFunction'], outputId: entry['outputId']})));
  }

  saveInternalData(): Promise<void> {
    return this.bbHelper.saveWithUpdatedOutputId(this.gridDatasource, this.dataGrid);
  }

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
  filterToolbarItems(e){
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
  }

  sortColumn(data) {
    const column = this as any;
    const value = column.calculateCellValue(data);
    return column.lookup.calculateCellValue(value);
  }
}

