<div class="bb-page-container">
    <app-bb-header (onBlockInsert)="onBlockInsert($event)"></app-bb-header>

    <div class="main-content" [ngClass]="{'hide-property-panel': !this.propertyPanelVisible}">
        <dx-resizable
            class="bb-diagram-container"
            [(width)]="diagramWidth"
            [maxWidth]="diagramWidthMax"
            [handles]="this.propertyPanelVisible ? 'right' : ''"
            minWidth="300"
            (onResizeStart)="onDiagramResizeStart()"
            (onResizeEnd)="onDiagramResizeEnd()">
            <app-bb-diagram #diagram></app-bb-diagram>
            <dx-button
                class="properties-drawer-button dx-shape-standard"
                [icon]="propertiesDrawerButtonIcon"
                (onClick)="openCloseProperties()">
            </dx-button>
        </dx-resizable>
        <div class="bb-property-container">
            <app-bb-property-panel></app-bb-property-panel>
        </div>
    </div>

    <div>
        <app-bb-grid #grid></app-bb-grid>
    </div>
    <div #botIcon class="icon-wrapper" *ngIf="isRuleBotVisible" (click)="displayCoreBotPopup($event)">
        <img class="bot-popup" src="../../../assets/images/AuditBot.png">
        <div class="rulebot-chat-popup">
            <app-rulebot-chat 
                [showPopup]="showCoreBotPopup" 
                (showPopupChanged)="onPopupStateChanged($event)">
            </app-rulebot-chat>
        </div>
    </div>
    <app-bb-circular-sources-popup>

    </app-bb-circular-sources-popup>
</div>