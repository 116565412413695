<div *ngIf="props.isTreeLabelVisible" [ngClass]="{ 'display-none': props.isTreeLabelVisible }" class="tree-label">
    {{ props.treeLabel }}
</div>
<dx-tree-list
    #treeList
    [id]="props.treeId"
    class="core-component"
    [dataSource]="dataSource"
    [keyExpr]="props.keyColumnName"
    [parentIdExpr]="props.parentKeyColumnName"
    [showColumnLines]="props.showColumnLines"
    [showBorders]="props.showBorders"
    [height]="props.height"
    [columnAutoWidth]="props.columnAutoWidth"
    [wordWrapEnabled]="props.wordWrapEnabled"
    [allowColumnReordering]="props.isColumnReorderAllowed"
    [allowColumnResizing]="props.isColumnResizingAllowed"
    (onToolbarPreparing)="onToolbarPreparing($event)">
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-pager
        *ngIf="props.isPagingEnabled"
        [showPageSizeSelector]="props.isPagingEnabled && props.isPageSizeSelectorVisible"
        [allowedPageSizes]="props.isPagingEnabled && props.allowedPageSizes"
        [showNavigationButtons]="props.isPagingEnabled && props.isPageNavigationVisible">
    </dxo-pager>
    <dxo-scrolling *ngIf="props.isScrollingEnabled && !props.isPagingEnabled" mode="standard"></dxo-scrolling>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-selection
        *ngIf="props.isRowSelectionEnabled"
        [selectAllMode]="props.rowSelectionSelectAllMode"
        [showCheckBoxesMode]="props.rowSelectionCheckBoxesMode"
        [allowSelectAll]="props.isRowSelectionSelectAllEnabled"
        [mode]="props.rowSelectionMode"
        [recursive]="props.isRowSelectionRecursive">
    </dxo-selection>
    <dxi-column
        *ngFor="let c of columns"
        [dataField]="c.dataField"
        [calculateDisplayValue]="c.dataFieldDisplay"
        [dataType]="c.dataType"
        [caption]="c.columnHeader"
        [type]="c.columnType ? c.columnType.columnType : null"
        [allowEditing]="c.isEditable"
        [format]="c.format"
        [width]="c.width"
        [minWidth]="c.minWidth"
        [visible]="c.isVisible"
        [cssClass]="c.cssClass"
        [calculateCellValue]="c.calculation"
        [allowSorting]="c.isSortingAllowed"
        [showInColumnChooser]="c.isShownInColumnChooser"
        [cellTemplate]="c.columnType ? c.columnType.cellTemplateName : null"
        [sortOrder]="c.sortOrder"
        [sortIndex]="c.sortIndex">
        <dxi-button
            *ngIf="c.columnType && c.columnType.columnType==='buttons'"
            [text]="c.columnType.columnTypeText"
            [hint]="c.columnType.hintText"
            [icon]="c.columnType.buttonIcon"
            [visible]="c.columnType.isVisible"
            cssClass="core-button"
            [onClick]="onCoreTreeButtonClick">
        </dxi-button>
        <dxo-lookup
            *ngIf="c.columnType && c.columnType.columnType==='lookup'"
            [dataSource]="c.columnType.lookupData"
            [allowClearing]="true"
            [valueExpr]="c.columnType.lookupValue"
            [displayExpr]="c.columnType.lookupDisplayValue">
        </dxo-lookup>
    </dxi-column>
    <!-- BEGIN Cell Templates -->
    <div *dxTemplate="let data of 'dropDownButtonCellTemplate'">
        <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
            <ng-container *ngVar="getDataForCellDropDownButton(c, data.data) as dropDownData">
                <dx-drop-down-button
                    [hint]="c.columnType.hintText"
                    [text]="c.columnType.isTextFromRecord ? data.data[c.columnType.recordTextProp]: c.columnType.columnTypeText"
                    [splitButton]="true"
                    [dataSource]="dropDownData"
                    [keyExpr]="c.columnType.lookupValue"
                    [displayExpr]="c.columnType.lookupDisplayValue"
                    [dropDownOptions]="{width: 'auto', maxWidth: '250'}"
                    [noDataText]="c.columnType.noDataText"
                    (onButtonClick)="onCoreTreeButtonClick($event, data)"
                    (onItemClick)="onCoreTreeButtonItemClick($event, data)">
                </dx-drop-down-button>
            </ng-container>
        </ng-container>
    </div>
    <div *dxTemplate="let data of 'plainButtonCellTemplate'">
        <ng-container *ngIf="data && data.column && data.column.dataField">
            <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
                <ng-container *ngIf="data.data[props.parentKeyColumnName]">
                    <dx-button
                        class="core-grid-button"
                        [hint]="c.columnType.hintText"
                        [text]="c.columnType.isTextFromRecord ? data.data[c.columnType.recordTextProp]: c.columnType.columnTypeText"
                        (onClick)="c.columnType.clickFunction($event, data)">
                    </dx-button>
                </ng-container>
                <ng-container *ngIf="!data.data[props.parentKeyColumnName]">
                    {{data.data[c.columnType.recordTextProp]}}
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <!-- END Cell Templates -->
    <ng-container *ngIf="props.customCommandColumnOptions?.length > 0">
        <dxi-column name="command-editing" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
            <div *dxTemplate="let data of 'cellTemplate'">
                <ng-container *ngFor="let buttonInfo of props.customCommandColumnOptions">
                    <ng-container *ngVar="getRowData(data) as rowInfo">
                        <ng-container *ngIf="buttonInfo?.condition(rowInfo)">
                            <dx-button [text]="buttonInfo.text" [hint]="buttonInfo.hint" (onClick)="buttonInfo.onClick(rowInfo)"></dx-button>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </dxi-column>
    </ng-container>
</dx-tree-list>
<dx-load-panel
    #treeLoadPanel
    [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#'+props.treeId }"
    [container]="'#'+props.treeId"
    [(visible)]="props.loadingVisible"
    [indicatorSrc]="indicatorUrl"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
