import { Component, OnInit, NgModule, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { SideNavOuterToolbarModule } from '../side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { AppInfoService } from '../../shared/services/app-info.service';
import { ScreenService } from '../../shared/services/screen.service';
import { BucketService } from '../../shared/services/bucket.service';
import { ReportsComponent } from '../../pages/reports/reports.component';
import { DashboardsComponent } from '../../pages/dashboards/dashboards.component';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { AnalyticsComponent } from '../../pages/analytics/analytics.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { SettingService } from 'src/app/shared/services/setting.service';
import { SiteThemeDefault, SiteThemeBI } from '../../shared/constants/theme-constants';
import { EnumSettingClassId } from '../../shared/constants/enums';
import { CommonModule } from '@angular/common';
import { SiteThemeService } from 'src/app/shared/services/site-theme.service';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

    @ViewChild(RouterOutlet, { static: false }) reportPage: RouterOutlet;
    @HostBinding('class') get getClass() {
        return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
    }

    siteThemeConfig: any = SiteThemeDefault;
    showLoadingThemeOverlay: boolean = true;

    constructor(public settingService: SettingService,
        public appInfo: AppInfoService,
        private siteThemeService: SiteThemeService,
        private screen: ScreenService,
        private appElementService: AppElementsService) { }

    ngOnInit() {
        sffjs.setCulture('en');

        this.settingService.getBoolSetting(EnumSettingClassId.EnableBITheme).subscribe(isBITheme => {
            this.siteThemeConfig = isBITheme ? SiteThemeBI : SiteThemeDefault;
            this.siteThemeService.applySiteTheme(document, this.siteThemeConfig);
            this.showLoadingThemeOverlay = false;
        });
    }

    onParameterSelectionChanged() {
        if (this.reportPage.component instanceof ReportsComponent
            || this.reportPage.component instanceof DashboardsComponent
            || this.reportPage.component instanceof AnalyticsComponent
            || this.reportPage.component instanceof HomeComponent) {
            this.reportPage.component.refreshViewer();
        }
    }
}

@NgModule({
    imports: [
        SideNavOuterToolbarModule,
        RouterModule,
        CommonModule
    ],
    declarations: [MainLayoutComponent],
    providers: [BucketService],
    exports: [MainLayoutComponent]
})
export class MainLayoutModule { }
