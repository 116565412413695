import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Dispute } from '../models/dispute';
import { DisputeField } from '../models/dispute-field';
import { Xaction } from '../models/xaction';
import { Product } from '../models/product';
import { Customer } from '../models/customer';
import { DisputeItemRow } from '../models/dispute-item-row';
import { DisputeItemRowId } from '../models/dispute-item-row-id';
import { SellerName } from '../models/seller-name';
import { FlatXactionCalculations } from '../models/flat-xaction-calculations';
import { DisputeBatchItemChanges } from '../models/dispute-batch-item-changes';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class DisputeManagementService {
    private baseurl: string = environment.apiEndpoint;
    private disputeManagementURL = this.baseurl + '/disputeManagement';

    constructor(private http: HttpClient) { }

    getFields(): Observable<any[]> {
        return this.http.get<any[]>(this.disputeManagementURL + '/fields/');
    }

    getDisputeLayout(): Observable<Dispute[]> {
        return this.http.get<Dispute[]>(this.disputeManagementURL + '/dispute/');
    }

    getDisputeLayoutById(id: number): Observable<Dispute> {
        return this.http.get<Dispute>(this.disputeManagementURL + '/dispute/' + id);
    }

    getSelectedDisputeFields(): Observable<DisputeField[]> {
        return this.http.get<DisputeField[]>(this.disputeManagementURL + '/disputeFields');
    }

    getDisputeItemRows(disputeId: number): Observable<FlatXactionCalculations[]> {
        return this.http.get<FlatXactionCalculations[]>(this.disputeManagementURL + '/disputeDataSourceRows/' + disputeId);
    }

    getFilteredDisputeItemRows(filterItem: DisputeItemRow): Observable<FlatXactionCalculations[]> {
        return this.http.post<FlatXactionCalculations[]>(this.disputeManagementURL + '/disputeFilteredDataSourceRows/', filterItem);
    }

    getDataSourceId(): Observable<number> {
        return this.http.get<number>(this.disputeManagementURL + '/DisputeDataSourceId');
    }

    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.disputeManagementURL + '/Products');
    }

    getCustomers(): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.disputeManagementURL + '/Customers');
    }

    getTagMapping(): Observable<any[]> {
        return this.http.get<any[]>(this.disputeManagementURL + '/GetTagMapping');
    }

    getCurrentlySelectedFields(): Observable<any[]> {
        return this.http.get<any[]>(this.disputeManagementURL + '/CurrentlySelectedFields');
    }

    getSelectableSellerNames(disputeId: number): Observable<SellerName[]> {
        return this.http.get<SellerName[]>(this.disputeManagementURL + '/DisputeSellerNames/' + disputeId);
    }

    insertDisputeLayout(dispute: Dispute): Observable<Dispute> {
        return this.http.post<Dispute>(this.disputeManagementURL + '/dispute', dispute);
    }

    insertDisputeField(disputeField: DisputeField): Observable<DisputeField> {
        return this.http.post<DisputeField>(this.disputeManagementURL + '/disputeField', disputeField);
    }

    insertDisputeItem(item: DisputeItemRow): Observable<Xaction> {
        return this.http.post<Xaction>(this.disputeManagementURL + '/insertItem', item);
    }

    updateDispute(dispute: Dispute): Observable<any> {
        return this.http.put<Dispute>(this.disputeManagementURL + '/dispute', dispute);
    }

    updateDisputeFields(disputeFields: DisputeField[]): Observable<any> {
        return this.http.post<DisputeField[]>(this.disputeManagementURL + '/disputeFields', disputeFields);
    }

    updateDisputeField(disputeField: DisputeField): Observable<any> {
        return this.http.put<DisputeField>(this.disputeManagementURL + '/disputeField', disputeField);
    }

    updateDisputeItem(item: DisputeItemRow): Observable<number> {
        return this.http.post<number>(this.disputeManagementURL + '/updateItem', item);
    }

    resolveDisputeItem(item: DisputeItemRow): Observable<number> {
        return this.http.post<number>(this.disputeManagementURL + '/resolveItem', item);
    }

    deleteDispute(disputeId: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.disputeManagementURL + '/dispute/' + disputeId);
    }

    deleteDisputeField(disputeFieldId: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.disputeManagementURL + '/disputeField/' + disputeFieldId);
    }

    deleteDisputeItem(item: DisputeItemRowId): Observable<number> {
        return this.http.post<number>(this.disputeManagementURL + '/deleteItem', item);
    }

    batchResolveDisputeItems(changes: DisputeBatchItemChanges): Observable<number> {
        return this.http.post<number>(this.disputeManagementURL + '/batchResolve', changes);
    }

    batchChangeDisputeItems(changes: DisputeBatchItemChanges): Observable<CoreResponse<boolean>> {
        return this.http.post<CoreResponse<boolean>>(this.disputeManagementURL + '/batchItems', changes);
    }
}
