<div class="core-custom-tool-panel-buttons">
    <div class="button">
        <dx-button
            [ngClass]="{'font-bold': hasPendingEdits}"
            [disabled]="!isGridEditable.value"
            (onClick)="finalizeEdits()"
            text="Finalize Edits"
            [disabled]="!isGridEditable.value">
        </dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="addRowProcedure()" text="Add Row" [disabled]="!isAddingAllowed.value"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="duplicateRowProcedure()" text="Duplicate Row(s)" [disabled]="!isAddingAllowed.value"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="clearFilters()" text="Clear Filters"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="showBulkEditPopup()" text="Bulk Edit" [disabled]="!isBulkEditAllowed.value || isProcessedOutput"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="showPeriodFilterRangePopup()" text="Period Range"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="showTraceOutputPopup()" text="Trace Output" [disabled]="traceOutputDisabled"></dx-button>
    </div>
    <div class="button">
        <dx-drop-down-button
            text="Export All"
            [splitButton]="true"
            displayExpr="name"
            [dataSource]="exportAllOptions"
            [dropDownOptions]="{ width: 'auto' }"
            (onButtonClick)="showExportOutputPopup(false)"
            (onItemClick)="showExportOutputPopup(true)">
        </dx-drop-down-button>
    </div>
    <div class="button">
        <dx-button (onClick)="openDiagramAudit()" text="Diagram Audit" [disabled]="diagramAuditDisabled"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="deleteProcedure()" text="Delete" [disabled]="!isDeletingAllowed.value || isProcessedOutput"></dx-button>
    </div>
    <div class="button">
        <dx-button (onClick)="refreshProcedure()" text="Refresh"></dx-button>
    </div>
</div>
<dx-tooltip target="#rapidLoadModeToggle" position="top" showEvent="dxhoverstart" hideEvent="dxhoverend">
    <div *dxTemplate="let data of 'content'" class="wrap-tooltip-text">
        <span>Rapid Load enabled (recommended) loads fewer rows at a time in the browser for faster performance.</span>
    </div>
</dx-tooltip>
<div class="theme-mode-toggles">
    <div id="rapidLoadModeToggle" class="theme-mode-toggle">
        <span>{{rapidLoadModeToggle ? 'Rapid Load' : 'Load All'}}</span>
        <div class="switch">
            <dx-switch (onValueChanged)="confirmToggleMode($event)" [(value)]="rapidLoadModeToggle"></dx-switch>
        </div>
    </div>
    <div class="theme-mode-toggle">
        <span>{{darkMode?'Dark Mode':'Light Mode'}}</span>
        <div class="switch">
            <dx-switch (onValueChanged)="toggleTheme($event)" [(value)]="darkMode"></dx-switch>
        </div>
    </div>
</div>
