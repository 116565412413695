<dx-report-viewer
    id="reportViewer"
    [ngClass]="{'admin-viewer': hasAdminView}"
    [reportUrl]="reportUrl"
    [height]="hasAdminView ? 'calc(100vh - 110px)' : 'calc(100vh - 50px)'">
    <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
    <dxrv-export-settings [useSameTab]="false" [useAsynchronousExport]="false"></dxrv-export-settings>
    <dxrv-callbacks (DocumentReady)="getInitialBrick($event)" (PreviewClick)="onPrevClick($event)" (CustomizeMenuActions)="customizeMenuActions($event)" (CustomizeExportOptions)="customizeExportOptions($event)"></dxrv-callbacks>
</dx-report-viewer>

<dx-load-panel
    #loadPanel
    [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#reportViewer' }"
    [(visible)]="loadingVisible && showLoadingPanel"
    [indicatorSrc]="indicatorUrl"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>

<app-rulebot-chat 
    [chatTitle]="'AI Report Auditing'"
    [isReportAudit]="true"
    [position]="'center'"
    [reportInfo]="reportAuditInfo"
    [showPopup]="isAuditBotVisible" 
    [initialMessage]="initialMessage"
    (showPopupChanged)="onAuditBotPopupStateChanged($event)"></app-rulebot-chat>

<app-reporting-parameters #reportParams (generate)="generateReport(this.reportId)" seriesMode="single" [(isPopupVisible)]="isReportParamsVisible"></app-reporting-parameters>
