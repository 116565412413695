import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { EnumBucketClass, EnumBucketType } from '../../constants/enums';
import { BucketClass } from '../../models/bucket-class';
import { BucketService } from '../../services/bucket.service';
import { Bucket } from '../../models/bucket';
import { navigation } from '../../../app-navigation';
import { SiteThemeDefault, SiteThemeBI } from '../../../shared/constants/theme-constants';
import { SettingService } from '../../services/setting.service';
import { EnumSettingClassId } from '../../../shared/constants/enums';
import { SiteThemeService } from '../../services/site-theme.service';
import { SideNavigationMenuComponent } from '../side-navigation-menu/side-navigation-menu.component';
import { HelperService } from '../../services/helper.service';
import { BucketSequenceContext } from '../../models/contexts/bucket-sequence-context';

@Component({
  selector: 'app-nav-bucket-folders',
  templateUrl: './nav-bucket-folders.component.html',
  styleUrls: ['./nav-bucket-folders.component.scss']
})
export class NavBucketFoldersComponent implements OnInit, OnChanges {
    @ViewChild(SideNavigationMenuComponent, { static: true })
    sideNavigationMenu: SideNavigationMenuComponent;

    @Input() folderType: EnumBucketType;
    @Input() isPopupVisible: boolean;
    @Input() existingItemMappings: {id: number, name: string}[];
    @Input()
    set itemId(value: number) {
        this._itemId = value;
        if (this._itemId && !this.initialContentLoaded) {
            this.loadPopupContent();
        }
    }
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();

    _itemId: number;
    itemTypeText: string;
    itemTypeNavItemName: string;
    radioSelectedValue: number = 0;
    radioOptions: any[] = [];
    radioValueNoFolder: number = 0;
    radioValueExisting: number = 1;
    radioValueCreateNew: number = 2;
    sequenceNo: number = 1;
    existingBucketClasses: BucketClass[] = [];
    bucketClassesForPreview: BucketClass[] = [];
    rootBucketsForPreview: Bucket[] = [];
    folderBucket: Bucket = null;
    allFolderBuckets: Bucket[] = [];
    selectedBucketClassId: number;
    createNewFolderName: string = '';
    saveButtonDisabled: boolean = true;
    bucketClassNameExists: boolean = false;
    initialContentLoaded: boolean = false;
    isLoading: boolean = true;
    siteThemeConfig: any = SiteThemeDefault;
    menuItems = navigation;
    upButtonDisabled: boolean = true;
    downButtonDisabled: boolean = true;

	constructor(private bucketService: BucketService,
        private settingService: SettingService,
        private siteThemeService: SiteThemeService,
        private helperService: HelperService,
        private toast: ToastrService
    ) {

    }

	ngOnInit() {
        this.settingService.getBoolSetting(EnumSettingClassId.EnableBITheme).subscribe(isBITheme => {
            this.siteThemeConfig = isBITheme ? SiteThemeBI : SiteThemeDefault;
            this.siteThemeService.applySiteTheme(document, this.siteThemeConfig);
        });
	}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isPopupVisible?.currentValue) {
            this.openPopup();
        }
    }

    openPopup(): void {
        this.loadPopupContent();
    }

    loadPopupContent(): void {
        this.isLoading = true;
        this.initialContentLoaded = true;
        this.itemTypeText = EnumBucketType[this.folderType].toLowerCase();
        this.itemTypeNavItemName = this.itemTypeText.charAt(0).toUpperCase() + this.itemTypeText.slice(1) + 's';

        this.radioOptions = [
            { id: this.radioValueNoFolder, name: 'No Folder' },
            { id: this.radioValueExisting, name: 'Select existing folder' },
            { id: this.radioValueCreateNew, name: 'Create new folder' }
        ];

        forkJoin([
            this.bucketService.getBucketClassesByFolderType(this.folderType),
            this.bucketService.getFolderBucket(this._itemId, this.folderType),
            this.bucketService.getAllFolderBuckets()
        ]).subscribe(([bucketClasses, folderBucket, allFolderBuckets]) => {
            this.rootBucketsForPreview = [];
            this.existingBucketClasses = bucketClasses.sort((a, b) => a.name.localeCompare(b.name));
            this.allFolderBuckets = allFolderBuckets.filter(x => x.type === this.folderType);
            this.allFolderBuckets.forEach(x => {
                x.name = this.existingItemMappings.find(y => y.id === x.itemId)?.name;
                this.rootBucketsForPreview.push(x);
            });

            this.existingItemMappings.forEach(x => {
                if (!this.rootBucketsForPreview.find(y => y.itemId === x.id)) {
                    this.rootBucketsForPreview.push({
                        id: 0,
                        bucket: null,
                        bucketClassId: this.getRootFolderBucketClass(this.folderType),
                        itemId: x.id,
                        sellerId: null,
                        type: this.folderType,
                        sequenceNo: null,
                        bucketClass: null,
                        name: x.name
                    });
                }
            });
            this.rootBucketsForPreview.sort((a, b) => a.name.localeCompare(b.name)).forEach((x, index) => {
                if (x.sequenceNo === null) {
                    x.sequenceNo = 1000000 + index;
                }
            });

            this.bucketClassesForPreview = [];
            this.existingBucketClasses.forEach(x => {
                const buckets = this.allFolderBuckets.filter(y => x.id === y.bucketClassId);
                this.bucketClassesForPreview.push({
                    id: x.id,
                    name: x.name,
                    folderType: x.folderType,
                    buckets
                });
                buckets.forEach(y => {
                    this.rootBucketsForPreview = this.rootBucketsForPreview.filter(z => z.itemId !== y.itemId);
                });
            });

            this.folderBucket = folderBucket ? folderBucket : new Bucket({
                id: 0,
                bucketClassId: 0,
                itemId: this._itemId,
                sellerId: null,
                type: this.folderType,
                sequenceNo: 1
            });
            this.sequenceNo = this.folderBucket.sequenceNo;
            this.radioSelectedValue = (this.folderBucket.id === 0 || !this.folderBucket.bucketClassId || this.folderBucket.bucketClassId === this.getRootFolderBucketClass(this.folderType))
                ? this.radioValueNoFolder : this.radioValueExisting;
            this.selectedBucketClassId = this.radioSelectedValue === this.radioValueExisting ? this.folderBucket.bucketClassId
                : (this.existingBucketClasses.length > 0 ? this.existingBucketClasses[0].id : null);
            this.createNewFolderName = '';
            this.bucketClassNameExists = false;

            // Construct the preview nav items
            this.menuItems = [];
            const rootItem = this.createRootItem();
            this.bucketClassesForPreview.forEach((x, index) => {
                const folderItem = this.createFolderItem(x.name, x.id, (this.radioSelectedValue === this.radioValueExisting && this.selectedBucketClassId === x.id), false);
                x.buckets.forEach(y => {
                    folderItem.items.push(this.createItem(y.name, y.itemId, y.sequenceNo, true));
                });
                // Don't display empty folders
                if (folderItem.items.length > 0) {
                    rootItem.items.push(folderItem);
                }
           });
            this.rootBucketsForPreview.forEach(y => {
                rootItem.items.push(this.createItem(y.name, y.itemId, y.sequenceNo, false));
            });
            this.menuItems.push(rootItem);

            this.renumberSortOrders();
            this.updateOrderButtons();
            this.updateSaveButtonState();

            setTimeout(() => {
                this.displayPreviewItem();
                this.isLoading = false;
            });
        });
    }

    closePopup(): void {
        this.isPopupVisibleChange.emit(false);
        this.isPopupVisible = false;
    }

    saveClick(): void {
        this.folderBucket.sequenceNo = this.sequenceNo;
        this.folderBucket.type = this.folderType;
        this.folderBucket.sellerId = null;
        this.folderBucket.itemId = this._itemId;
        switch (this.radioSelectedValue) {
            case this.radioValueNoFolder:
                this.folderBucket.bucketClassId = this.getRootFolderBucketClass(this.folderType);
                this.saveBucket();
                break;
            case this.radioValueExisting:
                this.folderBucket.bucketClassId = this.selectedBucketClassId;
                this.saveBucket();
                break;
            case this.radioValueCreateNew:
                const newBucketClass = new BucketClass({
                    name: this.createNewFolderName,
                    folderType: this.folderType
                });
                this.bucketService.insertBucketClass(newBucketClass).subscribe(bucketClassId => {
                    this.folderBucket.bucketClassId = bucketClassId;
                    this.saveBucket();
                }, error => {
                    this.toast.error(`An error occurred while trying to create new folder '${this.createNewFolderName}'`);
                });
                break;
        }

        this.closePopup();
    }

    cancelClick(): void {
        this.closePopup();
    }

    saveBucket(): void {
        if (this.folderBucket && this.folderBucket.id && this.folderBucket.id > 0) {
            // Update existing bucket
            this.bucketService.updateBucket(this.folderBucket).subscribe(response => {
                if (response === 1) {
                    this.handleSaveSuccess();
                }
            }, error => {
                this.toast.error('An error occurred while trying to save nav folder settings');
            });
        } else {
            // Create new bucket
            this.bucketService.insertBucket(this.folderBucket).subscribe(response => {
                this.handleSaveSuccess();
            }, error => {
                this.toast.error('An error occurred while trying to save new nav folder settings');
            });
        }
    }

    handleSaveSuccess(): void {
        const bucketSequenceContext: BucketSequenceContext = {
            bucketType: this.folderType,
            bucketSequenceNos: this.getBucketSequenceNos()
        };
        if (bucketSequenceContext.bucketSequenceNos.length > 1) {
            this.bucketService.updateBucketSequences(bucketSequenceContext).subscribe(response => {
                this.toast.success('Nav folder settings have been saved successfully');
                this.closePopup();
            }, error => {
                this.toast.error('An error occurred while trying to save nav folder sequence settings');
                this.closePopup();
            });
        } else {
            this.toast.success('Nav folder settings have been saved successfully');
            this.closePopup();
        }
    }

    updateSaveButtonState(): void {
        this.bucketClassNameExists = this.existingBucketClasses?.filter(x => x.name === this.createNewFolderName)?.length > 0;
        this.saveButtonDisabled = (this.radioSelectedValue === this.radioValueCreateNew && this.bucketClassNameExists) || !(
            (this.radioSelectedValue === this.radioValueNoFolder)
             || (this.radioSelectedValue === this.radioValueExisting && this.selectedBucketClassId > 0)
             || (this.radioSelectedValue === this.radioValueCreateNew && this.createNewFolderName.length > 0)
        );
    }

    getRootFolderBucketClass(bucketType: EnumBucketType): EnumBucketClass {
        switch (bucketType) {
            case EnumBucketType.Report:
                return EnumBucketClass.ReportRootFolder;
            case EnumBucketType.Dashboard:
                return EnumBucketClass.DashboardRootFolder;
            case EnumBucketType.Analytic:
                return EnumBucketClass.AnalyticRootFolder;
        }
    }

    getItemIcon(bucketType: EnumBucketType): string {
        switch (bucketType) {
            case EnumBucketType.Report:
                return 'file';
            case EnumBucketType.Dashboard:
                return 'chart';
            case EnumBucketType.Analytic:
                return 'mergecells';
        }
    }

    createRootItem(): any {
        return {
            text: this.itemTypeNavItemName,
            path: '',
            icon: this.getItemIcon(this.folderType),
            items: [],
            parent: null,
            disabled: false,
            topLevel: true,
            bucketFolder: false,
            sortOrder: 1,
            expanded: true
        };
    }

    createFolderItem(name: string, bucketClassId: number, expanded: boolean = false, isNew: boolean = false): any {
        return {
            text: name,
            path: '',
            icon: 'folder',
            items: [],
            parent: null,
            disabled: false,
            topLevel: true,
            bucketFolder: true,
            sortOrder: 0,
            expanded,
            bucketClassId,
            isNew
        };
    }

    createItem(name: string, id: number, sortOrder: number = 0, bucketFolder: boolean = false): any {
        return {
            text: name,
            path: this.getUniquePath(id),
            icon: 'staroutline',
            parent: null,
            disabled: false,
            topLevel: false,
            bucketFolder,
            sortOrder,
            itemId: id,
            isPreviewItem: id === this._itemId
        };
    }

    getUniquePath(id: number): string {
        return `${this.folderType}-${id}`;
    }

    onChange(): void {
        if (!this.isLoading) {
            setTimeout(() => {
                const item = this.createItem(this.existingItemMappings.find(x => x.id === this._itemId)?.name, this._itemId, 1, false);
                item.sortOrder = this.initialContentLoaded ? 1000 : this.sequenceNo;
                this.menuItems[0].items.forEach(x => x.expanded = false);

                this.removePreviewItem(item);
                switch (this.radioSelectedValue) {
                    case this.radioValueNoFolder:
                        item.bucketFolder = false;
                        this.menuItems[0].items.push(item);
                        break;
                    case this.radioValueExisting:
                        const folder = this.menuItems[0].items.find(x => x.text === this.existingBucketClasses.find(y => y.id === this.selectedBucketClassId)?.name);
                        if (folder) {
                            folder.expanded = true;
                            item.bucketFolder = true;
                            if (folder !== null) {
                                folder.items.push(item);
                            }
                        }
                        break;
                    case this.radioValueCreateNew:
                        if (this.createNewFolderName.length > 0) {
                            const newFolder = this.createFolderItem(this.createNewFolderName, 0, true, true);
                            item.bucketFolder = true;
                            newFolder.items = [ item ];
                            this.menuItems[0].items.push(newFolder);
                        }
                        break;
                }

                this.renumberSortOrders();
                this.updateMenuItems();
                this.updateSaveButtonState();
                this.displayPreviewItem();
            });
        }
    }

    moveItem(up: boolean): void {
        const items = this.getSiblingItems();
        let swapItemId1;
        let swapItemId2;
        const currentSortOrder = items.find(x => x.itemId && x.itemId === this._itemId)?.sortOrder;
        items.forEach(x => {
            if (x.itemId && x.itemId === this._itemId) {
                x.sortOrder += (up ? -1 : 1);
            } else if (x.sortOrder - 1 === currentSortOrder && !up) {
                swapItemId1 = x.itemId;
                swapItemId2 = this._itemId;
                x.sortOrder--;
            } else if (x.sortOrder + 1 === currentSortOrder && up) {
                swapItemId1 = this._itemId;
                swapItemId2 = x.itemId;
                x.sortOrder++;
            }
        });

        this.swapPositions(swapItemId1, swapItemId2);
        this.updateOrderButtons();
    }

    swapPositions(itemId1: number, itemId2: number): void {
        const doc = document as any;
        const element1 = doc.querySelector(`.item-id-${itemId1}`).parentNode.parentNode;
        const element2 = doc.querySelector(`.item-id-${itemId2}`).parentNode.parentNode;
        element1.parentNode.insertBefore(element1, element2);
    }

    displayPreviewItem(): void {
        // Ensure that the current item is visible to the user without having to scroll to find it
        setTimeout(() => {
            const doc = document as any;
            const previewItemElement = doc.querySelector('.preview-item')?.parentElement?.parentElement?.parentElement;
            if (previewItemElement) {
                let currentElement = previewItemElement;
                let position = previewItemElement.offsetTop;
                if (!previewItemElement.offsetParent.classList.contains('dx-scrollable-content')) {
                    position += previewItemElement.offsetParent.offsetTop;
                    currentElement = previewItemElement.offsetParent;
                }
                if (position < currentElement.scrollTop || position > (currentElement.offsetParent.scrollTop + currentElement.offsetParent.offsetParent.offsetHeight)
                    && !(currentElement.offsetParent.scrollTop === 0 && position < (currentElement.offsetParent.offsetParent.offsetHeight - 100))) {
                    this.sideNavigationMenu.menu.instance.scrollToItem(this.getUniquePath(this._itemId));
                }
            }
        });
    }

    removePreviewItem(item: any): any {
        // Remove any new folders or root preview item
        this.menuItems[0].items = this.menuItems[0].items.filter(x => !(x.isNew === true) && !(x.isPreviewItem === true));

        // Remove preview item in folder
        this.menuItems[0].items.forEach((x, xIndex) => {
            if (x.items && x.items.length > 0) {
                x.items = x.items.filter(y => !y.isPreviewItem);
            }
        });
    }

    updateMenuItems(): void {
        // Reset the items so that they become re-sorted
        this.menuItems = this.helperService.deepCopyTwoPointO(this.menuItems);
        this.updateOrderButtons();
    }

    updateOrderButtons(): void {
        const currentItem = this.getSiblingItems()?.find(x => x.itemId === this._itemId);
        this.upButtonDisabled = this.radioSelectedValue === this.radioValueCreateNew
            || !currentItem || !this.getSiblingItems().some(x => x.sortOrder < currentItem.sortOrder);
        this.downButtonDisabled = this.radioSelectedValue === this.radioValueCreateNew
            || !currentItem || !this.getSiblingItems().some(x => x.sortOrder > currentItem.sortOrder);
    }

    getBucketSequenceNos(): any[] {
        const items = this.getSiblingItems();
        return items.map(x => ({ itemId: x.itemId, sequenceNo: x.sortOrder }));
    }

    getSiblingItems(): any[] {
        let items = [];
        switch (this.radioSelectedValue) {
            case this.radioValueNoFolder:
                items = this.menuItems[0].items.filter(x => x.itemId && x.itemId > 0);
                break;
            case this.radioValueExisting:
                items = this.menuItems[0].items.find(x => x.bucketClassId && x.bucketClassId === this.selectedBucketClassId)?.items;
                break;
            case this.radioValueCreateNew:
                items = [];
                break;
        }
        return items;
    }

    renumberSortOrders(): void {
        this.menuItems[0].items.filter(x => x.bucketClassId && x.bucketClassId > 0).forEach(folderItem => {
            this.renumberSortOrderItems(folderItem.items);
        });
        this.renumberSortOrderItems(this.menuItems[0].items.filter(x => x.itemId && x.itemId > 0));
    }

    renumberSortOrderItems(items: any[]): void {
        items.sort((a,b) => a.sortOrder - b.sortOrder);
        items.forEach((x, index) => {
            x.sortOrder = index + 1;
        });
    }
}
