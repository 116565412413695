import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { FormulaBuilderTreeItem } from '../../models/formula-builder-tree-item';
import { FormulaBuilderDataValue } from '../../models/formula-builder-data-value';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { ReadOnlyStringInputProperties } from '../../models/core-string-input-properties';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';

@Component({
    selector: 'app-bb-formula-builder',
    templateUrl: './bb-formula-builder.component.html',
    styleUrls: ['./bb-formula-builder.component.scss']
})
export class BbFormulaBuilderComponent implements OnInit, OnChanges {

    @Input() value: string;
    @Input() data: FormulaBuilderTreeItem[];
    // Id must be unique - it is used for element id attributes
    @Input() id: string;
    @Output() valueChange = new EventEmitter<string>();

    public popupVisible: boolean;
    public description: string;
    public formulaCursorPosition: number;
    public friendlyValue: string;
    public displaySql: boolean;
    public readOnlyStringProperties = new ReadOnlyStringInputProperties();
    private scopeContainerId: string = '';
    private showEndDatedRules: boolean = false;

    constructor(private bbService: BuildingBlocksService,
        private bbHelperService: BuildingBlockHelperService) {

    }

    ngOnInit() {
        this.bbHelperService.getShowAdvancedView().subscribe(res => {
            this.displaySql = res;
        });
        this.bbHelperService.getScopeContainer().subscribe(res => {
            if(!res){
                return;
            } else if(res.id.includes('Co')){
                this.scopeContainerId = res.id;
            } else {
                this.scopeContainerId = res.parentId;
            }

        });
        this.bbHelperService.getShowEndDatedRules().subscribe(res => {
            this.showEndDatedRules = !res;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['value']?.firstChange){
            this.bbService.getFriendlyFormulaString(changes['value'].currentValue, '0').subscribe(res => {
                this.friendlyValueUpdate(res);
            });
        }
        if(changes['friendlyValue']?.firstChange){
            this.getSqlFormula();
        }
    }

    launchPopup(): void {
        this.popupVisible = true;
    }

    friendlyValueUpdate(friendlyValue: string): void {
        this.friendlyValue = friendlyValue;
        this.getSqlFormula();
    }

    getSqlFormula = async () => {
        const sqlFormulaString = await this.bbService.getSqlFormulaString(this.friendlyValue, this.scopeContainerId.match('[0-9]+')[0]).toPromise();
        this.valueChange.emit(sqlFormulaString);
        this.value = sqlFormulaString;
    };
}
