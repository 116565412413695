<link rel="stylesheet" href="https://cdn3.devexpress.com/jslib/20.2.4/css/dx-diagram.min.css" />
<script src="https://cdn3.devexpress.com/jslib/20.2.4/js/dx-diagram.min.js"></script>
<style>
    .nonInteract:hover  {
        cursor: default !important;
    }
    .colorRect:hover  {
        cursor: pointer !important;
    }
</style>
<dx-diagram
    class="bb-diagram"
    #diagram
    width="100%"
    height="100%"
    (onCustomCommand)="handleCustomCommand($event)"
    (onRequestEditOperation)="handleEdit($event)"
    (onSelectionChanged)="this.onDiagramSelectionChanged($event)"
    [units]="'px'"
    [viewUnits]="'px'">
    <dxo-editing
        [allowAddShape]="false"
        [allowChangeConnection]="false"
        [allowChangeConnectorPoints]="false"
        [allowChangeConnectorText]="false"
        [allowChangeShapeText]="false"
        [allowDeleteConnector]="false"
        [allowDeleteShape]="true"
        [allowMoveShape]="true"
        [allowResizeShape]="false">
    </dxo-editing>
    <svg
        *dxTemplate="let item of 'datasourceProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\setup-data.svg" x="8" y="0" width="84" height="84" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Data</title>
    </svg>
    <svg
        *dxTemplate="let item of 'transformProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\setup-transform.svg" x="2" y="0" width="96" height="96" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Transform</title>
    </svg>
    <svg
        *dxTemplate="let item of 'filterProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\setup-filter.svg" x="2" y="0" width="96" height="96" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Filter</title>
    </svg>
    <svg
        *dxTemplate="let item of 'calculateProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\setup-calc.svg" x="8" y="0" width="84" height="84" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Calculate</title>
    </svg>
    <svg
        *dxTemplate="let item of 'aggregateProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\setup-consolidate.svg" x="8" y="0" width="84" height="84" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Consolidate</title>
    </svg>
    <svg
        *dxTemplate="let item of 'unionProcessShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\blank-block.svg" x="8" y="0" width="84" height="84" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <title>Union</title>
    </svg>
    <svg
        *dxTemplate="let item of 'groupShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\ruler.svg" x="4" y="-15" width="100" height="100" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <title>Group</title>
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
        <text *ngIf="item.dataItem === undefined" style="text-anchor: middle; font-size: 6em" x="50" y="75">Gr</text>
    </svg>
    <svg
        *dxTemplate="let item of 'botShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <title>{{item.dataItem.description}}</title>
        <image href="..\..\..\..\assets\images\bot.svg" x="8" y="0" width="84" height="84" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'blockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect x="1" y="1" width="98" height="98" style="fill: brown; stroke-width: 6" class="main-polygon" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g *ngFor="let tag of item.dataItem.tags; index as i;">
            <text #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'accountAssignBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\account-assign-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
            <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
        <title>{{item.dataItem.description}}</title>
    </svg>
    <svg
        *dxTemplate="let item of 'periodBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect x="1" y="1" width="98" height="98" style="fill: yellow; stroke-width: 6" class="main-polygon" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g *ngFor="let tag of item.dataItem.tags; index as i;">
            <text #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'accountNormalizeBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect
            x="1"
            y="1"
            width="98"
            height="98"
            style="fill: orange; stroke-width: 6"
            class="main-polygon"
            [class.duplicate]="item.dataItem !== undefined && item.dataItem.isDuplicate" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g *ngFor="let tag of item.dataItem.tags; index as i;">
            <text #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'earnDateBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect
            x="1"
            y="1"
            width="98"
            height="98"
            style="fill: midnightblue; stroke-width: 6"
            class="main-polygon"
            [class.duplicate]="item.dataItem !== undefined && item.dataItem.isDuplicate" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g *ngFor="let tag of item.dataItem.tags; index as i;">
            <text #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'counterBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\counter-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'formulaBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\formula-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
            <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
            <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                <title>{{tag.hoverText}}</title>
            </rect>
            <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
        </g>
        <title>{{item.dataItem.description}}</title>
    </svg>
    <svg
        *dxTemplate="let item of 'filterBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        [class.head]="item.dataItem !== undefined && item.dataItem.isHead">
        <image href="..\..\..\..\assets\images\filter-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'joinBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\join-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'consolidateBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect
            x="1"
            y="1"
            width="98"
            height="98"
            style="fill: midnightblue; stroke-width: 6"
            class="main-polygon"
            [class.duplicate]="item.dataItem !== undefined && item.dataItem.isDuplicate" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g *ngFor="let tag of item.dataItem.tags; index as i;">
                <text #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'hierarchyBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\hierarchy-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'datasourceBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <title>{{item.dataItem.description}}</title>
        <image href="..\..\..\..\assets\images\datasource-block.svg" x="0" y="-15" width="100" height="100" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" [class]="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'unionBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\union-block.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" [class]="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <svg
        *dxTemplate="let item of 'updateXactionBlockShapeTemplate'"
        class="coreDiagramShape"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <image href="..\..\..\..\assets\images\update-gear.svg" x="15" y="0" width="75" height="75" [attr.opacity]="item.dataItem !== undefined && (item.dataItem.isForeign || item.dataItem.isLowOpacity) ? .3 : 1" />
        <image [attr.opacity]="item.dataItem !== undefined && item.dataItem.isHead ? 1 : 0" href="..\..\..\..\assets\images\greystar.png" x="22" y="8" width="10" height="10" />
        <title>{{item.dataItem.description}}</title>
        <g *ngIf="item.dataItem" >
            <g class="nonInteract" *ngFor="let tag of item.dataItem.tags; index as i;">
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20">{{tag.value}}</text>
                <rect class="colorRect" #colorRect [attr.x]="(100 - getAuditWidth(auditText)) / 2" [attr.y]="-25 * i - 35" [attr.width]="getAuditWidth(auditText)" height="20" [style]="tag.colorStyle">
                    <title>{{tag.hoverText}}</title>
                </rect>
                <text class="colorRect" #auditText x="50" [attr.y]="-25 * i - 20"><title>{{tag.hoverText}}</title>{{tag.value}}</text>
            </g>
        </g>
    </svg>
    <dxo-toolbox visibility="disabled"></dxo-toolbox>
    <dxo-properties-panel visibility="disabled"></dxo-properties-panel>
    <dxo-context-menu [commands]="scopeContainer?.name.includes('`0') ? limitedContextMenuCommands : contextMenuCommands"></dxo-context-menu>
</dx-diagram>

<dx-popup [width]="420" [height]="280" title="Create New Gearbox" [showCloseButton]="true" [(visible)]="isNewBotTemplatePopupVisible">
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="createNewBotButtonOptions"></dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="cancelNewBotButtonOptions"></dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'">
        <dx-form id="form" labelLocation="left" [colCount]="2" [(formData)]="newBotContext">
            <dxi-item dataField="name" colSpan="2">
                <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
                <dxi-validation-rule type="custom" [validationCallback]="isBotTemplateNameUnique" message="Must be unique"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="description" colSpan="2"></dxi-item>
            <dxi-item
                dataField="createInstance">
                <dxo-label text="Create Here"></dxo-label>
            </dxi-item>
            <dxi-item dataField="createTemplate">
                <dxo-label text="Add to Library"></dxo-label>
            </dxi-item>
        </dx-form>
    </div>
</dx-popup>
<svg
viewBox="0 0 0 0"
preserveAspectRatio="none"
height="0px">
    <text #textSizeElement>{{textToGetSizeOf}}</text>
</svg>