import { Injectable } from '@angular/core';
import { SiteThemeConfig } from '../models/site-theme-config';
import { HelperService } from './helper.service';

@Injectable()
export class SiteThemeService {

    constructor(private helperService: HelperService) {

    }

    applySiteTheme(doc: any, siteThemeConfig: SiteThemeConfig): void {
        if (!this.helperService.isNullOrEmpty(siteThemeConfig.baseClassName)) {
            doc.body.classList.add(siteThemeConfig.baseClassName);
        }

        // These variables can be used in the CSS and SCSS files throughout the site
        Object.keys(siteThemeConfig.colorValues).forEach(colorValueName => {
            doc.documentElement.style.setProperty(`--${colorValueName}`, siteThemeConfig.colorValues[colorValueName]);
        });
    }
}
