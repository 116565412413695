import { Component, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BlockPropertyValue } from '../../models/building-blocks';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';

@Component({
  selector: 'app-apply-to',
  templateUrl: './apply-to.component.html',
  styleUrls: ['./apply-to.component.scss']
})
export class ApplyToComponent implements OnInit {
    @Input() propertyValue: BlockPropertyValue;

    functionId: number;
    redirTypes: any;
    qtyFields: any;
    accounts: any;
    positions: any;

	constructor(private buildingBlockHelper: BuildingBlockHelperService) { }

	ngOnInit() {
        this.buildingBlockHelper.getFocusedObject().subscribe(focusedObject => {
            this.functionId = focusedObject.id.startsWith('Sg') ? +focusedObject.id.replace('Sg', '') : null;
        });
        this.buildingBlockHelper.getDataColumns().pipe(filter(x => x !== null)).subscribe(dataColumns => {
            this.qtyFields = this.buildingBlockHelper.getLookupByDatatype('Quantity Fields');
            this.accounts = this.buildingBlockHelper.getLookupByDatatype('seller');
            this.positions = this.buildingBlockHelper.getLookupByDatatype('account_attribute_4');
        });
        this.redirTypes = this.buildingBlockHelper.getLookupByDatatype('Apply to');
	}

    onInitNewRow(e: any): void {
        e.data.functionId = this.functionId;
        e.data.ratio = 1;
    }

    onAccountsToolbarPreparing(e: any): void {
        e.toolbarOptions.items.push({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'group',
                hint: 'Add all',
                onClick: () => {
                    const sellerSplits = this.propertyValue.value.seller;
                    sellerSplits.push(...this.accounts.dataSource
                        .filter(x => !sellerSplits.map(y => y.sellerId).includes(x.id))
                        .map(x => ({sellerId: x.id, functionId: this.functionId, ratio: 1})));
                }
            }
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'remove',
                hint: 'Delete all',
                onClick: () => {
                    this.propertyValue.value.seller = [];
                }
            }
        });
    }
}
