export class Dashboard {
    id: number;
    name: string;
    xml: string;
    sellerIdString: string;
    priority: number;
    seriesIdList: string;
    recurrenceId: number;
    isPublic: boolean;
    includeZeroes: boolean;
    attributes: string;
    exportAttributes: string;
}
