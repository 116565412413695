import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DxButtonComponent, DxCheckBoxComponent, DxDropDownBoxComponent, DxFormComponent, DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { CoreDropdownItem } from '../../models/core-dropdown-item';
import { coreDropdownBlankItemValue } from '../../../shared/constants/constants';
import { CoreDropdownPopupProperties } from '../../models/core-dropdown-popup-properties';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { CoreColumn } from '../../models/core-column';
import { CoreGridComponent } from '../core-data-grid/core-data-grid.component';
import { CoreDynamicCellEditorProps } from '../../models/core-dynamic-cell-editor-props';

@Component({
    selector: 'core-dropdown',
    templateUrl: './core-dropdown.component.html',
    styleUrls: ['./core-dropdown.component.scss'],
    providers: []
})

export class CoreDropdownComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('deleteYesButton', { static: false }) deleteYesButton: DxButtonComponent;
    @ViewChild('deleteTextBox', { static: false }) deleteTextBox: DxTextBoxComponent;
    @ViewChild('coreDropDownBox', { static: false }) coreDropDownBox: DxSelectBoxComponent;
    @ViewChild('coreDropDownTable', { static: false }) coreDropDownTable: DxDropDownBoxComponent;
    @ViewChild('addForm', { static: false }) addForm: DxFormComponent;
    @ViewChild('dropDownCheckBox', { static: false }) dropDownCheckBox: DxCheckBoxComponent;

    @Input() set selectedValue(value: number){
        this._selectedCellValue.value = this.dataSource?.find(row => row.id === value)?.name;
        this._selectedValue = value;
    } get selectedValue(): number {
        return this._selectedValue;
    };
    @Input() props: CoreDropdownProperties;
    @Input() set columns(value: CoreColumn[]) {
        if (!this._columns) {
            this._columns = [];
        }

        value.forEach(column => {
            column.dataField = 'record.' + column.dataField;
            this._columns.push(column);
        });
    }

    @Input() set popupProps(value: CoreDropdownPopupProperties[]) {
        if (value && value.findIndex(x => x.buttonType === 'delete') !== -1) {
            this.deletePopupProps = value[value.findIndex(x => x.buttonType === 'delete')];
        }

        if (value && value.findIndex(x => x.buttonType === 'add') !== -1) {
            this.addPopupProps = value[value.findIndex(x => x.buttonType === 'add')];
        }

        // bind this to all callback functions
        if (value) {
            value.forEach(prop => {
                if (prop.coreFormFieldProperties) {
                    prop.coreFormFieldProperties.forEach(field => {
                        if (field.validationRules) {
                            field.validationRules.forEach(rule => {
                                if (rule.validationCallbackFunction && typeof(rule.validationCallbackFunction) === 'function') {
                                    rule.validationCallbackFunction = rule.validationCallbackFunction.bind(this);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    // used for hover interactions on buttons when multiple dropdowns are on the same page
    @Input() set dropdownName(value: string) {
        const name = (value === null || value === undefined) ? '' : '_' + value;
        this.renameButtonId = 'core-dropdown-rename-button' + name;
        this.renameButtonTarget = '#' + this.renameButtonId;
        this.addButtonId = 'core-dropdown-add-button' + name;
        this.addButtonTarget = '#' + this.addButtonId;
        this.saveButtonId = 'core-dropdown-save-button' + name;
        this.saveButtonTarget = '#' + this.saveButtonId;
        this.deleteButtonId = 'core-dropdown-delete-button' + name;
        this.deleteButtonTarget = '#' + this.deleteButtonId;
        this.checkBoxId = 'core-dropdown-check-box' + name;
    }

    @Input() dataSource: any[];

    @Output() selectionChanged = new EventEmitter<number>();
    @Output() itemClick = new EventEmitter<number>();
    @Output() onRename = new EventEmitter<string>();
    @Output() onAdd = new EventEmitter();
    @Output() onSave = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter();
    @Output() onCheckUncheck = new EventEmitter<any>();

    get selectedItem(): CoreDropdownItem {
        if (this.items !== null && this.items !== undefined) {
            return this.items.find(x => x.value === this.selectedValue);
        }
        return {
            value: 0,
            displayValue: '',
            record: null,
            renameDisabled: false,
            saveDisabled: false,
            deleteDisabled: false
        };
    }

    _columns: CoreColumn[];
    _selectedValue: number;
    _selectedCellValue: any = {
        value: null,
        setValue: (value: any) => {
            this._selectedCellValue.value = value;
            this._selectedValue = this.dataSource?.find(row => row.name === value)?.id;
            this.selectionChanged.emit(this.selectedValue);
        }
    };
    items: Array<CoreDropdownItem>;
    blankValue: number = coreDropdownBlankItemValue;
    blankDisplayValue: string = '<blank>';
    defaultPassword: string = 'Wait!';

    renameValue: string;
    addValue: string;
    deleteDisplayValue: string;

    // Popup visible flags
    renamePopupVisible: boolean;
    addPopupVisible: boolean;
    deletePopupVisible: boolean;

    // sets the button ids of a dropdown
    renameButtonId: string;
    renameButtonTarget: string;
    addButtonId: string;
    addButtonTarget: string;
    saveButtonId: string;
    saveButtonTarget: string;
    deleteButtonId: string;
    deleteButtonTarget: string;
    checkBoxId: string;

    // popup properties
    deletePopupProps: CoreDropdownPopupProperties;
    addPopupProps: CoreDropdownPopupProperties;
    addFormDefaults: any;

    tableSelectProps: CoreDynamicCellEditorProps;

    customAddValidationMessage: string;
    popupCancelButtonOptions: any = {
        text: 'Cancel',
        onClick: () => {
            this.addValue = '';
            this.addPopupVisible = false;
        },
        elementAttr: {class:'core-dropdown-add-form-button'}
    };
    popupSubmitButtonOptions: any = {
        useSubmitBehavior: true,
        validationGroup: 'addData',
        text:'OK',
        elementAttr: {class:' dx-button-default core-dropdown-add-form-button'}
    };
    constructor() {

    }

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.props !== null && this.props !== undefined && this.dataSource !== null && this.dataSource !== undefined) {
            const items: Array<CoreDropdownItem> = [];

            if (!this.props?.isBlankItemExcluded) {
                items.push({
                    value: this.blankValue,
                    displayValue: this.blankDisplayValue,
                    record: null,
                    renameDisabled: true,
                    saveDisabled: true,
                    deleteDisabled: true
                });
            }

            if (this.dataSource !== undefined && this.props !== undefined && this.props !== null) {
                const self = this;
                this.dataSource.forEach((item) => {
                    if (item.id !== null && item.id !== undefined) {
                        const dropdownItem = this.addItemToDropdown(item, self);
                        items.push(dropdownItem);
                    }
                });
            }

            this.items = items;
            if(this.props.useTableDropDown && this.props.searchEnabled){
                this.createForTableSelect(this._columns.map(col => {
                    col.dataField = col.dataField.replace('record.', '');
                    return col;
                }));
            }
        }
    }

    ngAfterViewInit() {
        if (this.deletePopupProps && this.deletePopupProps.preventionWord !== null
            && this.deletePopupProps.preventionWord !== undefined
            && this.deletePopupProps.preventionWord !== this.defaultPassword) {
            this.deleteYesButton.disabled = true;
        }
    }

    onSelectItem(event): void {
        // we only want to emit event when user triggers the change
        if (event.event !== undefined && event.value !== undefined) {
                this.selectedValue = event.value;
                this.selectionChanged.emit(this.selectedValue);
            }
        }

    onDropDownGridItemSelected = (selectedValue: CoreDropdownItem) => {
        this.onSelectItem({ event: 'dropDownTableItemSelected', value: selectedValue });
    };

    onItemClick(event): void {
        this.itemClick.emit(event.itemData.value);
    }

    onRenameClick(): void {
        this.renameValue = this.selectedItem.displayValue;
        this.renamePopupVisible = true;
        setTimeout(() => {
            const doc = document as any;
            if (doc.querySelector('.rename-textbox input[type="text"]')) {
                doc.querySelector('.rename-textbox input[type="text"]').select();
            }
        }, 550);
    }

    onAddClick(): void {
        if (this.props.allowOverwriteOnAdd && this.selectedItem !== undefined) {
            this.addValue = this.selectedItem.displayValue;
        } else {
            this.addValue = '';
        }

        this.addPopupVisible = true;
        if (this.addPopupProps?.popupValidationFunction) {
            this.customAddValidationMessage = this.addPopupProps.popupValidationFunction();
        }

        if (this.addPopupProps && this.addPopupProps.defaultFormValues && !this.addFormDefaults) {
            this.addFormDefaults = Object.assign({}, this.addPopupProps.defaultFormValues);
        } else if (this.addPopupProps && this.addPopupProps.defaultFormValues) {
            this.addPopupProps.defaultFormValues = Object.assign({}, this.addFormDefaults);
        }

        setTimeout(() => {
            const doc = document as any;
            if (doc.querySelector('.add-textbox input[type="text"]')) {
                doc.querySelector('.add-textbox input[type="text"]').select();
            }
        }, 550);
    }

    onSaveClick(): void {
        this.onSave.emit(this.selectedValue);
    }

    onDeleteClick(): void {
        this.deleteDisplayValue = this.selectedItem.displayValue;
        this.deletePopupVisible = true;
    }

    onCheckBoxClick(event: any): void {
        this.onCheckUncheck.emit(event);
    }

    rename(): void {
        this.renamePopupVisible = false;
        this.updateSelectedItemDisplayValue(this.renameValue, true);
        this.renameValue = '';
    }

    add(): void {
        const overwriteItem = this.items.filter(x => x.displayValue === this.addValue)[0];
        if (overwriteItem !== undefined) {
            const message = `Are you sure you want to replace the ${this.props.itemDescription} '${this.addValue}'?`;
            confirm(message, `Replace ${this.props.itemDescription}?`).then(dialogResult => {
                if (dialogResult) {
                    this.onSave.emit(overwriteItem.value);
                    this.resetAndHideAddPopup();
                }
            });
        } else {
            if (this.addValue !== '') {
                this.onAdd.emit(this.addValue);
                this.resetAndHideAddPopup();
            }
        }
    }

    onAddFormFieldDataChanged(event: any): void {
        if (this.addForm && this.addPopupVisible) {
            this.addForm.instance.validate();
        }
    }

    onAddFormSubmit(event: any) {
        this.onAdd.emit(new CoreEventArguments(null, null, this.addForm.formData));
        this.resetAndHideAddPopup();

        // prevents page refresh after form submission
        return false;
    }

    resetAndHideAddPopup() {
        this.addValue = '';
        this.addPopupVisible = false;
    }

    isAddDisabled() {
        if (this.addPopupProps && this.addPopupProps.popupValidationFunction && this.addPopupVisible) {
            if (this.customAddValidationMessage && this.customAddValidationMessage.length > 0) {
                return true;
            }
        }

        let trimmedAddValue = this.addValue;
        if (this.addValue !== null && this.addValue !== undefined) {
            trimmedAddValue = this.addValue.trim();
        }

        return (this.addValue === '' || trimmedAddValue === '' || this.displayValueExists(this.addValue));
    }

    delete(event: any): void {
        this.deletePopupVisible = false;
        const eventArgs = new CoreEventArguments(event, null, this.props.useTableDropDown ? this : this.coreDropDownBox);
        this.onDelete.emit(eventArgs);
    }

    displayValueExists(displayValue: string): boolean {
        return this.items?.filter(x => !this.props.allowOverwriteOnAdd && x.displayValue === displayValue)?.length > 0;
    }

    deletePopupValueChanged(event: any): void {
        this.deleteYesButton.disabled = !(event.value === this.deletePopupProps.preventionWord);
    }

    deletePopupHiding(event: any): void {
        if (this.deleteTextBox && this.deleteTextBox.value !== null && this.deleteTextBox.value !== undefined) {
            this.deleteTextBox.value = this.defaultPassword;
        }
    }

    setAddFormDefaults(newAddFormDefaults: any): void {
        this.addFormDefaults = newAddFormDefaults;
    }

    setSelectedValue(value: number): void {
        this.selectedValue = value;
    }

    setCheckBoxValue(newValue: boolean): void {
        this.dropDownCheckBox.value = newValue;
    }

    deleteItemByValue(value: any) {
        const index = this.items.findIndex(x => x.value === value);
        if (index >= 0) {
            this.items.splice(index, 1);
        }
    }

    renameItemByValue(value: any, newDisplayValue: string, sortFunction: (a: CoreDropdownItem, b: CoreDropdownItem) => number = null): void {
        const index = this.items.findIndex(x => x.value === value);
        if (index >= 0) {
            this.items[index].displayValue = newDisplayValue;

            if (sortFunction) {
                this.items.sort((a, b) => sortFunction(a, b));
            }
        }
    }

    addNewItemToDropdown(item: any, sortFunction: (a: CoreDropdownItem, b: CoreDropdownItem) => number = null): void {
        const newDropdownItem = this.addItemToDropdown(item, this);
        this.items.push(newDropdownItem);

        if (sortFunction) {
            this.items.sort((a, b) => sortFunction(a, b));
        }
    }

    updateDynamicButton(id: string, state: boolean): void {
        const classes = document.getElementById(id).classList;
        if (state !== classes.contains('active')) {
            classes.toggle('active');
        }
    }

    updateSelectedItemDisplayValue(newDisplayValue: string, shouldEmit: boolean = false): void {
        if (newDisplayValue !== this.selectedItem.displayValue) {
            this.selectedItem.displayValue = newDisplayValue;
            this.selectedItem.record.name = newDisplayValue;
            if (shouldEmit) {
                this.onRename.emit(newDisplayValue);
            }
        }
    }

    repaint(): void {
        if (this.props.useTableDropDown) {
            this.coreDropDownTable.instance.repaint();
        } else {
            this.coreDropDownBox.instance.repaint();
        }
    }

    createForTableSelect(columns: CoreColumn[]){
        const result = new CoreDynamicCellEditorProps();
        this.tableSelectProps = result.createForCoreTableSelectTemplate(this.dataSource, columns, 'name', 'name', 300, this.props.width, this.props.isBlankItemExcluded);
        if (this.props.validationGroup) {
            this.tableSelectProps = this.tableSelectProps.addValidationGroup(this.props.validationGroup);
        }
    }

    private addItemToDropdown(item: any, self: any): CoreDropdownItem {
        const dropdownItem: CoreDropdownItem = {
            value: item[self.props.valueProperty],
            displayValue: item[self.props.displayValueProperty],
            record: item,
            renameDisabled: false,
            saveDisabled: false,
            deleteDisabled: false
        };

        // Optional property definitions
        if (self.props.renameDisabledProperty !== undefined && self.props.renameDisabledProperty !== '') {
            dropdownItem.renameDisabled = item[self.props.renameDisabledProperty];
        }
        if (self.props.saveDisabledProperty !== undefined && self.props.saveDisabledProperty !== '') {
            dropdownItem.saveDisabled = item[self.props.saveDisabledProperty];
        }
        if (self.props.deleteDisabledProperty !== undefined && self.props.deleteDisabledProperty !== '') {
            dropdownItem.deleteDisabled = item[self.props.deleteDisabledProperty];
        }

        return dropdownItem;
    }
}
