import { GridProps } from './core-data-grid-properties';
import { DevExpressDropdownOptions } from './dev-express-dropdown-options';

export class CoreDropdownProperties {
    isBlankItemExcluded: boolean = false;
    valueProperty: string;
    displayValueProperty: string;
    renameDisabledProperty: string;
    saveDisabledProperty: string;
    deleteDisabledProperty: string;
    renameDisabled: boolean = false;
    addNewDisabled: boolean = false;
    saveDisabled: boolean = false;
    deleteDisabled: boolean = false;
    renameHidden: boolean;
    addNewHidden: boolean;
    saveHidden: boolean;
    deleteHidden: boolean;
    checkBoxVisible: boolean = false;
    dropdownHidden: boolean;
    width: string | number | (() => string | number);
    itemDescription: string;
    allowOverwriteOnAdd = false;
    addMessage: string;
    checkBoxHint: string;
    isCheckBoxChecked: boolean = true;
    isPublishShown: boolean = false;
    isLockShown: boolean = false;
    useTableDropDown: boolean = false;
    gridProps: GridProps;
    tableGridDropDownOptions: DevExpressDropdownOptions;
    dynamicButtons: any[] = [];
    placeholder: string;
    searchEnabled: boolean = false;
    validationGroup: string = '';

    constructor() {

    }

    createAll(isBlankItemExcluded: boolean = false, valueProperty?: string, displayValueProperty?: string, renameHidden?: boolean,
        addNewHidden?: boolean, saveHidden?: boolean, deleteHidden?: boolean, checkBoxVisible: boolean = false, dropdownHidden?: boolean,
        width?: string | number | (() => string | number), itemDescription?: string, renameDisabledProperty?: string, saveDisabledProperty?: string,
        deleteDisabledProperty?: string, allowOverwriteOnAdd = false, addMessage?: string, checkBoxHint?: string, isCheckBoxChecked: boolean = true): CoreDropdownProperties {

        this.isBlankItemExcluded = isBlankItemExcluded;
        this.valueProperty = valueProperty;
        this.displayValueProperty = displayValueProperty;
        this.renameDisabledProperty = renameDisabledProperty;
        this.saveDisabledProperty = saveDisabledProperty;
        this.deleteDisabledProperty = deleteDisabledProperty;
        this.renameHidden = renameHidden;
        this.addNewHidden = addNewHidden;
        this.saveHidden = saveHidden;
        this.deleteHidden = deleteHidden;
        this.checkBoxVisible = checkBoxVisible;
        this.dropdownHidden = dropdownHidden;
        this.width = width;
        this.itemDescription = itemDescription;
        this.allowOverwriteOnAdd = allowOverwriteOnAdd;
        this.addMessage = addMessage;
        this.checkBoxHint = checkBoxHint;
        this.isCheckBoxChecked = isCheckBoxChecked;

        return this;
    }

    convertToTable(gridProps: GridProps, gridWidth: number, gridHeight: number) {
        this.useTableDropDown = true;
        this.gridProps = gridProps;
        this.gridProps.isFocusedRowEnabled = true;
        this.gridProps.keyColumn = 'value'; // 'record.' + this.valueProperty;
        this.gridProps.height = gridHeight;
        this.tableGridDropDownOptions = new DevExpressDropdownOptions(gridWidth);

        return this;
    }

    createPeriodDropdownDefaults(displayValueProperty: string, showPeriodControls: boolean, isPublishShown: boolean, isLockShown: boolean, itemDescription?: string){
        this.isBlankItemExcluded = true;
        this.valueProperty = 'id';
        this.displayValueProperty = displayValueProperty;
        this.renameDisabledProperty = null;
        this.saveDisabledProperty = null;
        this.deleteDisabledProperty = showPeriodControls ? 'isLocked' : null;
        this.renameHidden = true;
        this.addNewHidden = !showPeriodControls;
        this.saveHidden = true;
        this.deleteHidden = !showPeriodControls;
        this.checkBoxVisible = false;
        this.dropdownHidden = false;
        this.width = 170;
        this.itemDescription = itemDescription;
        this.allowOverwriteOnAdd = false;
        this.addMessage = '';
        this.checkBoxHint = '';
        this.isCheckBoxChecked = false;
        this.isPublishShown = isPublishShown;
        this.isLockShown = isLockShown;

        return this;
    }

    addPublishButton(publishAction: (e) => void) {
        this.dynamicButtons.push(
            {id: 'publish-button', icon: 'share', hint: 'Publish/Unpublish', onClick: e => publishAction(e)},
        );
        return this;
    }

    addLockButton(lockAction: (e) => void){
        this.dynamicButtons.push(
            {id: 'lock-button', hint: 'Lock', onClick: e => lockAction(e)}
        );
        return this;
    }

    addRevertButton(revertAction: (e) => void){
        this.dynamicButtons.push(
            {id: 'revert-button', icon: 'revert', hint: 'Revert', onClick: e => revertAction(e)}
        );
        return this;
    }

    setProp(prop: string, value: any) {
        this[prop] = value;
        return this;
    }
}
