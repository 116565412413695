import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BlockPropertyService } from 'src/app/pages/building-blocks/block-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-bb-union-field-input',
    providers: [ {provide: SaveableBbProperty, useExisting: BbUnionFieldInputComponent }],
    templateUrl: './bb-union-field-input.component.html',
    styleUrls: ['./bb-union-field-input.component.scss']
})
export class BbUnionFieldInputComponent extends SaveableBbProperty implements OnInit  {
    @Input() datasource: any[];
    @Input() isEditable: boolean = true;
    @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
    internalDatasource: any[];
    isValid: boolean = true;

    blockPropertyMappings: Record<string, any>;
    sourceFieldDropdown: { dataSource: any[], valueExpr: string, displayExpr: string };
    auxFieldDropdown: { dataSource: any[], valueExpr: string, displayExpr: string };

    constructor(private blockPropertyService: BlockPropertyService, private bbHelper: BuildingBlockHelperService, private helperService: HelperService) {
        super();
    }

    ngOnInit(): void {
        const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
        this.internalDatasource = this.datasource.map(col => {
            const colCopy = this.helperService.deepCopyTwoPointO(col);
            if(idCols.includes(colCopy['sourceName'])){
                colCopy['sourceName'] = this.bbHelper.convertIdToNameColumn(colCopy['sourceName']);
            }
            if(idCols.includes(colCopy['auxName'])){
                colCopy['auxName'] = this.bbHelper.convertIdToNameColumn(colCopy['auxName']);
            }
            return colCopy;
        });
        this.blockPropertyService.getMappings().subscribe(res => {
            if (this.isEditable === false){
                this.bbHelper.getDataColumns().subscribe(columns => {
                  const outputCols = this.bbHelper.convertProcessDataColumnsToDropdownDatasource(columns);
                  this.sourceFieldDropdown = outputCols;
                  this.auxFieldDropdown = outputCols;
                });
            }
            else {
                this.blockPropertyMappings = res;
                this.sourceFieldDropdown = this.blockPropertyMappings['fields'];
                this.auxFieldDropdown = this.blockPropertyMappings['auxFields'];
            }
        });
    }

    emitValue() {
        this.datasource = this.internalDatasource.map(entry => {
            const entryCopy = this.helperService.deepCopyTwoPointO(entry);
            if(!entryCopy['sourceName']){
                entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
                return entryCopy;
            }
            if(!entryCopy['auxName']){
                entryCopy['sourceName'] = this.bbHelper.convertNameToIdColumn(entryCopy['sourceName']);
                return entryCopy;
            }
            const sourceType: string = this.bbHelper.getDataColumnBySystemName(entryCopy['sourceName']).datatype;
            const auxType: string = this.bbHelper.getDataColumnBySystemName(entryCopy['auxName']).datatype;
            const isSourceConvertable = entryCopy['sourceName'].endsWith('_name');
            const isAuxConvertable = entryCopy['auxName'].endsWith('_name');
            if(isAuxConvertable && isSourceConvertable){
                if(sourceType === auxType){
                    entryCopy['sourceName'] = this.bbHelper.convertNameToIdColumn(entryCopy['sourceName']);
                    entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
                }
            } else if(isAuxConvertable && sourceType !== 'string'){
                entryCopy['auxName'] = this.bbHelper.convertNameToIdColumn(entryCopy['auxName']);
            } else if(isSourceConvertable && auxType !== 'string'){
                entryCopy['sourceName'] = this.bbHelper.convertNameToIdColumn(entryCopy['sourceName']);
            }
            return entryCopy;
        });
        this.datasourceChange.emit(this.datasource.map(entry => ({ sourceName: entry['sourceName'], auxName: entry['auxName'], alias: entry['alias'], outputId: entry['outputId']})));
    }

    checkForChanges() {
        if(this.dataGrid.instance.hasEditData()){
            this.bbHelper.setGridPropertyAsUnsaved('fieldMaps');
        } else {
            this.bbHelper.removeGridPropertyFromUnsaved('fieldMaps');
        }
    }

    checkColumnsValid = ({data: {sourceName, auxName}}) => sourceName || auxName;

    saveInternalData(): Promise<void> {
        return this.bbHelper.saveWithUpdatedOutputId(this.internalDatasource, this.dataGrid);
    }

    // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
    filterToolbarItems(e) {
        e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
    }

    sortColumn(data) {
        const column = this as any;
        const value = column.calculateCellValue(data);
        return column.lookup.calculateCellValue(value);
      }
}
