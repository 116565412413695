export class DistributionList {
    id: number;
    name: string;
    userId: number;
    layoutString: string;
    expanded: boolean;
    includeInactive: boolean;

    constructor(id?: number, name?: string, userId?: number, layoutString?: string, expanded?: boolean, includeInactive?: boolean) {
        this.id = id;
        this.name = name;
        this.userId = userId;
        this.layoutString = layoutString;
        this.expanded = expanded;
        this.includeInactive = includeInactive;
    }

    createDummy(): DistributionList {
        this.id = 0;
        this.name = '<None>';
        this.userId = null;
        this.layoutString = null;
        this.expanded = false;
        this.includeInactive = false;

        return this;
    }
}
