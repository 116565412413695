import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, NgModule, OnInit, ViewContainerRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DxReportViewerComponent, DxReportViewerModule } from 'devexpress-reporting-angular';
import { DxButtonModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';
import { environment } from '../../../../environments/environment';
import { ReportContext } from '../../models/contexts/report-context';
import { PeriodService } from '../../services/period.service';
import { ReportService } from '../../services/report.service';
import { SellerService } from '../../services/seller.service';
import { AuthService } from '../../services/auth.service';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils';
import { HelperService } from '../../services/helper.service';
import { CoreSimplePopupComponent } from '../core-simple-popup/core-simple-popup.component';
import { PermissionService } from '../../services/permission.service';
import { CoreFeature, EnumBucketClass, EnumBucketType, EnumUserGroup, settingClassIds } from '../../constants/enums';
import { ReportViewerToolbarActionIds as ToolbarActionIds } from '../../constants/dev-extreme-enums';
import { SettingService } from '../../services/setting.service';
import { forkJoin } from 'rxjs';
import { HistSeller } from '../../models/hist-seller';
import { HistSellerService } from '../../services/hist-seller.service';
import DevExpress from 'devextreme';
import * as ko from 'knockout';
import { CoreComponentModule } from '../core-component.module';
import { CommonModule } from '@angular/common';
import { Bucket } from '../../models/bucket';
import { BucketService } from '../../services/bucket.service';
import { ToastrService } from 'ngx-toastr';
import { ReportingParametersComponent } from '../reporting-parameters/reporting-parameters.component';
import { RuleBotChatModule } from '../corebot/rulebot-chat/rulebot-chat.module';
@Component({
    selector: 'app-report-viewer',
    templateUrl: './report-viewer.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./report-viewer.component.scss'],
    providers: [AuthService, ReportService, SellerService]
})
export class ReportViewerComponent implements OnInit {
    @Input() showLoadingPanel: boolean;
    @Input() hasAdminView: boolean;
    @Input() isImplementer: boolean = false;
    @Input() isEmailEnabled: boolean = true;
    @Input() isHomeDefaultEnabled: boolean;
    @Input() isUsingCoreBotTools: boolean = false;
    @Output() onEditReport = new EventEmitter();
    @Output() onLoadingCompleted = new EventEmitter();
    @Output() onEmailClicked = new EventEmitter();
    @Output() onReportParamsApplied = new EventEmitter();

    @ViewChild(DxReportViewerComponent, { static: true })
    reportViewer: DxReportViewerComponent;
    @ViewChild('reportParams') reportParams: ReportingParametersComponent;

    username: string;
    sellerId: number;
    isErrorPopupVisable: boolean = false;
    reportUrl: string;
    loadingVisible: boolean;
    hostUrl: string = environment.apiEndpoint;
    invokeAction: string = '/DXXRDV';
    token = localStorage.getItem('jwt');
    indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';
    exportVisible = ko.observable(true);
    isReportParamsVisible: boolean = false;
    reportId: number;
    permissionEditReport: boolean;
    permissionExportReport: boolean;
    isHomeDefault: boolean;
    defaultBucket: Bucket = new Bucket({bucketClassId: EnumBucketClass.DefaultHome, type: EnumBucketType.Report});

    isAuditBotVisible: boolean = false;
    reportAuditInfo: any;
    isAuditDisabled = ko.observable(true);
    initialMessage = 'Need help tracking down a report issue? Click on any report value and ask me about its origins, and I\'ll provide details on how it came to be.';
    userHasBotAccess: boolean = false;

    constructor(private http: HttpClient,
        private authService: AuthService,
        private reportService: ReportService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private helperService: HelperService,
        private permissionService: PermissionService,
        private settingService: SettingService,
        private histSellerService: HistSellerService,
        private bucketService: BucketService,
        private viewContainerRef: ViewContainerRef,
        private toast: ToastrService) {

        ajaxSetup.ajaxSettings = {
            beforeSend: (jqxhr, settings) => {
                jqxhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
            },
            headers: { Authorization: `Bearer ${this.token}` }
        };
    }

    ngOnInit() {
        this.username = this.authService.getUserFromToken();
        this.sellerId = this.authService.getSellerIdFromToken();
        if (this.hasAdminView === undefined) {
            this.sellerService.getHasAdminView().subscribe(result => this.hasAdminView = result);
        }
        this.permissionEditReport = this.permissionService.checkCurrentUserPermission(CoreFeature.EditReports.toString());
        this.permissionExportReport = this.permissionService.checkCurrentUserPermission(CoreFeature.ExportReports.toString());
        this.userHasBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());
        if (this.isImplementer === undefined){
            this.permissionService.getIsImplementer().subscribe(x => {
                this.isImplementer = x;
            });
        }
        this.exportVisible = ko.observable(this.permissionExportReport);
    }

    generateReport(id: number) {
        if (this.isHomeDefaultEnabled) {
            this.bucketService.getDefaultBucket(id, EnumBucketType.Report).subscribe(bucket => {
                this.isHomeDefault = !!bucket;
                this.updateHomeButtonStatus();
            });
        }

        if (this.reportParams.isDateOnly || this.reportParams.isAccountOnly) {
            this.onReportParamsApplied.emit();
            return;
        }

        if (!this.username || (!id && this.hasAdminView)) {
            return;
        }
        this.reportId = id;

        this.settingService.getBoolSetting(settingClassIds.InternalLimitReportDashboardExportsByAttribute).subscribe(isExportLimited => {
            if (isExportLimited) {
                forkJoin({
                    report: this.reportService.getReport(id),
                    mySeller: this.sellerService.getSeller(this.authService.getUserFromToken())
                }).subscribe(results => {
                    // TODO: Should move this attribute string check into attributeservices.
                    const currentAttributes = this.histSellerService.getCurrentHistSeller(results.mySeller.histSellers).attributes;
                    let didAttributesMatch: boolean = true;
                    if (results.report.exportAttributes !== null) {
                        for (const attribute of currentAttributes) {
                            if (!attribute.attributeClass.isText && !results.report.exportAttributes.includes('[' + attribute.id + ']')) {
                                didAttributesMatch = false;
                                break;
                            }
                        }
                    }

                    this.exportVisible(didAttributesMatch);
                });
            }
        });

        this.sellerService.getSellerWithSubordinateSetting(this.username).subscribe(seller => {
            this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {
                this.loadingVisible = true;
                const context = new ReportContext();
                context.id = id;
                context.beginDate = new Date(localStorage.getItem('beginDate') + 'Z');
                context.endDate = new Date(localStorage.getItem('endDate') + 'Z');
                context.selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
                context.token = this.authService.getUserFromToken();
                context.isDesigner = false;

                const seriesIdStr = sessionStorage.getItem('selectedSeries');
                context.seriesId = seriesIdStr ? this.helperService.parseBracketedIdString(seriesIdStr)[0] : null;

                this.loadSavedUserPageSettings();
                this.handleUserPageSettingChanges();

                this.reportService.getReportAsByteArray(context).subscribe(result => {
                    this.http.post<number>(environment.apiEndpoint + '/report/', result).subscribe(resultUrl => {
                        this.reportUrl = resultUrl.toString();
                        this.loadingVisible = false;
                        this.onLoadingCompleted.emit();
                    }, error => {
                        this.loadingVisible = false;
                        this.onLoadingCompleted.emit();
                        console.error(error);
                    });
                },
                _ => {
                    const popup = this.helperService.createComponent(CoreSimplePopupComponent);
                    popup.instance.props = {
                        message: 'Report no longer available. Please contact your administrator for any further questions.',
                        closeOnOutsideClick: false,
                        showCancelButton: false,
                        height: 200,
                        width: 300,
                        resizeEnabled: false,
                        title: 'Error: Report not available',
                        onOkClick: () => {
                            sessionStorage.setItem('selectedReport', undefined);
                            this.helperService.handleReportDashboardLoadError(this.hasAdminView);
                        }
                    };
                    this.helperService.injectComponent(this.viewContainerRef, popup);
                });

                if (this.hasAdminView) {
                    this.updateReportParamsLabel();
                }
            });
        });
    }

    editReport(): void {
        this.onEditReport.emit();
    }

    handleReportRedirect() {
        this.helperService.handleReportDashboardLoadError(this.hasAdminView);
    }

    customizeMenuActions(e) {
        e.args.Actions.find(action => action.id === ToolbarActionIds.Print).visible = this.exportVisible;
        e.args.Actions.find(action => action.id === ToolbarActionIds.PrintPage).visible = this.exportVisible;
        e.args.Actions.find(action => action.id === ToolbarActionIds.Export).visible = this.exportVisible;

        // Change the upload icon to a download icon
        const exportToToolbarItem = e.args.GetById(ToolbarActionIds.Export);
        exportToToolbarItem.imageTemplateName = 'web-report-download-icon';

        if (this.hasAdminView) {
            const designToolbarItem = e.args.GetById(ToolbarActionIds.Design);
            designToolbarItem.imageTemplateName = 'web-report-edit-icon';
            designToolbarItem.text = 'Edit';
            designToolbarItem.hasSeparator = false;
            designToolbarItem.visible = this.permissionEditReport && sessionStorage.selectedMenuItem.includes('report');
            designToolbarItem.clickAction = () => this.editReport();
        }

        if (this.isEmailEnabled) {
            e.args.Actions.push({
                id: ToolbarActionIds.Email,
                text: 'Email',
                imageTemplateName: 'web-report-email-icon',
                visible: true,
                disabled: false,
                hasSeparator: true,
                clickAction: () => this.onEmailClicked.emit()
            });
        }

        if ((this.userHasBotAccess && this.isUsingCoreBotTools) || this.isImplementer === true){
            e.args.Actions.push({
                id: ToolbarActionIds.CoreBot,
                text: 'Audit Report',
                imageTemplateName: 'web-report-audit-icon',
                visible: true,
                disabled: this.isAuditDisabled,
                hasSeparator: true,
                clickAction: () => this.onAuditBotClicked()
            });
        }

        if (this.hasAdminView) {
            e.args.Actions.push({
                id: ToolbarActionIds.DateAccount,
                text: 'Date/Account Selector',
                visible: true,
                disabled: false,
                hasSeparator: false,
                clickAction: () => this.isReportParamsVisible = true
            });
            setTimeout(() => this.updateReportParamsLabel());
        }

        if (this.isHomeDefaultEnabled) {
            e.args.Actions.push({
                id: ToolbarActionIds.Home,
                text: 'Show on Home Page',
                imageTemplateName: 'web-report-home-icon',
                visible: true,
                disabled: false,
                hasSeparator: false,
                clickAction: () => this.onHomeDefaultClicked()
            });
        }

        // Relative reordering to align with dashboards UI
        this.moveActionToLast(e.args.Actions, ToolbarActionIds.Design);
        this.moveActionToLast(e.args.Actions, ToolbarActionIds.Fullscreen);
        setTimeout(() => this.setMenuActionClasses());
    }

    moveActionToLast(actions: any[], id: string) {
        actions.push(actions.splice(actions.findIndex(x => x.id === id), 1)[0]);
    }

    setMenuActionClasses(): void {
        // Set items after email to show on the right - to align with dashboards UI
        const lastLeftItemId = this.isEmailEnabled ? 'email' : 'search';
        this.findToolbarButtonByTitleKeyword(lastLeftItemId).parentElement?.parentElement?.classList?.add('last-left');

        this.findToolbarButtonByTitleKeyword('date/account')?.parentElement?.parentElement?.classList?.add('report-params');
    }

    customizeExportOptions(e) {
        if (!this.permissionExportReport)
        {
            e.args.HideExportOptionsPanel();
        }
    }

    updateReportParamsLabel() {
        this.findToolbarButtonByTitleKeyword('date').innerHTML =
            `<div id="report-params-button">
                <i class="dx-icon-group"></i>
                <span>${this.helperService.getSessionDateRangeLabel()}</span>
            </div>`;
    }

    updateHomeButtonStatus(toastMsg = '') {
        const element = this.findToolbarButtonByTitleKeyword('home');
        if (this.isHomeDefault) {
            element.classList.add('toolbar-button-activated');
            element.title = 'Remove from Home Page';
        } else {
            element.classList.remove('toolbar-button-activated');
            element.title = 'Show on Home Page';
        }

        if (toastMsg) {
            this.toast.success(toastMsg);
        }
    }

    findToolbarButtonByTitleKeyword(keyword: string): HTMLElement {
        const items = Array.from(document.querySelectorAll<HTMLElement>('.dxrd-toolbar-item > :first-child > :first-child'));
        return items.find(x => x.title.toLowerCase().includes(keyword));
    }

    setDesignButtonVisibility(visible: boolean) {
        const designToolbarItemElement = document.querySelector<HTMLElement>('#reportViewer .dxrd-toolbar-item .dxrd-image-design')?.parentElement?.parentElement;
        if (designToolbarItemElement !== null) {
            if (visible) {
                designToolbarItemElement.classList.remove('hidden-toolbar-item');
            } else {
                designToolbarItemElement.classList.add('hidden-toolbar-item');
            }
        }
    }

    onHomeDefaultClicked() {
        const toastMsg = 'Home page updated';
        this.isHomeDefault = !this.isHomeDefault;
        if (this.isHomeDefault) {
            this.defaultBucket.itemId = this.reportId;
            this.bucketService.addDefaultBucket(this.defaultBucket).subscribe(() => this.updateHomeButtonStatus(toastMsg));
        } else {
            this.bucketService.deleteDefaultBucketForSeller().subscribe(() => this.updateHomeButtonStatus(toastMsg));
        }
    }

    openPopupAsDateSelector() {
        if (!this.isReportParamsVisible) {
            this.reportParams.openAsDateSelector();
        }
    }

    openPopupAsAccountSelector() {
        if (!this.isReportParamsVisible) {
            this.reportParams.openAsAccountSelector();
        }
    }

    getPageZoomLocalStorageKey(): string {
        return `core_reportviewer_zoom_${this.sellerId}`;
    }

    getMultipageModeLocalStorageKey(): string {
        return `core_reportviewer_multipagemode_${this.sellerId}`;
    }

    loadSavedUserPageSettings(): void {
        if (this.reportId) {
            let zoomFactor = localStorage.getItem(this.getPageZoomLocalStorageKey());
            if (zoomFactor === null) {
                zoomFactor = '1';
            }
            this.reportViewer.GetPreviewModel().reportPreview.zoom(zoomFactor);

            let isMultipageMode = false;
            const multipageModeValue = localStorage.getItem(this.getMultipageModeLocalStorageKey());
            if (multipageModeValue === 'true') {
                isMultipageMode = true;
            }
            this.reportViewer.GetPreviewModel().reportPreview.showMultipagePreview(isMultipageMode);
        }
    }

    onPrevClick(e: any){
        if (e){
            if (e.args.Brick.content && e.args.GetBrickText()){
                const preview = e.component.bindingSender.GetReportPreview();
                preview.pages()[e.args.PageIndex].selectBrick(e.args.Brick.indexes);
                this.reportAuditInfo = {Value: e.args.GetBrickValue(), CellContents: e.args.GetBrickText(), ReportParId: this.reportId};
            }
        }
    }

    getInitialBrick(e: any){
        this.isAuditDisabled(false);
        const prev = this.reportViewer.bindingSender.GetReportPreview();
        const pageBricks = prev.pages()[0].bricks();
        const validBricks = pageBricks.filter(x => x.content
            && x.content[1]
            && x.content[1].Value
            && x.content[1].Value.includes('reportInfo'));
        if (validBricks.length > 0){
            this.reportAuditInfo = {
                Value: validBricks[0].content[1].Value,
                CellContents: validBricks[0].content[0].Value,
                ReportParId: this.reportId
            };
        }
    }

    handleUserPageSettingChanges(): void {
        this.reportViewer.GetPreviewModel().reportPreview.zoom.subscribe(zoomFactor => {
            localStorage.setItem(this.getPageZoomLocalStorageKey(), zoomFactor);
        });

        this.reportViewer.GetPreviewModel().reportPreview.showMultipagePreview.subscribe(showMultipagePreview => {
            localStorage.setItem(this.getMultipageModeLocalStorageKey(), showMultipagePreview);
        });
    }

    onAuditBotClicked(){
        this.isAuditBotVisible = true;
    }

    onAuditBotPopupStateChanged(e: any){
        this.isAuditBotVisible = false;
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxReportViewerModule,
        DxLoadPanelModule,
        DxPopupModule,
        DxButtonModule,
        CoreComponentModule,
        RuleBotChatModule
    ],
    declarations: [ReportViewerComponent],
    exports: [ReportViewerComponent]
})
export class ReportViewerModule { }
