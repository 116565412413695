<div class="page-contents account-management">
    <dx-responsive-box>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-row [ratio]="0.5"></dxi-row>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="2"></dxi-col>
        <dxi-item>
            <dxi-location [row]="0" [col]="0" [rowspan]="2" [colspan]="2"></dxi-location>

            <div class="resizable-area" (window:resize)="onWindowResize()">
                <dx-resizable
                    [elementAttr]="{ class: 'resizable-panel' }"
                    handles="right"
                    [(width)]="leftPanelWidth"
                    [minWidth]="leftPanelMinWidth"
                    [maxWidth]="leftPanelMaxWidth"
                    (onResize)="onResizeLeftPanel($event)">
                    <dx-responsive-box>
                        <dxi-row [ratio]="1"></dxi-row>

                        <dxi-col [ratio]="1"></dxi-col>

                        <dxi-item class="above-closed-drawer">
                            <dxi-location [row]="0" [col]="0"></dxi-location>

                            <div>
                                <ng-container *ngIf="sellerColumns">
                                    <core-data-grid
                                        #sellerGrid
                                        class="accountFullHeight"
                                        [columns]="sellerColumns"
                                        [props]="sellerGridProps">
                                    </core-data-grid>
                                </ng-container>
                            </div>
                        </dxi-item>
                    </dx-responsive-box>
                </dx-resizable>
                <dx-resizable 
                    [elementAttr]="{ class: 'resizable-panel' }" 
                    handles="none" 
                    [width]="rightPanelWidth" 
                    [minWidth]="rightPanelMinWidth">
                    <dx-responsive-box>
                        <dxi-row [ratio]="1"></dxi-row>
                        <dxi-row [ratio]="1"></dxi-row>

                        <dxi-col [ratio]="1"></dxi-col>

                        <dxi-item class="above-closed-drawer">
                            <dxi-location [row]="0" [col]="0"></dxi-location>
                            <div>
                                <ng-container *ngIf="histColumns">
                                    <core-data-grid
                                        #histSellerGrid
                                        class="accountHalfHeight"
                                        [dataSource]="histSellers"
                                        [columns]="histColumns"
                                        [props]="histGridProps">
                                    </core-data-grid>
                                </ng-container>
                            </div>
                        </dxi-item>
                        <dxi-item class="above-closed-drawer">
                            <dxi-location [row]="1" [col]="0"></dxi-location>
                            <div>
                                <ng-container *ngIf="factorColumns">
                                    <core-data-grid
                                        #factorGrid
                                        class="accountHalfHeight"
                                        [dataSource]="accountFactors"
                                        [columns]="factorColumns"
                                        [props]="factorGridProps">
                                    </core-data-grid>
                                </ng-container>
                            </div>
                        </dxi-item>
                    </dx-responsive-box>
                </dx-resizable>
            </div>
        </dxi-item>
    </dx-responsive-box>
    <div *ngIf="!sellerColumns" id="loading-placeholder"></div>
</div>
<core-popup #coreDataGridPopup [props]="passwordConfirmationProps" [steps]="passwordConfirmationSteps"></core-popup>
<dx-popup title="Issues Detected" [(visible)]="isIssuePopupVisible" [width]="700" [height]="500" [showCloseButton]="true" [wrapperAttr]="{ class: 'issue-popup' }">
    We detected some inconsistencies in the manager hierarchy.
    <br />
    <br />
    <div>{{issueMessage}}</div>
    <br />
    Please fix these issues and save again.
</dx-popup>
<dx-popup title="Issues Detected" [(visible)]="isFactorIssuePopupVisible" [width]="700" [height]="500" [showCloseButton]="true" [wrapperAttr]="{ class: 'issue-popup' }">
    We detected some invalid start and end dates.
    <br />
    <br />
    <div>{{factorIssueMessage}}</div>
    <br />
    Please fix these issues and save again.
</dx-popup>

<dx-popup
    [height]="525"
    [width]="700"
    [showTitle]="true"
    title="Employee Info"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="addSellerPopupVisible"
    [position]="{ 
        my: 'top', 
        at: 'top', 
        of: 'window' 
    }"
    class="add-seller-popup">
    <dx-form #addSellerForm [colCount]="2" [(formData)]="newSeller">
        <dxi-item dataField="name" cssClass="nameTextbox" [editorOptions]="addSellerNameOptions">
            <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="importName" cssClass="importNameTextbox">
            <dxi-validation-rule type="required" message="Import Name is required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="beginDate" editorType="dxDateBox" [editorOptions]="beginDateEditorOptions">
            <dxo-label text="Start Date"></dxo-label>
            <dxi-validation-rule type="required" message="Start Date is required"></dxi-validation-rule>
        </dxi-item>
    </dx-form>

    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="addSellerPopupSaveButtonOptions"></dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="addSellerPopupCancelButtonOptions"></dxi-toolbar-item>
</dx-popup>
<dx-popup
    [showTitle]="true"
    title="Account: {{ openSeller?.name }}"
    [dragEnabled]="false"
    [showCloseButton]="false"
    [hideOnOutsideClick]="false"
    [(visible)]="editSellerPopupVisible"
    [position]="{
        my: 'top',
        at: 'top',
        of: '.main-scroll-view .dx-scrollview-content',
        offset: { y: 30 }
    }"
    class="edit-seller-popup">
    <dx-scroll-view>
        <dx-responsive-box>
            <dxi-row [ratio]="1"></dxi-row>
            <dxi-row [ratio]="1"></dxi-row>
            <dxi-row [ratio]="1"></dxi-row>

            <dxi-col [ratio]="1"></dxi-col>
            <dxi-col [ratio]="3"></dxi-col>

            <dxi-item>
                <dxi-location [row]="0" [col]="0" [rowspan]="3"></dxi-location>

                <div class="edit-seller-popup-left">
                    <dx-form #editSellerForm [colCount]="1" [(formData)]="openSeller" (onFieldDataChanged)="editSellerChange($event)">
                        <dxi-item
                            dataField="name"
                            cssClass="editNameTextbox"
                            [editorOptions]="{
                                disabled: !permissionEditSeller
                            }">
                            <dxo-label text="Display Name"></dxo-label>
                            <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
                        </dxi-item>

                        <dxi-item
                            dataField="emailAddress"
                            [editorOptions]="{
                                disabled: !permissionEditSeller
                            }">
                            <dxo-label text="Email Address"></dxo-label>
                        </dxi-item>

                        <dxi-item
                            dataField="loginName"
                            [editorOptions]="{
                                disabled: !permissionEditAccountSecurity
                            }">
                            <dxo-label text="Login Name"></dxo-label>
                        </dxi-item>

                        <dxi-item
                            dataField="userGroupId"
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                disabled: !permissionEditAccountSecurity,
                                items: availablePermissions,
                                valueExpr: 'id',
                                displayExpr: 'name'
                            }">
                            <dxo-label text="User Group"></dxo-label>
                            <dxi-validation-rule type="required" message="User Group is required"></dxi-validation-rule>
                        </dxi-item>

                        <dxi-item 
                            dataField="isPayee" 
                            editorType="dxCheckBox" 
                            [editorOptions]="{ 
                                disabled: !isImplementer 
                            }">
                            <dxo-label text="Is Payee"></dxo-label>
                        </dxi-item>

                        <dxi-item>
                            <dx-button text="Generate Password" [disabled]="generatePasswordDisabled" (onClick)="showPasswordConfirmationPopup()"></dx-button>
                        </dxi-item>

                        <dxi-item>
                            <core-data-grid
                                #popupImportGrid
                                [(dataSource)]="openSeller.sellerImports"
                                [columns]="popupImportColumns"
                                [props]="popupImportProps"
                                class="seller-import-grid">
                            </core-data-grid>
                        </dxi-item>
                    </dx-form>
                </div>
            </dxi-item>
            <dxi-item>
                <dxi-location [row]="0" [col]="1"></dxi-location>
                <div id="popupSellerNotes">
                    <div class="grid-label">Notes</div>
                    <dx-text-area [(value)]="openSeller.descr" height="150"></dx-text-area>
                </div>
            </dxi-item>
            <dxi-item>
                <dxi-location [row]="1" [col]="1"></dxi-location>
                <div>
                    <core-data-grid
                        #popupHistSellerGrid
                        *ngIf="histColumns"
                        [dataSource]="histSellers"
                        [columns]="histColumns"
                        [props]="popupHistGridProps">
                    </core-data-grid>
                </div>
            </dxi-item>
            <dxi-item>
                <dxi-location [row]="2" [col]="1"></dxi-location>
                <div>
                    <core-data-grid
                        #popupFactorGrid
                        *ngIf="factorColumns"
                        [(dataSource)]="openSeller.accountFactors"
                        [columns]="factorColumns"
                        [props]="popupFactorGridProps">
                    </core-data-grid>
                </div>
            </dxi-item>
        </dx-responsive-box>
    </dx-scroll-view>

    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="editSellerPopupSaveButtonOptions"></dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="editSellerPopupCancelButtonOptions"></dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="editSellerPopupDeleteButtonOptions"></dxi-toolbar-item>
</dx-popup>
<email-results [results]="sendEmailResults"></email-results>
<core-import
    #coreImport
    [importURL]="importURL"
    [importCleaningSourceData]="importCleaningSourceData"
    [description]="importDescription"
    (onImportSuccess)="getAccountData(true)">
</core-import>
<dx-load-panel
    #loadPanel
    [visible]="!sellerColumns || sellerDeleting || isLoadingVisible"
    shadingColor="rgba(0,0,0,0.4)"
    [shading]="true"
    [showIndicator]="true"
    [showPane]="true"
    [hideOnOutsideClick]="false"
    [position]="{ 
        of: '.main-scroll-view .dx-scrollview-content' 
    }">
</dx-load-panel>
