import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output, Input, QueryList, ViewChild, ViewChildren, ElementRef, AfterViewInit } from '@angular/core';
import { Dictionary } from 'src/app/shared/dictionary';
import { BbObject, Container, Group} from 'src/app/shared/models/building-blocks';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Node } from '../node';
import { Breadcrumb } from '../breadcrumb';
import { BuildingBlockHelperService } from '../building-block-helper.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BotInstanceContext } from 'src/app/shared/models/contexts/bot-instance-context';
import { confirm } from 'devextreme/ui/dialog';
import { coreResponseCodes, EnumContainerType, EnumSettingClassId, EnumUserGroup } from 'src/app/shared/constants/enums';
import { DxContextMenuComponent, DxDataGridComponent, DxDropDownButtonComponent, DxPopoverComponent, DxPopupComponent, DxTextAreaComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { PeriodService } from 'src/app/shared/services/period.service';
import { Period } from 'src/app/shared/models/period';
import { SettingService } from 'src/app/shared/services/setting.service';
import { SellerService } from 'src/app/shared/services/seller.service';

@Component({
  selector: 'app-bb-header',
  templateUrl: './bb-header.component.html',
  styleUrls: ['./bb-header.component.scss']
})
export class BbHeaderComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('botMapGrid', { static: false }) botMapGrid: DxDataGridComponent;
    @ViewChildren('botTemplateContextMenu') botTemplateContextMenu!: QueryList<DxContextMenuComponent>;
    @ViewChild('rulesDropdownButton', { static: false }) rulesDropdownButton: DxDropDownButtonComponent;
    @ViewChild('rulesTreeView', { static: false }) rulesTreeView: DxTreeViewComponent;

    @Output() onBlockInsert = new EventEmitter<any>();

    breadcrumbArray: Breadcrumb[] = [];
    scopeContainer: Container = null;
    objectStore: Dictionary<string, BbObject> = null;
    selectedNodes: Node[] = null;
    blockTypes: any[];
    blockTypesForDropdown: any[];

    botTemplates: any[];
    botInstanceContext: BotInstanceContext;
    isBotMappingPopupVisible: boolean = false;
    botMap: any[];
    foreignRefLookup: any[];
    localRefLookup: any;
    localFriendlyNames: any;
    selectedBotTemplate: Group;

    selectedSeriesId: number;
    selectedPeriodId: number;
    selectedRecurrenceId: number;
    periods: Period[];

    layoutDropdownProps: CoreDropdownProperties;
    auditMode: boolean = false;
    advancedViewMode: boolean = false;
    botViewMode: boolean = false;
    noHeadProcess: boolean = false;
    plansRules: any;

    isUserImplementer = false;
    auditPageMode: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(private buildingBlockHelper: BuildingBlockHelperService,
        private buildingBlocksService: BuildingBlocksService,
        private periodService: PeriodService,
        private sellerService: SellerService,
        private toast: ToastrService,
        private permissionService: PermissionService,
        private helperService: HelperService,
        private settingService: SettingService) {


    }

    ngOnInit(): void {
        this.buildingBlockHelper.getOriginWindow().subscribe(originWindow => {
            this.auditPageMode = originWindow != null;
        });
        this.buildingBlockHelper.getScopeContainer().pipe(takeUntil(this.unsubscribe$)).subscribe(scopeContainer => {
            this.scopeContainer = scopeContainer;
            if (scopeContainer) {
                this.refreshBreadcrumbs(scopeContainer);
            }
            if(scopeContainer?.id.startsWith('Co') || scopeContainer?.id.startsWith('Sg')) {
                this.highlightScopeInRuleDropdown();
                this.setViewModes(scopeContainer.id);
            }
            const headProcessId = scopeContainer?.['headProcessId'];
            this.noHeadProcess = this.helperService.isNullOrUndefined(headProcessId) || this.helperService.isEmptyOrSpaces(headProcessId);
            if(scopeContainer === undefined){
                this.onClickBackButton();
            }
        });
        this.buildingBlockHelper.getObjectStore().pipe(takeUntil(this.unsubscribe$)).subscribe(objectStore => {
            if (objectStore) {
                const activeContainers = this.buildingBlockHelper.getAllContainersForPlansView().filter(container => container.isActive);
                this.plansRules = activeContainers
                    .filter(container => container.typeId === EnumContainerType.Plan)
                    .map(container => ({
                        name: container.name,
                        id: container.id,
                        items: activeContainers
                                .filter((child, index, self) =>
                                    (child.parentId === container.id) && (index === self.findIndex((t) => (t.name === child.name))) && child.typeId !== EnumContainerType.SetupRule)
                                .sort((a, b) => a.name.localeCompare(b.name))
                    }))
                    .filter(plan => plan.items.length > 0)
                    .sort((a, b) => a.name.localeCompare(b.name));
                if(this.rulesTreeView) {
                    this.rulesTreeView.dataSource = this.plansRules;
                    this.highlightScopeInRuleDropdown();
                }

                if (this.objectStore) {
                    this.buildingBlockHelper.forceRefreshDiagram();
                }
                this.localFriendlyNames = [''];
                this.buildingBlockHelper.getDataColumns().pipe(filter(x => !!x), take(1)).subscribe(() => {
                    this.localRefLookup = this.buildingBlockHelper.getLocalLookupForForeignBots();
                    this.localFriendlyNames = this.localRefLookup.map(x => ({key: x.key, items: x.items.map(y => y.friendlyName)}));
                });
            }
            this.objectStore = objectStore;
        });
        this.buildingBlockHelper.getSelectedNodes().pipe(takeUntil(this.unsubscribe$)).subscribe(selectedNodes => {
            this.selectedNodes = selectedNodes;
        });
        this.buildingBlockHelper.getBotTemplates().subscribe(botTemplates => this.botTemplates = botTemplates);

        this.blockTypes = this.buildingBlocksService.getDiagramCustomShapesByCategory('Core.Blocks');
        // Need to get rid of the 'template' property for the select box, otherwise DevExpress uses it as the displayExpr no matter what
        this.blockTypesForDropdown = this.blockTypes.map(x => ({category: x.category, type: x.type, title: x.title, icon: 'plus'})).sort((a, b) => a.title.localeCompare(b.title));
        this.permissionService.getIsImplementer().subscribe(result => {
            if (result) {
                this.isUserImplementer = true;
            }
        });
        this.periodService.getPeriods().subscribe(periods => this.periods = periods);
    }

    ngAfterContentInit(): void {
        this.buildingBlockHelper.setSeriesId(this.selectedSeriesId);
        this.buildingBlockHelper.setPeriodId(this.selectedPeriodId);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    refreshBreadcrumbs(scopeContainer: Container): void {
        const newBreadcrumbArray = [];
        for (let block = scopeContainer; block !== null; block = this.buildingBlockHelper.getParentByChildObject(block)) {
            newBreadcrumbArray.unshift(new Breadcrumb(block));
        }
        this.breadcrumbArray = newBreadcrumbArray;
    }

    onBreadcrumbClick(breadcrumb: Breadcrumb): void{
        this.buildingBlockHelper.setScopeContainer(breadcrumb.container);
        this.buildingBlockHelper.setFocusedObject(breadcrumb.container);
    }

    toggleAdvancedView(e: any): void {
        this.buildingBlockHelper.setShowAdvancedView(e.value);
    }

    toggleBotView(e: any): void {
        this.buildingBlockHelper.setBotView(e.value);
    }

    onPeriodChanged(id: number | null): void {
        this.buildingBlockHelper.setSeriesId(this.selectedSeriesId);
        this.buildingBlockHelper.setRecurrenceId(this.selectedRecurrenceId);
        this.buildingBlockHelper.setPeriodId(id);
    }

    onClickBackButton(): void {
        this.buildingBlockHelper.scopeUp();
    }

    onSelectBlockType(e): void {
        if (e.selectedItem) {
            const item = this.blockTypes.find(x => x.type === e.selectedItem.type);
            this.onBlockInsert.emit(item);
            setTimeout(() => {
                e.component.instance().reset();
            }, 10);
        }
    }

    blankDisplayExpr = () => '';

    onBotSelect(e): void {
        const botId = e.selectedItem?.id;
        if (botId && this.scopeContainer?.id) {
            this.botInstanceContext = new BotInstanceContext();
            this.botInstanceContext.templateId = botId;
            this.botInstanceContext.ruleId = this.scopeContainer?.id;
            this.botInstanceContext.map = null;
            this.selectedBotTemplate = e.selectedItem;

            this.createBotInstance();
        }
    }

    createBotInstance() {
        this.buildingBlocksService.createBotInstance(this.botInstanceContext).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.buildingBlocksService.getContainerById(this.scopeContainer.id).subscribe(scopeContainer => {
                    this.buildingBlockHelper.setScopeContainer(scopeContainer);
                    this.buildingBlockHelper.replaceObjectInStore(scopeContainer);
                });
                this.buildingBlockHelper.refreshDataColumns();
            } else if (response.responseCode === coreResponseCodes.DirtyData) {
                this.botMap = [];
                this.foreignRefLookup = [];
                Object.keys(response.result).forEach(key => {
                    this.botMap.push({foreignName: key, localName: ''});
                    this.foreignRefLookup.push({id: key, name: response.result[key]});
                });
                this.isBotMappingPopupVisible = true;
            } else if (response.responseCode === coreResponseCodes.InvalidRequest) {
                this.toast.error(response.message);
            }
        });
    }

    onBotContextItemClick(e) {
        const botId = e.element.parentElement.id;
        if (e.itemData.name === 'delete') {
            const botName = this.botTemplates[1].items.find(x => x.id === botId).name.replace('`0', '');
            confirm('Are you sure you want to delete this template?<br><br>'+botName, 'Delete Bot Template').then((dialogResult) => {
                if (dialogResult) {
                    this.deleteBotTemplate(botId);
                }
            });
        } else if (e.itemData.name === 'edit') {
            this.buildingBlocksService.getContainerById(botId).subscribe(container => this.buildingBlockHelper.scopeDown(container));
        }
    }

    deleteBotTemplate(id: string) {
        this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(id, true);
        this.buildingBlocksService.deleteContainer(id).subscribe(res => {
            this.botTemplates[1].items = this.botTemplates[1].items.filter(x => x.id !== id);
            const updatedTemplates = this.botTemplates;
            this.botTemplates = [];
            setTimeout(() => this.botTemplates = updatedTemplates);
        });
    }

    onDropdownClickOut(e: any){
        if(!this.botTemplateContextMenu.some(x => x.visible)) {
            e.component.close();
        }
    }

    onSubmitBotMapping() {
        const flatLookup = this.localRefLookup.map(y => y.items).reduce((acc, val) => acc.concat(val), []);
        this.botMapGrid.instance.saveEditData();
        this.botMap.forEach(x => x.localName = flatLookup.find(y => y.friendlyName === x.localName)?.systemName ?? '');
        this.botInstanceContext.map = Object.assign({}, ...this.botMap.map((x) => ({[x.foreignName]: x.localName})));
        this.isBotMappingPopupVisible = false;
        this.createBotInstance();
    }

    onCancelBotMapping() {
        this.isBotMappingPopupVisible = false;
    }

    getBotTemplateName(): string {
        return this.botTemplates?.find(x => x.id === this.botInstanceContext?.templateId)?.name ?? 'Global Bot';
    }

    calculateForeignRefType(rowData): string {
        const foreignName = rowData.foreignName;
        const sourcePrefixes = {Bb: 'Gear', Co: 'Rule', Ds: 'Datasource', Sg: 'Rule'};
        const fieldPrefixes = {seller: 'Role Field', qty: 'Quantity Field', date: 'Date Field', text: 'Text Field', tag: 'Tag Field', calc: 'Calculated Quantity Field'};
        return sourcePrefixes[foreignName.substring(0, 2)] ?? fieldPrefixes[foreignName.split('_')[0]];
    }

    getFilteredlocalFriendlyNames = (options) => {
        const type = options.values?.[0];
        let lookup = [];

        if (type) {
            const isField = type.includes('Field');
            lookup = this.localFriendlyNames.filter(x => isField ? x.key === type : !x.key.includes('Field'));
        }
        return lookup;
    };

    navigateToOtherRule(e: any){
        if(this.buildingBlockHelper.hasUnsavedChanges()){
            this.buildingBlockHelper.notifyUserOfUnsavedChanges();
            return;
        }
        if(e.node.parent !== null) {
            this.closeRulesDropdown();
            const activeVersion = this.buildingBlockHelper.getActiveRuleVersionOrDefaultByPeriodId(e.node.key, this.selectedPeriodId, this.periods);
            this.buildingBlockHelper.scopeDown(activeVersion);
        }
    }

    closeRulesDropdown(){
        this.rulesDropdownButton.instance.toggle(false);
    }

    highlightScopeInRuleDropdown(e: any = null){
        const treeViewComponent = e?.component ?? this.rulesTreeView?.instance;
        if(treeViewComponent){
            treeViewComponent.unselectAll();
            treeViewComponent.collapseAll();
            treeViewComponent.expandItem(this.scopeContainer.parentId);
            treeViewComponent.selectItem(this.scopeContainer.id);
            if(this.rulesTreeView){
                this.rulesTreeView.searchValue = '';
            }
        }
    }

    setViewModes(id: string): void {
        const localStorageKey = this.buildingBlockHelper.getViewModesLocalStorageKey(id);
        const viewModesJSON = localStorage.getItem(localStorageKey);
        if (viewModesJSON) {
            const viewModes = JSON.parse(viewModesJSON);
            if (new Date(viewModes.expiry) > new Date()) {
                this.advancedViewMode = viewModes.advancedViewMode;
                this.auditMode = viewModes.auditMode;
                this.botViewMode = viewModes.botViewMode;
            }
            localStorage.removeItem(localStorageKey);
        }

        this.buildingBlockHelper.setShowAdvancedView(this.advancedViewMode);
        this.buildingBlockHelper.setBotView(this.botViewMode);
    }
}
