import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';

// eslint-disable-next-line max-len
LicenseManager.setLicenseKey(`Using_this_AG_Grid_Enterprise_key_( AG-045004 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Core Commissions )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Core Commissions )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Core Commissions )_need_to_be_licensed___( Core Commissions )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 September 2024 )____[v2]_MTcyNjc4NjgwMDAwMA==91b60d3c7279ed762f4eccc248d8cb6e`);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
