import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { DistributionList } from '../models/distribution-list';

@Injectable()
export class DistributionListService {
    private distributionListURL = environment.apiEndpoint + '/distributionlist/';

    constructor(private http: HttpClient) {
    }

    getDistributionLists(userId: number): Observable<DistributionList[]> {
        return this.http.get<DistributionList[]>(this.distributionListURL + userId.toString());
    }

    insertDistributionList(distributionList: DistributionList): Observable<DistributionList> {
        return this.http.post<DistributionList>(this.distributionListURL + 'insert', distributionList);
    }

    updateDistributionList(distributionList: DistributionList): Observable<number> {
        return this.http.put<number>(this.distributionListURL + 'update', distributionList);
    }

    deleteDistributionList(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.distributionListURL + 'distributionList/' + id.toString());
    }
}
