import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, Output, ViewChild } from '@angular/core';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTreeViewComponent, DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import DataSource from 'devextreme/data/data_source';
import * as events from 'devextreme/events';


@Component({
    selector: 'app-side-navigation-menu',
    templateUrl: './side-navigation-menu.component.html',
    styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
    @ViewChild(DxTreeViewComponent, { static: true })
    menu: DxTreeViewComponent;

    @Output()
    selectedItemChanged = new EventEmitter<string>();

    @Output()
    openMenu = new EventEmitter<any>();

    @Output()
    bucketsChanged = new EventEmitter<any>();

    @Output()
    favoriteClicked = new EventEmitter<string>();

    @Output()
    favoriteMouseEnter = new EventEmitter<any>();

    @Output()
    favoriteMouseLeave = new EventEmitter<any>();

    @Input()
    isPreview: boolean;

    dataSource: any;
    @Input()
    set items(value: any[]) {
        // Sorting the items by their defined sort order before binding to TreeView - up to 3 hierarchy levels need sorted
        value.forEach(item => {
            if (item.items && item.items.length > 0) {
                item.items.forEach(childItem => {
                    if (childItem.items && childItem.items.length > 0) {
                        // Sort items that are in folders
                        childItem.items = this.sortItems(childItem.items);
                    }
                });
                // Sort items that are children of top-level menu items
                item.items = this.sortItems(item.items);
            }
        });
        this.dataSource = new DataSource({
            // Sort top-level menu items
            store: this.sortItems(value)
        });
    }

    @Input()
    set selectedItem(value: string) {
        if (this.menu.instance) {
            if (value) {
                this.menu.instance.selectItem(value);
            } else {
                this.menu.instance.unselectAll();
            }
        }
    }

    private _compactMode = false;
    @Input()
    get compactMode() {
        return this._compactMode;
    }
    set compactMode(val) {
        this._compactMode = val;
        if (val && this.menu.instance) {
            this.menu.instance.collapseAll();
        }
    }

    constructor(private elementRef: ElementRef) { }

    updateSelection(event) {
        if (!this.isPreview) {
            const nodeClass = 'dx-treeview-node';
            const selectedClass = 'dx-state-selected';
            const leafNodeClass = 'dx-treeview-node-is-leaf';
            const element: HTMLElement = event.element;

            const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
            Array.from(rootNodes).forEach(n => {
                n.classList.remove(selectedClass);
            });

            let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
            while (selectedNode && selectedNode.parentElement) {
                // Preventing folders from appearing selected
                if (selectedNode.classList.contains(nodeClass) && !(selectedNode.children[0]?.children[0]?.classList?.contains('never-selected'))) {
                    selectedNode.classList.add(selectedClass);
                }
                selectedNode = selectedNode.parentElement;
            }

            if (selectedNode != null) {
                const node = selectedNode.querySelectorAll(`.${nodeClass}.${selectedClass}.${leafNodeClass}`);
                Array.from(node).forEach(n => {
                    sessionStorage.setItem('selectedMenuItem', n.getAttribute('data-item-id'));
                });
            }
        }
    }

    updateSelectionById(id) {
        const nodeClass = 'dx-treeview-node';
        const selectedClass = 'dx-state-selected';
        const focusedClass = 'dx-state-focused';
        const element: HTMLElement = this.menu.instance.element();

        // Remove selected class from all nodes
        const rootNodes = element.querySelectorAll(`.${nodeClass}`);
        Array.from(rootNodes).forEach(n => {
            n.classList.remove(selectedClass);
            n.classList.remove(focusedClass);
        });

        // Add selected class to selected node (and potential parent nodes)
        let selectedNode = element.querySelector(`.${nodeClass}[data-item-id="${id}"]`);
        while (selectedNode && selectedNode.parentElement) {
            if (selectedNode.classList.contains(nodeClass)) {
                selectedNode.classList.add(selectedClass);
            }
            selectedNode = selectedNode.parentElement;
        }

        // Set sessionStorage 'selectedMenuItem' value
        sessionStorage.setItem('selectedMenuItem', id);
    }

    onItemClick(e) {
        if (!this.isPreview) {
            this.selectedItemChanged.emit(e);
            setTimeout(() => {
                if (e.itemData.path && this._compactMode === false && !e.node.selected) {
                    e.component.selectItem(e.itemData);
                }
            });
        }
    }

    onFavoriteMouseEnter(e, data) {
        this.favoriteMouseEnter.emit(data);
    }

    onFavoriteMouseLeave(e, data) {
        this.favoriteMouseLeave.emit(data);
    }

    onFavoriteClick(e, path: string) {
        e.event.stopPropagation();
        this.favoriteClicked.emit(path);
    }

    onMenuInitialized(event) {
        event.component.option('deferRendering', false);
    }

    leftClickLink(event) {
        // This is in place so that the browser doesn't actually route the to the specified href path, since the selected view will
        //  be loaded as part of the side menu functionality. The only reason the link & path exists is so that when the user
        //  right clicks, they have the option to open in new tab/window
        event.preventDefault();
    }

    sortItems(items): any[] {
        return items.sort((a,b) => {
            if (a.sortOrder === b.sortOrder) {
                return a.text.localeCompare(b.text);
            } else {
                return a.sortOrder - b.sortOrder;
            }
        });
    }

    ngAfterViewInit() {
        events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
            this.openMenu.next(e);
        });
    }

    ngOnDestroy() {
        events.off(this.elementRef.nativeElement, 'dxclick');
    }
}

@NgModule({
    imports: [
        DxTreeViewModule,
        DxButtonModule,
        DxBoxModule,
        CommonModule
    ],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent]
})
export class SideNavigationMenuModule { }
