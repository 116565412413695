export class Analytic {
    id: number;
    name: string;
    layoutString: string;
    sellerIdString: string;
    attributes: string;
    isDefault: boolean;
    showPointLabels: boolean;
    showChart: boolean;
    chartTypeName: string;
    chartOrientation: ChartOrientation;
    valueFormat: string;
    valuePrecision: number;
    showColumnTotals: boolean;
    showColumnGrandTotals: boolean;
    showRowTotals: boolean;
    showRowGrandTotals: boolean;
}

export enum ChartOrientation {
    Vertical = 0,
    Horizontal = 1
}
