import { AccountFactorBatchChanges } from 'src/app/shared/models/account-factor-batch-changes';
import { HistSellerBatchChanges } from 'src/app/shared/models/hist-seller-batch-changes';
import { Seller } from 'src/app/shared/models/seller';
import { SellerImport } from 'src/app/shared/models/seller-import';

export class SellerBatchChangesContext {
    seller: Seller;
    sellerImports: SellerImport[];
    histSellerBatchChanges: HistSellerBatchChanges;
    accountFactorBatchChanges: AccountFactorBatchChanges;

    constructor(seller: Seller, sellerImports: SellerImport[], histSellerBatchChanges: HistSellerBatchChanges, accountFactorBatchChanges: AccountFactorBatchChanges) {

        this.seller = seller;
        this.sellerImports = sellerImports;
        this.histSellerBatchChanges = histSellerBatchChanges;
        this.accountFactorBatchChanges = accountFactorBatchChanges;
    }
}
