<dx-filter-builder
    #filterBuilder
    id="property-panel-filter-builder"
    class="filterBuilder"
    [allowHierarchicalFields]="true"
    [fields]="allProps.fields"
    [(value)]="filterBuilderObject"
    [groupOperations]="['and', 'or']"
    [customOperations]="customOperations"
    width="auto"
    (onValueChanged)="generateSqlString()"
    (onContentReady)="bindFilterBuilderElements($event)">
    <dx-tag-box
        *dxTemplate="let condition of 'tagBoxTemplate'"
        [value]="condition.value"
        [dataSource]="condition.field.lookup.dataSource"
        [maxDisplayedTags]="9"
        (onValueChanged)="condition.setValue($event.value)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        width="auto"
        showSelectionControls="true"
        [searchEnabled]="true"
        [grouped]="isFieldGrouped(condition.field.dataField)"
        stylingMode="underlined">
    </dx-tag-box>
    <dx-select-box
        *dxTemplate="let condition of 'columnTemplate'"
        [value]="condition.value"
        (onValueChanged)="retainCustomValue($event.value, condition)"
        [dataSource]="getDatasourceForEditorTemplate(condition.field.dataField).dataSource"
        [valueExpr]="getDatasourceForEditorTemplate(condition.field.dataField).valueExpr"
        [displayExpr]="getDatasourceForEditorTemplate(condition.field.dataField).displayExpr"
        [searchEnabled]="true"
        width="auto"
        [acceptCustomValue]="hasCustomValues(condition)"
        stylingMode="underlined"
        (onCustomItemCreating)="customColumn($event, condition)"
        (onEnterKey)="handleEnterKey($event)">
    </dx-select-box>
    <dx-text-box
        *dxTemplate="let condition of 'stringTemplate'"
        [value]="condition.value"
        stylingMode="underlined"
        (onValueChanged)="setTextColumnValue(condition, $event.value)">
    </dx-text-box>
</dx-filter-builder>
