/* eslint-disable no-bitwise */
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { addDays, addMonths } from 'date-fns';
import { format } from 'date-fns/esm';
import { DxLoadPanelComponent } from 'devextreme-angular';
import { DisplayDataArguments } from '../models/core-display-data-arguments';
import { CoreEventArguments } from '../models/core-event-arguments';
import { CoreResponse } from '../models/core-response';
import { BucketService } from './bucket.service';
import { Singleton } from '../models/singleton';
import { dateUoms } from '../constants/enums';
import { SellerName } from '../models/seller-name';
import { CoreColumn } from '../models/core-column';

@Injectable()

export class HelperService {
    constructor(private router?: Router, private bucketService?: BucketService, private injector?: Injector, private factoryResolver?: ComponentFactoryResolver) {
    }

    isNameReserved(name: string) {
        const reservedNames: string[] = ['account', 'proc value', 'notes', 'import notes', 'manager', 'result', 'id', 'role', 'datasource_id',
            'series_id', 'period_id', 'datasource_mapping_id', 'seller_id', 'xaction_id'];
        const reservedPrefixes: string[] = ['seller_', 'qty_', 'date_', 'text_', 'tag_', 'groupable1_', 'groupable2_'];
        let isReserved = false;

        isReserved = reservedNames.includes(name.toLowerCase());
        reservedPrefixes.forEach(prefix => {
            const preReg = RegExp(prefix + '\\d');
            if (preReg.test(name)) {
                isReserved = true;
            }
        });
        return isReserved;
    }

    containsLetterOrDigit(name: string) {

        if (/\d/.test(name) || /[a-zA-Z]/.test(name)) {
            return true;
        }
        return false;
    }

    isEmptyOrSpaces(str) {
        return str === null || str === '' || str.match(/^ *$/) !== null;
    }

    isNullOrUndefined(val: any) {
        return val === null || val === undefined;
    }

    isNullOrEmpty(val: any) {
        return this.isNullOrUndefined(val) || this.isEmptyOrSpaces(val);
    }

    delimitValues(values: any[], delimiter: string) {
        let delimited: string = delimiter;

        values.forEach(x => {
            delimited += x + delimiter;
        });

        return delimited;
    }

    validateAndExecutePageFunction(_this: any, eventArgs: CoreEventArguments) {
        if (_this && eventArgs && eventArgs.functionName && _this[eventArgs.functionName]) {
            _this[eventArgs.functionName](eventArgs);
        }
    }

    removePropertiesFromObject(objectProperties: any, _object: any) {
        Object.keys(objectProperties).forEach(p => {
            if (!objectProperties[p]) {
                delete _object[p];
            }
        });

        return _object;
    }

    getEmptyColumnsObject(dataSet: any[], columnsPatternsToCheck: any[] = []) {
        const objectProperties = {};
        // const columns = Object.keys(dataSet[0]);

        const distinctColumns = Object.keys(dataSet.reduce((result, obj) => Object.assign(result, obj), {}));

          distinctColumns.forEach(col => {
            if (columnsPatternsToCheck.length > 0) {
                if (columnsPatternsToCheck.some(x => col.indexOf(x.columnPattern) !== -1)) {
                    objectProperties[col] = dataSet.map(x => x[col])
                        .some(x => x !== null && x !== undefined);
                } else {
                    objectProperties[col] = true;
                }
            } else {
                objectProperties[col] = dataSet.map(x => x[col])
                .some(x => x !== null && x !== undefined);
            }
        });

        return objectProperties;
    }

    openDisplayDataPopup(dda: DisplayDataArguments, windowUrl: string) {
            const openerParams = [
                'fullscreen=yes',
                'height=' + screen.height,
                'width=' + screen.width,
                'fullscreen=yes',
                'directories=no',
                'titlebar=no',
                'toolbar=no',
                'menubar=no'
            ].join(',');

            const newWindow = window.open(windowUrl, 'recordDataViewer' + this.generateRandomGuid(), openerParams);
            newWindow['displayDataArguments'] = dda;
            newWindow.moveTo(0, 0);
    }

    openNewTab(dda: DisplayDataArguments, windowUrl: string) {
        const newWindow = window.open(windowUrl, '_blank', '');
        newWindow['displayDataArguments'] = dda;
}

    addToDayByUomId(uomId: number, startDate: Date, qty: number): Date {
        const date = dateUoms[uomId] === 'Day' ? addDays(startDate, qty - 1)
            : dateUoms[uomId] === 'Month' ? addDays(addMonths(startDate, qty), -1)
            : dateUoms[uomId] === 'AllPeriods' ? startDate
            : startDate;

        return date;
    }

    dateToMidnight(date: string | Date): Date {
        return new Date((new Date(date)).toDateString());
    }

    getUTCMidnight(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    getUTCEndOfDay(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 990));
    }

    createDateRangeString(beginDate: Date | string, endDate: Date | string, formatString: string = 'MM/dd/yyyy') {
        return format(new Date(beginDate), formatString)
            .concat(' - ', format(new Date(endDate), formatString));
    }

    getDateString(date: Date | string): string {
        const dt = new Date(date);
        // Add 1 to getMonth, since that is the only one zero-indexed.
        return (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0') + '-' + dt.getFullYear();
    }

    formatDate(date: Date | string, formatString: string = 'MM/dd/yyyy'): string {
        return format(new Date(date), formatString);
    }

    formatDateDefault(date: any): string {
        const day = String.format('{0:00}', date.getDate());
        const month = String.format('{0:00}', date.getMonth() + 1);
        const year = date.getFullYear().toString();
        return `${month}-${day}-${year}`;
    }

    currentDateTimeAsUTC(){
        return this.treatDateStringAsUTC(new Date());
    }

    dateToUTCISOString(date: Date | string): string {
        return this.formatDate(date, 'yyyy-MM-dd') + 'T' + this.formatDate(date, 'HH:mm:ss.SSS');
    }

    // Return is 'any' due to the Date vs. String mismatch for dates coming from the api.
    treatDateStringAsUTC(date: Date | string): any {
        if (date === undefined || date === null || date === '') {
            return '';
        }
        let dateString = typeof(date) === 'string' ? date : this.dateToUTCISOString(date.toISOString());
        if (!dateString.endsWith('Z')) {
            dateString += 'Z';
        }
        return dateString;
    }

    cleanDate(date: Date): any {
        if (date.getFullYear() < 100) {
            const currentYear = new Date().getFullYear();
            const currentCentury = currentYear - (currentYear % 100);
            const fullYear = currentCentury + date.getFullYear();
            date.setFullYear(fullYear);
        }
        return date;
    }

    isDateValid(e: any): boolean {
        return e?.value === undefined || e?.value === null || e.value.getFullYear() >= 1800;
    }

    parseDate(dateString: string, yearFirst: boolean = false, includeTime: boolean = false, ensureRealDate: boolean = false): Date {
        dateString = dateString.trim();
        let month = 0;
        let day = 0;
        let year = 0;
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        let timeString = '';
        if (includeTime && dateString.includes(' ')) {
            timeString = dateString.substring((dateString.indexOf(' ') + 1)).trim();
            dateString = dateString.substring(0, dateString.indexOf(' ')).trim();
        }
        let dateParts;
        if (dateString.includes('/')) {
            dateParts = dateString.split('/');
        } else if (dateString.includes('-')) {
            dateParts = dateString.split('-');
        }
        if (!dateParts || dateParts.length !== 3) {
            return ensureRealDate ? new Date(NaN) : null;
        }
        if (!yearFirst && dateParts[0].length === 4) {
            yearFirst = true;
        }
        let enteredYear = yearFirst ? parseInt(dateParts[0], 10) : parseInt(dateParts[2], 10);
        if (enteredYear.toString().length < 3) {
            // Logic to assume the 4-digit year from an entered 2-digit year
            const maxFutureYears = 50;
            const currentYear = new Date().getFullYear() % 100;
            const maxYear = (currentYear + maxFutureYears) % 100;
            const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
            let assumedCentury = currentCentury;

            // Check to see if we need to jump to previous century
            if (currentYear < maxYear && enteredYear >= maxYear) {
                assumedCentury = currentCentury - 100;
            }
            // Check to see if we need to jump ahead to next century
            if (currentYear > maxYear && enteredYear < maxYear) {
                assumedCentury = currentCentury + 100;
            }
            enteredYear += assumedCentury;
        }
        if (timeString) {
            const timeRegex = /([\d]{1,2}):([\d]{2}):?([\d]{0,2})[\s]?[\s]?([aApP]?[mM]?)/;
            if (timeString.match(timeRegex)) {
                const parsedTimeParts = this.regexParseMultiple(timeRegex, timeString);
                hours = Number(parsedTimeParts[0]);
                if (hours >= 12) {
                    hours -= 12;
                }
                minutes = Number(parsedTimeParts[1]);
                seconds = Number(parsedTimeParts[2]);
                if (parsedTimeParts[3].toUpperCase() === 'PM') {
                    hours += 12;
                }
            }
        }

        if (yearFirst) {
            year = parseInt(dateParts[0], 10).toString().length < 3 ? enteredYear : Number(dateParts[0]);
            month = Number(dateParts[1]) - 1;
            day = Number(dateParts[2]);
        } else {
            year = parseInt(dateParts[2], 10).toString().length < 3 ? enteredYear : Number(dateParts[2]);
            month = Number(dateParts[0]) - 1;
            day = Number(dateParts[1]);
        }
        const dateValue = new Date(year, month, day, hours, minutes, seconds);
        if (ensureRealDate && (month !== dateValue.getMonth() || day !== dateValue.getDate())) {
            // This handles scenarios where user enters date formatted correctly but doesn't exist, like '1/32/2000' or '2/29/2001'
            return new Date(NaN);
        }
        return dateValue;
    }

    defaultEmptyDate(): Date {
        return new Date(1000, 0, 1);
    }

    getDateDifferenceInDays(date1: Date, date2: Date): number {
        const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0, 0);
        const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0, 0);
        return Math.round(((d2 as any) - (d1 as any)) / (1000 * 60 * 60 * 24));
    }

    isPathEqual(selectedPath: string, itemPath: string) {
        if (itemPath && selectedPath && selectedPath[0] === '/' && itemPath[0] !== '/') {
            itemPath = '/' + itemPath;
        }
        return selectedPath === itemPath;
    }

    isPathMatch(selectedPath: string, itemPath: string) {
        if (itemPath && selectedPath && selectedPath[0] === '/' && itemPath[0] !== '/') {
            itemPath = '/' + itemPath;
        }
        if (!itemPath || itemPath === '' || itemPath === '/') {
            return false;
        }
        return selectedPath.startsWith(itemPath);
    }

    removeDuplicateValuesByMatchingCallBack<T, U>(arr: Array<T>, callBack: (item: T) => U): T[] {
        const seen = new Set();
        return arr.filter(item => {
            const k = callBack(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }

    removeBracketsFromString(target: string, returnArray = false): string | string[] {
        const result = target.replace(/[\[]+/g , '').replace(/[\]]+/g, ',').slice(0, -1);
        if (returnArray) {
            return result.length ? result.split(',') : [];
        }

        return result;
    }

    handleReportDashboardLoadError(hasAdminView: boolean = false) {
        if (this.router.url === '/home' || this.router.url === '/'){
            this.bucketService.deleteDefaultBucketForSeller().subscribe(res => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/']);
            });
        }
        const route = this.router.url.split('/');
        route.pop();
        const redirectPath = hasAdminView ? route.join('/') : '/';
        this.router.navigate([redirectPath]);
    }
    // TODO: Would be better if it wasn't just one level deep
    deepCopyNestedObject(source: Record<string, any>): Record<string,any> {
        const deepCopy = {};
        Object.keys(source).forEach(key => {
            if(source[key] && typeof source[key] === 'object') {
                deepCopy[key] = [...source[key]];
            } else {
                deepCopy[key] = source[key];
            }
        });
        return deepCopy;
    }

    deepCopyTwoPointO(potentialObject: any) {

        if (typeof potentialObject !== 'object' || potentialObject === null || potentialObject === undefined) {
            return potentialObject;
        } else {
            if (potentialObject instanceof Array) {
                const copy = [];
                for (const arrayItem of potentialObject){
                    copy.push(this.deepCopyTwoPointO(arrayItem));
                }
                return copy;
            } else if(potentialObject instanceof Object) {
                const copy = {};
                for (const key in potentialObject) {
                    if (potentialObject.hasOwnProperty(key)) {
                        copy[key] = this.deepCopyTwoPointO(potentialObject[key]);
                    }
                }
                return copy;
            }
        }
        throw new Error('Failed to deep copy.');
    }

    deepCompare(potentialObject: any, potentialCopy: any): boolean {
        if (typeof potentialObject !== 'object' || this.isNullOrUndefined(potentialObject) || this.isNullOrUndefined(potentialCopy)) {
            return potentialObject === potentialCopy;
        } else {
            if (potentialObject instanceof Array && potentialCopy instanceof Array) {
                potentialObject.forEach((value, index) => {
                    if(!this.deepCompare(value, potentialCopy[index])) { return false; }
                });
            } else if(potentialObject instanceof Object && potentialCopy instanceof Object) {
                for (const key in potentialObject) {
                    if (potentialObject.hasOwnProperty(key)) {
                        if(!this.deepCompare(potentialObject[key], potentialCopy[key])) { return false; };
                    }
                }
            }
        }
        return true;
    }

    isSameObject(a: any, b: any) {
        return (!a && !b) || ((a && b) && Object.keys(a).every(key => a[key] === b[key]) && Object.keys(b).every(key => b[key] === a[key]));
    }

    generateRandomGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    isGuid(value: string): boolean {
        const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        const matches = guidRegex.exec(value);
        return matches && matches.length > 0 && matches[0] === matches.input;
    }

    createComponent<T>(component: Type<T>): ComponentRef<T> {
        const factory = this.factoryResolver.resolveComponentFactory(component);
        return factory.create(this.injector);
    }

    injectComponent<T>(viewContianerRef: ViewContainerRef, component: ComponentRef<T>) {
        viewContianerRef.insert(component.hostView);
    }

    flipObject(obj: any): any {
        return Object.keys(obj).reduce((ret, key) => {
            ret[obj[key]] = key;
            return ret;
        }, {});
    }

    createLoadPanel(optionalPosition?: any, message?: string, imageUrl?: string): ComponentRef<DxLoadPanelComponent>  {
        const loadPanel = this.createComponent(DxLoadPanelComponent);
        loadPanel.instance.shadingColor = 'rgba(0,0,0,0.6)';
        loadPanel.instance.showIndicator = true;
        loadPanel.instance.showPane = true;
        loadPanel.instance.shading = true;
        loadPanel.instance.hideOnOutsideClick = false;
        loadPanel.instance.visible = true;
        loadPanel.instance.showPane = false;
        loadPanel.instance.message = message;
        if (optionalPosition) {
            loadPanel.instance.position = optionalPosition;
        }
        if (imageUrl) {
            loadPanel.instance.indicatorSrc = imageUrl;
        }
        return loadPanel;
    }

    getSessionDateRangeLabel(): string {
        const bd = new Date(localStorage['beginDate']);
        const ed = new Date(localStorage['endDate']);
        return (isNaN(bd.getTime()) || isNaN(ed.getTime())) ? 'Date Range' : `${bd.toDateString().substr(4)} to ${ed.toDateString().substr(4)}`;
    }

    displayErrorToast(toast: ToastrService, response: CoreResponse<any>, defaultMessage: string): void {
        toast.error(
            response.message ? response.message : (response.messageHeader ? response.messageHeader : defaultMessage),
            response.message && response.messageHeader ? response.messageHeader : null
        );
    }

    getDateFormatString(coreWinFormatString: string): string {
        switch (coreWinFormatString) {
            case 'MM/dd/yyyy':
            case 'MM/dd/yy':
            case 'MM-dd-yyyy':
            case 'MM-dd-yy':
            case 'd':
                return 'd';
            case 'MM/dd/yyyy hh:mm':
            case 'MM/dd/yy hh:mm':
            case 'MM-dd-yyyy hh:mm':
            case 'MM-dd-yy hh:mm':
            case 'MM/dd/yyyy HH:mm':
            case 'MM/dd/yy HH:mm':
            case 'MM-dd-yyyy HH:mm':
            case 'MM-dd-yy HH:mm':
            case 'g':
                return 'g';
            case 'MM/dd/yyyy hh:mm:ss':
            case 'MM/dd/yy hh:mm:ss':
            case 'MM-dd-yyyy hh:mm:ss':
            case 'MM-dd-yy hh:mm:ss':
            case 'MM/dd/yyyy HH:mm:ss':
            case 'MM/dd/yy HH:mm:ss':
            case 'MM-dd-yyyy HH:mm:ss':
            case 'MM-dd-yy HH:mm:ss':
            case 'G':
                return 'G';
            case 'hh:mm':
            case 'HH:mm':
            case 't':
                return 't';
            case 'HH:mm:ss':
            case 'hh:mm:ss':
            case 'T':
                return 'T';
            case 'yyyy-MM-ddThh:mm:ss':
            case 'yyyy-MM-ddTHH:mm:ss':
            case 's':
                return 's';
            case 'dddd, dd MMMM yyyy':
            case 'D':
                return 'D';
            case 'dddd, dd MMMM yyyy hh:mm':
            case 'dddd, dd MMMM yyyy HH:mm':
            case 'f':
                return 'f';
            case 'dddd, dd MMMM yyyy hh:mm:ss':
            case 'dddd, dd MMMM yyyy HH:mm:ss':
            case 'F':
                return 'F';
            case 'MMMM dd':
            case 'M':
                return 'M';
            case 'yyyy MMMM':
            case 'Y':
                return 'Y';
            default:
                return 'd';
        }
    }

    defaultToEmptyString(value) {
        if (value === null || value === undefined) {
            return '';
        }
        return value;
    }

    stringCompare(a: string, b: string, caseSensitive: boolean = false) {
        return a.localeCompare(b, 'en', { sensitivity: caseSensitive ? 'case' : 'base' });
    }

    getColorStringFromNumber(color) {
        let colorValue = parseInt(color.toString(), 10);
        if (colorValue < 0) {
            colorValue += 0xFFFFFFFF + 1;
        }
        let colorStr = colorValue.toString(16);
        if (colorStr.length < 8) {
            colorStr = Array(8-colorStr.length).fill('0').join('') + colorStr;
        }
        colorStr = colorStr.substring(2, 8) + colorStr.substring(0, 2);
        return '#' + colorStr;
    }

    createBracketedIdString(values: any[]): string {
        let returnValue = '';
        values.forEach(x => {
            returnValue += `[${x}]`;
        });
        return returnValue;
    }

    parseBracketedIdString(value: string): number[] {
        const regex = /\[([\d]+)\]/g;
        const values = this.regexParseSingle(regex, value).map(Number);
        return values;
    }

    regexParseSingle(regexPattern: any, valueToParse: string): string[] {
        const returnValues = [];
        let currentMatch;
        while ((currentMatch = regexPattern.exec(valueToParse)) !== null) {
            returnValues.push(currentMatch[1]);
        }
        return returnValues;
    }

    regexParseMultiple(regexPattern: any, valueToParse: string): string[] {
        const returnValues = [];
        const matches = regexPattern.exec(valueToParse);
        let matchIndex = 0;
        while (matches[matchIndex + 1] !== undefined) {
            returnValues[matchIndex] = matches[matchIndex + 1];
            matchIndex++;
        }
        return returnValues;
    }

    replaceFieldFriendlyNames(str: string, fields: Singleton[]) {
        fields.forEach(field => {
            str = str.split(`[${field.value}]`).join(`[${field.refName}]`);
        });
        return str;
    }

    replaceFieldSystemNames(str: string, fields: Singleton[]) {
        if (str) {
            fields.forEach(field => {
                str = str.split(`[${field.refName}]`).join(`[${field.value}]`);
            });
        }
        return str;
    }

    argbIntToRgbaStr(n: any) {
        const vals: number[] = [n >>> 16 & 0xff, n >>> 8 & 0xff, n & 0xff, Math.round(((n >>> 24)/255)*100)/100];
        return 'rgba(' + vals.join(', ') + ')';
    }

    rgbaStrToArgbInt(str: string) {
        const vals: number[] = str.slice(5, str.length-1).split(', ').map(x => +x);
        return Math.round(vals[3] * 255) << 24 | vals[0] << 16 | vals[1] << 8 | vals [2];
    }

    getAlternateImportNameColumns(accounts: SellerName[]) {
        const popuplatedColumns = [];
        for (let i = 2; ; i++) {
            if (accounts.some(sellerName => sellerName['importName'+i])) {
                popuplatedColumns.push(new CoreColumn('importName'+i, 'Import Name '+i, true));
            } else {
                break;
            }
        }
        return popuplatedColumns;
    }

    createDatasourceFromStaticArray(items: any[], idKey: string): any {
        // This method creates a datasource configuration from a static array, used for DevExpress lookup datasources when the data is already retrieved
        return {
			store: new CustomStore({
				key: idKey,
				byKey: (key) => items.find(x => x.id === key),
				load: (params: any) => {
					let data = items;
					if (!this.isNullOrUndefined(params.searchValue)) {
						if (params.searchOperation === 'contains') {
							data = data.filter(x => x[params.searchExpr]?.toUpperCase().indexOf(params.searchValue?.toUpperCase()) > -1);
						}
					}
					return of(data).toPromise().then(response => {
						const end = (params.skip + params.take) <= response.length ? (params.skip + params.take) : response.length;
						return {
							data: response.slice(params.skip, end),
							totalCount: response.length
						};
					});
				}
			}),
			paginate: true,
			staticItems: items
		};
    }
}
