<header #coreHeader (window:resize)="onResize($event)">
    <div [class]="themeClass">
        <dx-toolbar class="header-toolbar">
            <dxi-item
                *ngIf="menuToggleEnabled"
                location="before"
                widget="dxButton"
                cssClass="menu-button"
                [options]="{
                    icon: 'menu',
                    stylingMode: 'text',
                    onClick: toggleMenu
                }">
            </dxi-item>
            <dxi-item location="before">
                <div>
                    <div *ngIf="!hasAdminView">
                        <dx-button
                            *ngIf="hasDates; else noStoredDates"
                            style="text-transform: unset !important;"
                            id="date-account-selector-button"
                            icon="group"
                            class="dx-button-default"
                            hint="Open Date/Account Selection"
                            text="{{beginDate | date}}{{beginDate ? ' to ' : ''}}{{endDate | date}}"
                            [disabled]="!isReportParametersEnabled"
                            (onClick)="showPicker()">
                        </dx-button>
                        <ng-template #noStoredDates>
                            <div class="warning">No periods have been published</div>
                        </ng-template>
                    </div>
                </div>
            </dxi-item>

            <dxi-item location="center">
                <div [ngClass]="{ 'display-none': !showCompanyIcon }">
                    <img [src]="appInfo.logo" class="header-image" />
                </div>
            </dxi-item>
            <dxi-item id="company-label" location="center">
                <div [ngClass]="{ 'display-none': !showCompanyLabel }">{{companyHeader}}</div>
            </dxi-item>
            <dxi-item location="after">
                <dx-button
                    [ngClass]="{ 'icon-hidden': !isEmailIssueIndicatorVisible }"
                    icon="info"
                    type="danger"
                    stylingMode="text"
                    [hint]="emailIssueMessage"
                    (onClick)="openEmailIssuePopup()">
                </dx-button>
            </dxi-item>
            <dxi-item location="after">
                <dx-button
                    [ngClass]="{ 'warning-icon-only processing-gif': isProcessingInProgress, 'icon-hidden': !isProcessingInProgress }"
                    icon="/assets/images/spinning-gears-white.gif"
                    [hint]="isProcessingInProgress && isLogViewPopupEnabled 
                        ? 'Commissions processing, data is still being generated. Click to view active logs' 
                        : isProcessingInProgress 
                            ? 'Commissions processing, data is still being generated.'
                            : ''"
                    (onClick)="openLogViewPopup()">
                </dx-button>
            </dxi-item>
            <dxi-item location="after">
                <div class="user-name">{{username}}</div>
            </dxi-item>
            <dxi-item location="after">
                <dx-button id="trello-button" *ngIf="hasAdminView" title="trello" hint="Trello" stylingMode="text" (onClick)="linkToTrello()"></dx-button>
            </dxi-item>
            <dxi-item location="after">
                <dx-popup
                    [wrapperAttr]="{ id: 'doc-popup' }"
                    [width]="900"
                    [height]="800"
                    [showTitle]="true"
                    title="Help"
                    [dragEnabled]="true"
                    container=".dx-viewport"
                    [hideOnOutsideClick]="true"
                    [showCloseButton]="true"
                    [shading]="false"
                    [(visible)]="helpPopupVisible"
                    [resizeEnabled]="true">
                    <div *dxTemplate="let data of 'content'">
                        <iframe [src]="explainDocPath" width="100%" height="100%"></iframe>
                    </div>
                </dx-popup>
                <dx-popup
                [wrapperAttr]="{ id: 'doc-popup' }"
                [width]="900"
                [height]="800"
                [showTitle]="true"
                title="Service Agreement"
                [dragEnabled]="true"
                container=".dx-viewport"
                [hideOnOutsideClick]="true"
                [showCloseButton]="true"
                [shading]="false"
                [(visible)]="agreementPopupVisible"
                [resizeEnabled]="true">
                <div *dxTemplate="let data of 'content'">
                    <iframe [src]="agreementDocPath" width="100%" height="100%"></iframe>
                </div>
            </dx-popup>
                <dx-button *ngIf="isBotMenuItemVisible" id="corebot-button" hint="CoreBot AI Tools" (onClick)="onCoreBotClicked($event)"></dx-button>
            </dxi-item>
            <dxi-item location="after" locateInMenu="auto">
                <div *dxTemplate="let data of 'item'">
                    <dx-button
                        class="user-button"
                        stylingMode="text"
                        hint="Settings"
                        (onClick)="onSettingsButtonClick($event)">
                        <div *dxTemplate="let data of 'content'">
                            <app-user-panel menuMode="context" [menuItems]="userMenuItems"></app-user-panel>
                        </div>
                    </dx-button>
                </div>
            </dxi-item>
        </dx-toolbar>
    </div>
    <dx-popup *ngIf="isAliasGridDataLoaded"
        [width]="300"
        [height]="230"
        [showTitle]="true"
        title="Alias as User"
        [dragEnabled]="true"
        container=".dx-viewport"
        [hideOnOutsideClick]="true"
        [showCloseButton]="true"
        [(visible)]="aliasPopupVisible">
        Log out as admin and log in as the selected user (must have a login name):
        <br />
        <br />
        <core-dropdown
            #aliasUsersDropdown
            dropdownName="users"
            [selectedValue]="aliasSelectedId"
            [dataSource]="aliasUsers"
            [props]="aliasDropdownProps"
            [columns]="aliasDropdownColumns"
            (selectionChanged)="onAliasUserSelected($event)">
        </core-dropdown>

        <dxi-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            [options]="{
                text: 'Log In',
                stylingMode: 'contained',
                type: 'default',
                disabled: !aliasSelectedId,
                onClick: aliasUser
            }">
        </dxi-toolbar-item>
        <dxo-position my="right top" at="right bottom" of=".header-toolbar"></dxo-position>
    </dx-popup>
    <dx-popup
        [width]="300"
        [height]="230"
        [showTitle]="true"
        [shading]="true"
        title="Inactivity Warning"
        [dragEnabled]="true"
        container=".dx-viewport"
        [hideOnOutsideClick]="true"
        [showCloseButton]="true"
        [(visible)]="isInactivePopupVisible">
        You will be logged out in five minutes due to inactivity. If you are still working, click OK.

        <dxi-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            [options]="{
                text: 'OK',
                stylingMode: 'contained',
                type: 'default',
                onClick: refreshUserToken
            }">
        </dxi-toolbar-item>
    </dx-popup>
    <dx-popup
        [width]="300"
        [height]="150"
        [showTitle]="true"
        [shading]="true"
        title="Email Warning"
        [dragEnabled]="true"
        container=".dx-viewport"
        [hideOnOutsideClick]="true"
        [showCloseButton]="true"
        [(visible)]="isEmailIssuePopupVisible">
        {{emailIssueMessage}}

        <dxi-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            [options]="{
                text: 'go to settings',
                stylingMode: 'contained',
                type: 'default',
                onClick: goToEmail
            }">
        </dxi-toolbar-item>
        <dxi-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            [options]="{
                text: 'dismiss',
                stylingMode: 'contained',
                type: 'default',
                onClick: dismissEmailIssueIndicator
            }">
        </dxi-toolbar-item>
        <dxo-position my="right top" at="right bottom" of=".header-toolbar"></dxo-position>
    </dx-popup>
    <app-chatbot-chat #chatBot
        [showPopup]="showCoreBotPopup" 
        [showToolbarItems]="true"
        (showPopupChanged)="onPopupStateChanged($event)">
    </app-chatbot-chat>
</header>

<core-popup #headerPopup [props]="headerPopupProps" [steps]="headerPopupSteps"></core-popup>

<app-reporting-parameters 
    wrapperId="nonadmin-reporting-parameters-popup"
    [(isPopupVisible)]="pickerPopupVisible" 
    (generate)="generateClicked()">
</app-reporting-parameters>

<app-corebot-widget #botWidget [userClicked]="isInteractionClicked" (widgetClicked)="onCoreBotClicked($event)"></app-corebot-widget>
