import { SiteThemeConfig } from '../models/site-theme-config';

// There is no way to import values from the variables SCSS files into TS, so they are defined here in this file

export const SiteThemeDefault: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base',
    addlClassName: 'dx-swatch-additional',
    colorValues: {
        baseAccent: '#03a9f4',
        baseBackground: '#ffffff',
        baseTextColor: '#000000de',
        baseAccentScroll: 'rgba(3,169,244,.4)',
        baseAccentCellEdit: 'rgba(3,169,244,.32)',
        baseAccentLookupBackground: 'rgba(3,169,244,.04)',
        addlBackground: '#363640',
        addlBackgroundHover: '#3f3f4b',
        addlBorderColor: '#515159'
    }
};

export const SiteThemeBI: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base-bi',
    addlClassName: 'dx-swatch-additional-bi',
    colorValues: {
        baseAccent: '#17174b',
        baseBackground: '#ffffff',
        baseTextColor: '#000000de',
        baseAccentScroll: 'rgba(23,23,75,.4)',
        baseAccentCellEdit: 'rgba(23,23,75,.24)',
        baseAccentLookupBackground: 'rgba(23,23,75,.04)',
        addlBackground: '#3cbab2',
        addlBackgroundHover: '#47c4bc',
        addlBorderColor: '#65cdc6'
    }
};
