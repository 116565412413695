import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BlockPropertyService } from 'src/app/pages/building-blocks/block-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { Container } from '../../models/building-blocks';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-bb-xaction-mapping',
  providers: [ {provide: SaveableBbProperty, useExisting: BbXactionMappingComponent }],
  templateUrl: './bb-xaction-mapping.component.html',
  styleUrls: ['./bb-xaction-mapping.component.scss']
})
export class BbXactionMappingComponent extends SaveableBbProperty implements OnInit, OnChanges  {
  @Input() datasource: any[];
  @Input() xactionDsId: string;
  @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  internalDatasource: any[];
  isValid: boolean = true;
  scopeContainer: Container;
  blockPropertyMappings: Record<string, any>;
  xactionNameDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  headProcessDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  constructor(private blockPropertyService: BlockPropertyService,
    private bbHelper: BuildingBlockHelperService,
    private bbService: BuildingBlocksService,
    private helperService: HelperService) {
    super();
  }

  ngOnInit(): void {
    this.refreshDatasource();
    this.bbHelper.getScopeContainer().subscribe(scopeContainer => {
      this.scopeContainer = scopeContainer;
      if(this.scopeContainer){
        this.updateMappableColumns();
      }
    });
  }

  refreshDatasource(){
    if(this.helperService.isNullOrUndefined(this.datasource)){
      this.datasource = [];
    }
    this.internalDatasource = this.convertDatasource(this.datasource);
  }

  convertDatasource(ds: any[]){
    const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
    return ds.map(col => {
      const colCopy = this.helperService.deepCopyTwoPointO(col);
      if(idCols.includes(colCopy['columnName'])){
        colCopy['columnName'] = this.bbHelper.convertIdToNameColumn(colCopy['columnName']);
      }
      if(idCols.includes(colCopy['xactionName'])){
        colCopy['xactionName'] = this.bbHelper.convertIdToNameColumn(colCopy['xactionName']);
      }
      return colCopy;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['datasource']){
      this.dataGrid?.instance.cancelEditData();
      this.dataGrid?.instance.refresh();
      this.refreshDatasource();
    }
    if(changes['xactionDsId']){
      this.xactionDsId = this.xactionDsId?.slice(2);
      if(this.scopeContainer){
        this.updateMappableColumns();
      }
    }
  }

  emitValue() {
    this.datasource = this.internalDatasource.map(entry => {
      const entryCopy = this.helperService.deepCopyTwoPointO(entry);
      const xactionType: string = this.bbHelper.getDataColumnBySystemName(entryCopy['xactionName']).datatype;
      const isSourceConvertable = entryCopy['columnName'].endsWith('_name');
      const isXactionConvertable = entryCopy['xactionName'].endsWith('_name');
      if(isSourceConvertable && xactionType !== 'string'){
        entryCopy['columnName'] = this.bbHelper.convertNameToIdColumn(entryCopy['columnName']);
      }
      if(isXactionConvertable){
        entryCopy['xactionName'] = this.bbHelper.convertNameToIdColumn(entryCopy['xactionName']);
      }
      return entryCopy;
    });
    this.datasourceChange.emit(this.datasource.map(entry => ({ columnName: entry['columnName'], xactionName: entry['xactionName'] })));
  }

  saveInternalData(): Promise<void> {
    return this.dataGrid?.instance.saveEditData();
  }

  checkForChanges(){
    if(this.dataGrid.instance.hasEditData()){
      this.bbHelper.setGridPropertyAsUnsaved('columnMappings');
    } else {
      this.bbHelper.removeGridPropertyFromUnsaved('columnMappings');
    }
  }

  updateMappableColumns(){
    if(this.xactionDsId?.startsWith('Ds')){
      this.xactionDsId = this.xactionDsId.slice(2);
    }
    if(this.xactionDsId !== '-1' && !this.helperService.isNullOrUndefined(this.xactionDsId) && this.xactionDsId !== ''){
      this.bbHelper.setShowLoadPropertyPanel(true);
      forkJoin([this.bbService.getProcessDataColumnsByProcessId(this.scopeContainer?.id, this.bbHelper.getHeadObjectIdsByContainer(this.scopeContainer)[0], this.scopeContainer.periodBeginId ?? -1),
        this.bbService.getProcessDataColumnsByProcessId(null, 'Ds' + this.xactionDsId, -1)]).subscribe(res =>{

        this.headProcessDatasource = this.bbHelper.convertProcessDataColumnsToDropdownDatasource(res[0]);
        const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
        this.headProcessDatasource.dataSource = this.headProcessDatasource.dataSource.filter(col => !idCols.includes(col[this.headProcessDatasource.valueExpr]));
        this.xactionNameDatasource = this.bbHelper.convertProcessDataColumnsToDropdownDatasource(res[1]);
        this.headProcessDatasource['dataSource'] = this.headProcessDatasource['dataSource'].filter(col => !['xaction_id', 'period_id'].includes(col['refName']));
        this.xactionNameDatasource['dataSource'] = this.xactionNameDatasource['dataSource'].filter(col => !['xaction_id', 'period_id'].includes(col['refName']));
      }).add(() => {this.bbHelper.setShowLoadPropertyPanel(false);});
    }
  }

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
  filterToolbarItems(e){
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
  }
}
