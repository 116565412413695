import { containerTypeIds } from '../constants/enums';

export enum DataColumnUsageCode {
    Produced = 2,
    Consumed = 1,
    Ignored = 0
}

export enum BlockCode {
    SegmentBlockCode = '0001',
    AccountAssignBlockCode = '0002',
    PeriodFilterBlockCode = '0003',
    AccountNormalizeBlockCode = '0004',
    EarnDateBlockCode = '0005',
    CounterBlockCode = '0006',
    ContextBlockCode = '0007',
    FormulaBlockCode = '0008',
    FilterBlockCode = '0009',
    JoinBlockCode = '0010',
    ConsolidateBlockCode = '0011',
    HierarchyBlockCode = '0012',
    UnionBlockCode = '0013',
    UpdateXactionBlockCode = '0014'
}

export enum DiagramBlockMappings {
    'Core.PeriodBlock.Block' = BlockCode.PeriodFilterBlockCode,
    'Core.AccountAssign.Block' = BlockCode.AccountAssignBlockCode,
    'Core.AccountNormalize.Block' = BlockCode.AccountNormalizeBlockCode,
    'Core.EarnDate.Block' = BlockCode.EarnDateBlockCode,
    'Core.Counter.Block' = BlockCode.CounterBlockCode,
    'Core.Formula.Block' = BlockCode.FormulaBlockCode,
    'Core.Filter.Block' = BlockCode.FilterBlockCode,
    'Core.Join.Block' = BlockCode.JoinBlockCode,
    'Core.Consolidate.Block' = BlockCode.ConsolidateBlockCode,
    'Core.Datasource.Block' = BlockCode.ContextBlockCode,
    'Core.Hierarchy.Block' = BlockCode.HierarchyBlockCode,
    'Core.Union.Block' = BlockCode.UnionBlockCode,
    'Core.Update.Block' = BlockCode.UpdateXactionBlockCode
}

export enum BlockMiniLabel {
    'P' = BlockCode.PeriodFilterBlockCode,
    'A' = BlockCode.AccountAssignBlockCode,
    'AN' = BlockCode.AccountNormalizeBlockCode,
    'ED' = BlockCode.EarnDateBlockCode,
    'G' = BlockCode.CounterBlockCode,
    'D' = BlockCode.ContextBlockCode,
    'F' = BlockCode.FormulaBlockCode,
    'Fi' = BlockCode.FilterBlockCode,
    'J' = BlockCode.JoinBlockCode,
    'Gr' = BlockCode.ConsolidateBlockCode,
    'H' = BlockCode.HierarchyBlockCode,
    'U' = BlockCode.UnionBlockCode,
    'Up' = BlockCode.UpdateXactionBlockCode,
}

export enum DataColumnType {
    SellerField,
    AccountFactors,
    AccountAttribute,
    BlockIntroduced,
    QuantityField,
    TextField,
    DateField,
    TagField,
    TagGroup,
    CalculatedField,
    CoreInternalField,
    RecordMetaField,
    RuleIntroduced,
}

export class DataColumn {
    systemName: string;
    friendlyName: string;
    datatype: string;
    formatString: string;
    type: DataColumnType;
}

export class ProcessDataColumn {
    private static readonly OverwrittenColumnSystemNamePrefix: string = 'overwritten_';

    systemName: string;
    isPrimaryKeyPart: boolean;
    expression: string;
    usageCode: DataColumnUsageCode;

    get isPhysical(): boolean {
        return this.isPrimaryKeyPart || this.usageCode === DataColumnUsageCode.Produced;
    }

    get isOverwritten(): boolean {
        return this.systemName.startsWith(ProcessDataColumn.OverwrittenColumnSystemNamePrefix);
    }
}

// NOTE: Prefixed with 'Bb' to avoid name collision with built-in 'Object'. -DH
export abstract class BbObject {
    constructor(public id: string, public name: string, public parentId: string, public description: string) {
    }

    abstract get objectTypeCode(): string;
    abstract applyChanges(changes: any): void;
}

// TODO: Get rid of this class. -DH // Actually get rid of Group class below and remove abstract here -JH
export abstract class Container extends BbObject {
    // HACK: Without a class member here, any BbObject would pass for a Container. -DH
    typeId: number;
    recurrenceId: number;
    lastModified: string;
    objectJson: string;
    locked: boolean;
    isActive: boolean;
    isSegment?: boolean;
    isPayment?: boolean;
    periodBeginId: number | null;
    periodEndId: number | null;
}

export class Group extends Container {
    constructor(id: string, name: string, parentId: string, description: string, typeId: number = 1, objectJson: string = '', recurrenceId: number = null,
        lastModified: string = '', locked: boolean = false, public headProcessId: string = null, isActive: boolean = true, periodBeginId: number | null = null, periodEndId: number | null = null) {

        super(id, name, parentId, description);
        this.typeId = typeId;
        this.recurrenceId = recurrenceId;
        this.lastModified = lastModified;
        this.objectJson = objectJson;
        this.locked = locked;
        this.headProcessId = headProcessId;
        this.isActive = isActive;
        this.periodBeginId = periodBeginId;
        this.periodEndId = periodEndId;
    }

    static getObjectTypeCode(): string {
        return 'Co';
    }

    get objectTypeCode(): string {
        return Group.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class DatasourceProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceType: string,
        public columnString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ds';
    }

    get objectTypeCode(): string {
        return DatasourceProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class TransformProcessAuxSourceProcess {
    ordinal: number;
    processId: string;
    joinCondition: string;
}

export class TransformProcessWrittenColumn {
    systemName: string;
    expression: string;
}

export class TransformProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public mainSourceProcessId: string,
        public auxSourceProcesses: TransformProcessAuxSourceProcess[],
        public writtenColumns: TransformProcessWrittenColumn[]) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Tr';
    }

    get objectTypeCode(): string {
        return TransformProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class FilterProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public filterString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Fi';
    }

    get objectTypeCode(): string {
        return FilterProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class CalculateProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public displayFormulaString: string,
        public sqlFormulaString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ca';
    }

    get objectTypeCode(): string {
        return CalculateProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
        this.displayFormulaString = changes.displayFormulaString;
    }
}

export class AggregateProcessKeyColumn {
    systemName: string;
}

export class AggregateProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public keyColumns: AggregateProcessKeyColumn[],
        public inputValueColumnSystemName: string,
        public functionCode: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ag';
    }

    get objectTypeCode(): string {
        return AggregateProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }

    get outputValueColumnSystemName(): string {
        return `${this.id}_value`;
    }
}

export class UnionProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public auxProcessId: string,
        public unionAll: boolean
        ) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Un';
    }

    get objectTypeCode(): string {
        return UnionProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export enum BlockPropertyDatatype {
    // eslint-disable-next-line id-blacklist
    String,
    MultiLineString,
    NullableInt,
    SegmentFilterBuilder,
    FormulaBuilder,
    DropDown,
    PeriodSelector,
    ApplyTo,
    CheckBox,
    AccountAssignFields,
    PositiveWholeNumber,
    DayOfMonthWholeNumber,
    MonthWholeNumber,
    PeriodDropdown,
    FieldGrid,
    ReadOnlyString,
    GroupedDropDown,
    FilterBuilder,
    SegmentFormulaBuilder,
    UpdateXactionFields,
    ReadOnlyInt,
    UnionFields,
}

export class BlockProperty {
    constructor(
        readonly datatype: BlockPropertyDatatype,
        readonly systemName: string,
        readonly friendlyName: string,
        readonly groupFriendlyName: string,
        readonly conditionalDisplayArg?: string
    ) {
    }
}

export class BlockPropertyValue {
    constructor(
        readonly property: BlockProperty,
        public value: any
    ) {
    }
}

export abstract class BlockType {

    abstract getBlockTypeCode(): string;
    abstract getProperties(): readonly BlockProperty[];
    abstract applyChanges(changes: any): void;
    abstract createSameBlockType(): BlockType;

}

export class Block extends Container {
    constructor(id: string, name: string, parentId: string, description: string,
        private readonly blockType: BlockType,
        public headProcessId: string,
        readonly propertyValues: BlockPropertyValue[],
        isPayment: boolean,
        isActive: boolean
        ) {
        super(id, name, parentId, description);
        this.isPayment = isPayment;
        this.isActive = isActive;
    }

    static getObjectTypeCode(): string {
        return 'Bb';
    }

    applyChanges(changes: any): void {
        this.blockType.applyChanges(changes);

        this.name = changes.name;
        this.parentId = changes.parentId;
        this.description = changes.description;

        for (const property of this.blockType.getProperties()) {
            const index: number = this.propertyValues.findIndex(originalProperty => originalProperty.property.systemName === property.systemName);
            this.propertyValues[index] = changes.propertyValues[index];
        }
    }

    get objectTypeCode(): string {
        return Block.getObjectTypeCode();
    }

    get blockTypeCode(): string {
        return this.blockType.getBlockTypeCode();
    }
}

export class SegmentBlockType extends BlockType {

    static getObjectTypeCode(): string {
        return 'Sg';
    }

    getBlockTypeCode(): string {
        return BlockCode.SegmentBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Datasource', 'Source'),
            new BlockProperty(BlockPropertyDatatype.PeriodSelector, 'periodFilterString', 'Period Filter', 'Source'),
            new BlockProperty(BlockPropertyDatatype.SegmentFilterBuilder, 'criteriaFilterString', 'Criteria Filter', 'Data Filter'),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
            new BlockProperty(BlockPropertyDatatype.SegmentFilterBuilder, 'criteriaFilterString', 'Account Filter', 'Eligibility'),
            new BlockProperty(BlockPropertyDatatype.SegmentFormulaBuilder, 'displayFormulaString', 'Formula', 'Calculate'),
            new BlockProperty(BlockPropertyDatatype.NullableInt, 'precision', 'Precision', 'Calculate'),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'runningSumDateFieldSystemName', 'Running Sum', 'Calculate'),
            new BlockProperty(BlockPropertyDatatype.ReadOnlyInt, 'isPayout', 'Payment', 'Calculate', 'false'),
            new BlockProperty(BlockPropertyDatatype.ReadOnlyInt, 'orderIndex', 'Level', 'Calculate'),
            new BlockProperty(BlockPropertyDatatype.ApplyTo, 'redir', 'Apply to', 'Output'),
        ];
    }

    createSameBlockType(): BlockType {
        return new SegmentBlockType();
    }

    applyChanges(changes: any): void {
    }
}

export class CounterBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.CounterBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new BlockProperty(BlockPropertyDatatype.FieldGrid, 'aggregateFields', 'Group By Me', null),
        ];
    }

    createSameBlockType(): BlockType {
        return new CounterBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class ConsolidateBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.ConsolidateBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
        ];
    }

    createSameBlockType(): BlockType {
        return new ConsolidateBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class HierarchyBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.HierarchyBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null)
        ];
    }

    createSameBlockType(): BlockType {
        return new HierarchyBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class EarnDateBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.EarnDateBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
        ];
    }

    createSameBlockType(): BlockType {
        return new EarnDateBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class AccountNormalizeBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.AccountNormalizeBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
        ];
    }

    createSameBlockType(): BlockType {
        return new AccountNormalizeBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class AccountAssignBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.AccountAssignBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.DropDown, 'beginDateField', 'Begin Date Field', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'endDateField', 'End Date Field', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'activityDateField', 'Activity Date Field', null),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Copy To', null),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Copy From', null),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'includeNonMatchingRows', 'Include Non-Matching Rows', null),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'dataRowsArePersisted', 'Update Records', null),
            new BlockProperty(BlockPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Join Condition', null),
            new BlockProperty(BlockPropertyDatatype.AccountAssignFields, 'sourceAndAuxFields', 'Account Assign Fields', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'joinHint', 'Join Hint', null, `isShowAdvancedView`),
        ];
    }

    createSameBlockType(): BlockType {
        return new AccountAssignBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class FormulaBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.FormulaBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new BlockProperty(BlockPropertyDatatype.FormulaBuilder, 'sqlFormulaString', 'Formula', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'datatype', 'Data Type', null),
            new BlockProperty(BlockPropertyDatatype.String, 'format', 'Format String', null, `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'datatype').value !== 'string'`),
            new BlockProperty(BlockPropertyDatatype.ReadOnlyString, 'dependentIds', 'Dependent Ids', null, `isShowAdvancedView`)
        ];
    }

    createSameBlockType(): BlockType {
        return new FormulaBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class FilterBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.FilterBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new BlockProperty(BlockPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Filter', null),
            new BlockProperty(BlockPropertyDatatype.ReadOnlyString, 'filterString', 'Filter String', null, `isShowAdvancedView`),
        ];
    }

    createSameBlockType(): BlockType {
        return new FilterBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class PeriodFilterBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.PeriodFilterBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.DropDown, 'dateRangeTypeCode', 'Filter Type', null),
            new BlockProperty(BlockPropertyDatatype.PositiveWholeNumber, 'previousPeriods', 'Previous Periods', null,
                `['S','R'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'excludeCurrentPeriod', 'Exclude Current Period', null,
                `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'R'`),
            new BlockProperty(BlockPropertyDatatype.MonthWholeNumber, 'dateRangeMonthNo', 'Date Range Month Number', null,
                `['Q','Y','L','P','X','N','W'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.DayOfMonthWholeNumber, 'dateRangeDayNo', 'Date Range Date Number', null,
                `['M','Q','Y','L','P','X','N','W'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.PeriodDropdown, 'fromPeriodId', 'From period id', null,
                `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'S'`),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null)
        ];
    }

    createSameBlockType(): BlockType {
        return new PeriodFilterBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class ContextBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.ContextBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.DropDown, 'dateRangeTypeCode', 'Filter Type', null),
            new BlockProperty(BlockPropertyDatatype.PositiveWholeNumber, 'previousPeriods', 'Previous Periods', null,
                `['S','R'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'excludeCurrentPeriod', 'Exclude Current Period', null,
                `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'R'`),
            new BlockProperty(BlockPropertyDatatype.MonthWholeNumber, 'dateRangeMonthNo', 'Date Range Month Number', null,
                `['Q','Y','L','P','X','N','W','Sem', 'PSem', 'Tri', 'PTri'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.DayOfMonthWholeNumber, 'dateRangeDayNo', 'Date Range Date Number', null,
                `['M','Q','Y','L','P','X','N','W', 'Sem', 'PSem', 'Tri', 'PTri'].includes(focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new BlockProperty(BlockPropertyDatatype.PeriodDropdown, 'fromPeriodId', 'From period id', null,
                `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'S'`),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'useAccountNormalize', 'Use Account Normalize', 'Advanced'),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'useEarnDate', 'Use Earn Date', 'Advanced'),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'simpleFilter', 'Use Simple Filter', 'Advanced', `isShowAdvancedView`),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
        ];
    }

    createSameBlockType(): BlockType {
        return new ContextBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class JoinBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.JoinBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Copy To', null),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Copy From', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'joinType', 'Join Type', null),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'dataRowsArePersisted', 'Update Records', null),
            new BlockProperty(BlockPropertyDatatype.CheckBox, 'copySystemColumns', 'Copy System Columns', null, 'isShowAdvancedView'),
            new BlockProperty(BlockPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Join Condition', null,
                `focusedBlock.propertyValues.find(prop => prop.property.systemName === 'joinType').value != 5`),
            new BlockProperty(BlockPropertyDatatype.AccountAssignFields, 'sourceAndAuxFields', 'Joined/Aliased Fields', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'joinHint', 'Join Hint', null, 'isShowAdvancedView'),
        ];
    }

    createSameBlockType(): BlockType {
        return new JoinBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class UnionBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.UnionBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Primary Source', null),
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Aux Source', null),
            new BlockProperty(BlockPropertyDatatype.UnionFields, 'fieldMaps', 'Field Maps', null),
        ];
    }

    createSameBlockType(): BlockType {
        return new UnionBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class UpdateXactionBlock extends BlockType {
    getBlockTypeCode(): string {
        return BlockCode.UpdateXactionBlockCode;
    }

    getProperties(): readonly BlockProperty[] {
        return [
            new BlockProperty(BlockPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Primary Source', null),
            new BlockProperty(BlockPropertyDatatype.DropDown, 'datasourceId', 'Datasource', null),
            new BlockProperty(BlockPropertyDatatype.UpdateXactionFields, 'updateFields', 'Fields', null),
        ];
    }

    createSameBlockType(): BlockType {
        return new UpdateXactionBlock();
    }

    applyChanges(changes: any): void {
    }
}

export class ContainerPlan {
    id: string;
    name: string;
    description: string;
    eligible: string;
    recurrence: string;
    lastModified: string;
    activeRules: ContainerRule[];
    allRules: ContainerRule[];
    showInactiveRules: boolean;
    locked: boolean;
    isActive: boolean;

    constructor(id: string, name: string, description: string, objectJson: string,
        recurrence: string, lastModified: string, locked: boolean, rules: ContainerRule[], isActive: boolean) {
        if (objectJson === undefined || objectJson === null || objectJson === '') {
            objectJson = '{}';
        }
        const displayProperties = JSON.parse(objectJson);

        this.id = id;
        this.name = name;
        this.description = description;
        this.eligible = displayProperties.eligible;
        this.recurrence = recurrence;
        this.lastModified = lastModified;
        this.allRules = rules;
        this.activeRules = rules.filter(x => x.isActive);
        this.showInactiveRules = false;
        this.locked = locked;
        this.isActive = isActive;
    }
}

export class ContainerRule {
    id: string;
    name: string;
    description: string;
    lastModified: string;
    type: string;
    status: string;
    dataSource: string;
    isPayment: boolean;
    isActive: boolean;
    locked: boolean;
    level: number;
    isSegment: boolean;
    periodBeginId: number;
    periodEndId: number | null;

    constructor(id: string, name: string, description: string, lastModified: string, typeId: number,
        locked: boolean, objectJson: string, level: number, isActive: boolean, periodBeginId: number, periodEndId: number, isSegment?: boolean, isPayment?: boolean) {
        if (objectJson === undefined || objectJson === null || objectJson === '') {
            objectJson = '{}';
        }
        const displayProperties = JSON.parse(objectJson);

        this.id = id;
        this.name = name;
        this.description = description;
        this.lastModified = lastModified;
        this.type = typeId === containerTypeIds.SetupRule ? 'Setup' : 'Production';
        this.status = displayProperties.status;
        this.dataSource = displayProperties.dataSource;
        this.isPayment = (isPayment !== undefined) ? isPayment : (displayProperties.isPayment !== undefined ? displayProperties.isPayment : false);
        this.isActive = isActive;
        this.locked = locked;
        this.level = level ?? displayProperties.level;
        this.isSegment = isSegment;
        this.periodBeginId = periodBeginId;
        this.periodEndId = periodEndId;
    }
}
