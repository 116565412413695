import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Xaction } from '../models/xaction';
import { CoreRequest } from '../models/core-request';

@Injectable()
export class ABCService {
    private abcURL = environment.apiEndpoint + '/abc/';

    constructor(private http: HttpClient) { }

    getPolicies(): Observable<Xaction[]> {
        return this.http.get<Xaction[]>(this.abcURL + 'Policies');
    }

    updatePolicy(updatedPolicy: Xaction): Observable<number> {
        return this.http.put<number>(this.abcURL + 'UpdatePolicy', updatedPolicy);
    }

    insertPolicy(newPolicy: CoreRequest<Xaction>): Observable<Xaction> {
        return this.http.post<Xaction>(this.abcURL + 'InsertPolicy', newPolicy);
    }
}
