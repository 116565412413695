import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreIds } from '../models/core-ids';
import { CoreResponse } from '../models/core-response';
import { Period, PublishPeriod } from '../models/period';
import { tap, map } from 'rxjs/operators';
import { HelperService } from './helper.service';

@Injectable()
export class PeriodService {
    TIMEZONE_OFFSET = 8;
    private periodURL = environment.apiEndpoint + '/Period';
    private periodEvent = new BehaviorSubject<boolean>(false);
    private $periodLockedEvent = new Subject();

    constructor(private http: HttpClient, private helperService: HelperService) {

    }

    getPeriodLockedEvent(): Observable<any> {
        return this.$periodLockedEvent.asObservable();
    }

    setPeriodLockedEvent() {
        this.$periodLockedEvent.next();
    }

    getPeriodEvents(): Observable<boolean> {
        return this.periodEvent.asObservable();
    }

    getPeriods(): Observable<Period[]> {
        return this.http.get<Period[]>(this.periodURL);
    }

    getBoundPeriods(): Observable<Period[]> {
        const allPeriodsStartDateStr = '1900-01-01T00:00:00';
        return this.getPeriods().pipe(map(periods => periods.filter(p => p.beginDate.toString() !== allPeriodsStartDateStr)));
    }

    getPublished(): Observable<Period[]> {
        return this.http.get<Period[]>(this.periodURL + '/getpublished');
    }

    getLastUnlockedPeriod(recurrenceId: number) {
        return this.http.get<Period>(this.periodURL + '/lastUnlocked/' + recurrenceId);
    }

    getWorkflow(): Observable<Period> {
        return this.http.get<Period>(this.periodURL + '/getworkflow');
    }

    getAllPeriodId(): Observable<number> {
        return this.http.get<number>(this.periodURL + '/allperiod');
    }

    updatePeriod(period: Period): void {
        this.http.post<number>(this.periodURL, period)
            .pipe(tap(() => this.periodEvent.next(true)));
    }

    publishPeriod(publishPeriod: PublishPeriod): Observable<CoreResponse<Period>> {
        const period = new Period().createFromPublishPeriod(publishPeriod);
        return this.http.post<CoreResponse<Period>>(this.periodURL + '/publishPeriod', period)
            .pipe(tap(() => this.periodEvent.next(true)));
    }

    deletePeriod(periodId: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.periodURL + '/' + periodId)
            .pipe(tap(response => response.result > 0 ? this.periodEvent.next(true) : null));
    }

    deletePeriodAndReassignVersions(periodId: number, newPeriodId: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.periodURL + '/' + periodId + '/' + newPeriodId)
            .pipe(tap(response => response.result > 0 ? this.periodEvent.next(true) : null));
    }

    insertPeriodFromProcessing(period: Period): Observable<Period> {
        return this.http.put<Period>(this.periodURL + '/insertFromProcessing', period)
            .pipe(tap(() => this.periodEvent.next(true)));
    }

    /*
     * Returns the period begin date closest but not beyond the start of the quarter containing endDate.
     * sortedPeriods must be sorted by the beginDate of the period.
    */
    getStartOfQuarter(endDate: Date, sortedPeriods: Period[]): Date {
        const month = endDate.getMonth() + 1;
        const quarterBeginDate = new Date(endDate.getFullYear(), (month - 1) - ((month - 1) % 3), 1);
        let earliestQuarterDate = sortedPeriods[0].beginDate;
        for (let i = 1; i <= sortedPeriods.length; i++) {
            if (new Date(sortedPeriods[sortedPeriods.length - i].beginDate) >= quarterBeginDate) {
                earliestQuarterDate = sortedPeriods[sortedPeriods.length - i].beginDate;
            }
        }

        return earliestQuarterDate;
    }

    /*
     * Returns the period begin date closest but not beyond the start of the year containing endDate.
     * sortedPeriods must be sorted by the beginDate of the period.
    */
    getStartOfYear(endDate: Date, sortedPeriods: Period[]): Date {
        const yearBeginDate = new Date(endDate.getFullYear(), 0, 1);
        let earliestYearDate = sortedPeriods[0].beginDate;
        for (let i = 1; i <= sortedPeriods.length; i++) {
            if (new Date(sortedPeriods[sortedPeriods.length - (i)].beginDate) >= yearBeginDate) {
                earliestYearDate = sortedPeriods[sortedPeriods.length - (i)].beginDate;
              }
          }

        return earliestYearDate;
    }

    getCurrent(sortedPeriods: Period[]): Period {
        const latestPeriod = sortedPeriods[sortedPeriods.length - 1];
        const now = Date.now();
        return sortedPeriods.find(x => Date.parse(x.beginDate.toString()) < now && now < Date.parse(x.endDate.toString())) ?? latestPeriod;
    }

    sortPeriods(periods: Period[]) {
        return periods.sort((a, b) => {
            if (a.beginDate.valueOf() < b.beginDate.valueOf()) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    async setStorageDates(sellerId: number, subordinateSellerIds: number[], username: string, publishOverride = false) {
        return this.getPublished().toPromise().then((periods: Period[]) => {
            if (!publishOverride) {
                periods = periods.filter(x => x.isPublished);
            }

            const selectedRecurrenceId = +localStorage.getItem('recurrenceId');
            const filteredPeriods = selectedRecurrenceId > 0
                ? periods.filter(x => x.recurrenceId === selectedRecurrenceId)
                : periods;

            const sortedPeriodsDesc = this.sortPeriods(filteredPeriods);
            const getCurrentPeriod = this.getCurrent(sortedPeriodsDesc);

            if (!sortedPeriodsDesc.some(p => p.beginDate.toString() === localStorage.getItem('beginDate')) ||
                !sortedPeriodsDesc.some(p => p.endDate.toString() === localStorage.getItem('endDate'))) {
                localStorage.removeItem('beginDate');
                localStorage.removeItem('endDate');
            }

            if (getCurrentPeriod && (!(localStorage.getItem('beginDate') && localStorage.getItem('endDate')))) {
                localStorage.setItem('beginDate', getCurrentPeriod?.beginDate.toString());
                localStorage.setItem('endDate', getCurrentPeriod?.endDate.toString());

                const filteredSubordinates = subordinateSellerIds.filter(x => x !== sellerId);
                const ids = [sellerId, ...filteredSubordinates];
                const selectedSellers = this.helperService.createBracketedIdString(ids);

                localStorage.setItem(`${username}.selectedSellers`, selectedSellers);
            }
        });
    }

    getLatestedPublishedPeriod(recurrenceId: number) {
        return this.http.get<Period>(this.periodURL + '/latestPublishedPeriod/' + recurrenceId);
    }

    lockPeriod(periodId: number, seriesId: number): Observable<CoreResponse<string>> {
        const body = new CoreIds();
        body.periodId = periodId;
        body.seriesId = seriesId;

        return this.http.post<CoreResponse<string>>(this.periodURL + '/lockPeriod', body)
            .pipe(tap(() => this.periodEvent.next(true)));
    }

    isPeriodLocked(periodId: number): Observable<boolean> {
        return this.http.get<boolean>(this.periodURL + '/lockedPeriod/' + periodId);
    }

    getPeriodsInRange(beginPeriodId: number, endPeriodId: number, validPeriods: Period[]): Period[] {
        const beginPeriodDate: Date = new Date(validPeriods.find(p => p.id === beginPeriodId).beginDate);
        const endPeriodDate: Date = endPeriodId ? new Date(validPeriods.find(p => p.id === endPeriodId).endDate) : new Date('2099/12/31');
        return validPeriods.filter(p => new Date(p.beginDate) >= beginPeriodDate && new Date(p.endDate) <= endPeriodDate);
    }
}
