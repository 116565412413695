<div class="core-report-designer" (window:resize)="onWindowResize()">
    <div class="edit-report-settings">
        <dx-button class="report-setting" text="Preview" icon="video" (onClick)="onPreviewClick()"></dx-button>
        <dx-button class="report-setting" text="Rules" icon="datafield" (onClick)="onFunctionsClick()"> </dx-button>
        <dx-check-box [(value)]="isPaymentReport" text="Payment Report" class="report-setting" (onValueChanged)="isPaymentReportChange()"></dx-check-box>
        <dx-check-box [(value)]="includeZeroes" text="Include Zeroes" class="report-setting" (onValueChanged)="reportParameterChange()"></dx-check-box>
        <dx-check-box [(value)]="isPublic" text="Public" class="report-setting" (onValueChanged)="reportParameterChange()"></dx-check-box>
        <div class="report-setting">
            <span class="report-setting-label">Recurrence:</span>
            <dx-select-box
                [(value)]="recurrenceId"
                [dataSource]="recurrences"
                valueExpr="id"
                displayExpr="name"
                class="report-setting-selectbox"
                stylingMode="underlined"
                (onValueChanged)="reportParameterChange()">
            </dx-select-box>
        </div>
        <dx-check-box
            [(value)]="showInactiveSegments"
            text="Show Inactive Segments"
            class="report-setting"
            (onValueChanged)="showInactiveSegmentsChange($event)">
        </dx-check-box>
    </div>
</div>

<dx-report-designer #reportDesigner [reportUrl]="reportUrl" [height]="designerHeight">
    <dxrd-request-options [host]="hostUrl" [getDesignerModelAction]="getDesignerModelAction"></dxrd-request-options>
    <dxrd-callbacks
        (CustomizeMenuActions)="customizeMenuActions($event)"
        (CustomizeElements)="customizeElements($event)"
        (ExitDesigner)="exitDesigner($event)">
    </dxrd-callbacks>
</dx-report-designer>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#reportDesigner' }"
    [(visible)]="loadingVisible && showLoadingPanel"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>

<dx-popup
    #previewPopup
    [showTitle]="true"
    title="Preview Report"
    [dragEnabled]="false"
    [hideOnOutsideClick]="false"
    [showCloseButton]="true"
    [(visible)]="previewPopupVisible"
    [position]="{
        my: 'top',
        at: 'top',
        of: '.main-scroll-view .dx-scrollview-content',
        offset: { y: 30 }
    }"
    class="preview-report-popup">
    <dx-report-viewer id="reportPreviewer" [reportUrl]="reportPreviewUrl" height="calc(100vh-50px)">
        <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
        <dxrv-callbacks (CustomizeMenuActions)="customizePreviewMenuActions($event)" (CustomizeExportOptions)="customizePreviewExportOptions($event)"></dxrv-callbacks>
    </dx-report-viewer>
</dx-popup>

<dx-popup
    #segmentsPopup
    [showTitle]="true"
    [width]="700"
    [(height)]="segmentsPopupHeight"
    [minWidth]="450"
    [minHeight]="segmentsPopupMinHeight"
    title="Report Segments/Rules"
    [dragEnabled]="true"
    [resizeEnabled]="true"
    container=".dx-viewport"
    [showCloseButton]="false"
    [hideOnOutsideClick]="false"
    [(visible)]="segmentsPopupVisible"
    [position]="{
        my: 'top',
        at: 'top',
        of: '.main-scroll-view .dx-scrollview-content',
        offset: { y: 30 }
    }"
    (onResize)="onResizeSegmentsPopup($event)"
    class="segments-popup">
    <core-data-grid #segmentsGrid [props]="segmentsGridProps" [columns]="segmentsGridColumns" [dataSource]="reportSegments"></core-data-grid>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="segmentsPopupOkButtonOptions"></dxi-toolbar-item>
</dx-popup>

<dx-popup
    #deletePopup
    height="200"
    width="400"
    [showTitle]="true"
    title="Delete Report"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="deletePopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="delete-report-popup">
    <dx-responsive-box>
        <dxi-row [ratio]="3"></dxi-row>
        <dxi-row [ratio]="2"></dxi-row>

        <dxi-col [ratio]="2"></dxi-col>
        <dxi-col [ratio]="4"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>

        <dxi-item class="icon">
            <dxi-location [row]="0" [col]="0"></dxi-location>
            <i class="dx-icon dx-icon-help"></i>
        </dxi-item>

        <dxi-item class="message">
            <dxi-location [row]="0" [col]="1" [colspan]="3"></dxi-location>
            <span>Delete report '{{ reportLayout.name }}'?</span>
        </dxi-item>

        <dxi-item class="yes-button">
            <dxi-location [row]="1" [col]="0" [colspan]="2"></dxi-location>
            <dx-button #deleteYesButton (click)="deleteReport()" class="dx-button-default">Yes</dx-button>
        </dxi-item>

        <dxi-item class="cancel-button">
            <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>
            <dx-button (click)="deletePopupVisible = false">No</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>

<dx-popup
    #renamePopup
    height="200"
    width="400"
    [showTitle]="true"
    title="Rename Report"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="renamePopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="rename-report-popup">
    <dx-responsive-box>
        <dxi-row [ratio]="3"></dxi-row>
        <dxi-row [ratio]="2"></dxi-row>

        <dxi-col [ratio]="2"></dxi-col>
        <dxi-col [ratio]="4"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>

        <dxi-item class="message">
            <dxi-location [row]="0" [col]="0" [colspan]="4"></dxi-location>
            <dx-text-box class="report-designer-rename-text" [(value)]="newReportName" stylingMode="underlined"></dx-text-box>
        </dxi-item>

        <dxi-item class="yes-button">
            <dxi-location [row]="1" [col]="0" [colspan]="2"></dxi-location>
            <dx-button #deleteYesButton (click)="renameReport()" class="dx-button-default">OK</dx-button>
        </dxi-item>

        <dxi-item class="cancel-button">
            <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>
            <dx-button (click)="cancelRename()">Cancel</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>
