<div id="filter-section">
    <div class="filter-subsection working-in-content" *ngIf="!auditPageMode">
        <app-period-dropdown
            #periodDropdown
            [dropdownProps]="periodDropdownProps"
            [(selectedPeriodId)]="periodId"
            [(selectedRecurrenceId)]="recurrenceId"
            (selectedPeriodIdChange)="onPeriodChanged($event)"
            [(selectedSeriesId)]="seriesId">
        </app-period-dropdown>
        <div style="font-weight: 500; margin-left: 0px;">Row Count</div>
        <dx-select-box
            id="filter-select-box"
            [(value)]="this.rowCount"
            [showClearButton]="false"
            [acceptCustomValue]="true"
            width="100px"
            displayExpr="name"
            valueExpr="id"
            [dataSource]="rowCountOptions"
            (onCustomItemCreating)="customRowCount($event)"
            stylingMode="underlined">
        </dx-select-box>
        <div *ngIf="!auditPageMode">
            <dx-check-box text="Advanced view" class="bb-header-item" (onValueChanged)="toggleAdvancedView($event)" [(value)]="showAdvancedView"></dx-check-box>
        </div>
        <div *ngIf="!auditPageMode">
            <dx-check-box text="View end dated rules" class="bb-header-item" (onValueChanged)="toggleEndDatedRules($event)"></dx-check-box>
        </div>
    </div>
    <div class="filter-subsection">
        <ng-container *ngIf="this.filterDataColumns">
            <div class="filter-builder-property">
                <app-bb-filter-builder
                    [(filterBuilderObject)]="filterBuilderObject"
                    [validDataColumns]="this.filterDataColumns"
                    [auxDataColumns]="null"
                    [isJoin]="false">
                </app-bb-filter-builder>
            </div>
            <dx-button text="Clear Selection" hint="Clear Selection" (onClick)="this.clearSelectedRow($event)"></dx-button>
            <ng-container *ngIf="this.selectedRow != null">
                <dx-button text="Diagram Audit" hint="Diagram Audit" (onClick)="diagramAudit()" [disabled]="liveReload"></dx-button>
            </ng-container>
            <dx-button text="Audit" hint="Audit" (onClick)="refreshGrid()" [disabled]="liveReload"></dx-button>
            <dx-button text="Cancel Audit" (onClick)="cancelAudit()"></dx-button>
        </ng-container>
        <dx-button text="Refresh Cache" hint="Refresh Cache" (onClick)="refreshCache()" class="refresh-cache"></dx-button>
        <dx-button text="Process Log" hint="Process Log" [visible]="this.showAdvancedView" (onClick)="showPLV()"></dx-button>
        <dx-button text="{{SQLTypeName}} SQL" hint="{{SQLTypeName}} SQL" [visible]="this.showAdvancedView && this.isImplementer" (onClick)="getAuditSQL()"></dx-button>
        <ng-container *ngIf="this.isProcessedOutput">
            <div>
                Displaying Processed Data
            </div>
        </ng-container>
        <ng-container *ngIf="this.auditPageMode">
            <svg height="48">
                <rect width="20" height="20" fill="white"></rect>
                <rect x="1" y="1" width="20" height="20" class="cell-consumed" stroke-width="2" stroke="black"></rect>
                <text x="30" y="15">Consumed</text>
                <rect x="1" y="26" width="20" height="20" fill="white"></rect>
                <rect x="1" y="26" width="20" height="20" class="cell-produced" stroke-width="2" stroke="black"></rect>
                <text x="30" y="41">Produced</text>
            </svg>
        </ng-container>
    </div>
</div>
<div id="output-record-section">
    <ag-grid-angular 
        #agGrid
        (gridReady)="onGridReady($event)"
        style="height: 600px;"
        class="ag-theme-balham"
        rowModelType="clientSide"
        rowSelection="single"
        (rowSelected)="onAgRowClick($event)"
        (columnPinned)="onColumnPinned($event)"
        (columnMoved)="onColumnMoved()"
        (filterChanged)="updatePinnedBottomRowData()"
        [rowData]="agRecords"
        [defaultColDef]="defaultColDef"
        [columnDefs]="agColumns"
        [columnTypes]="columnTypes"
        [suppressDragLeaveHidesColumns]="true"
        [suppressMakeColumnVisibleAfterUnGroup]="true"
        [suppressCellFocus]="true"
        [suppressAggFuncInHeader]="true"
        [suppressColumnVirtualisation]="false"
        [statusBar]="statusBar"        
        [animateRows]="true">
    </ag-grid-angular>
</div>
<dx-popup #sqlPopup [showCloseButton]="true" [(visible)]="auditSQLPopupVisible" title="{{SQLTypeName}} SQL" >
    <div id="sql-load-panel" *ngIf="!auditSQLStrings">
        <dx-load-indicator></dx-load-indicator>
    </div>

    <dx-tab-panel *ngIf="auditSQLStrings" id="sql-tab-panel">
        <dxi-item *ngFor="let SQLInfo of auditSQLStrings | keyvalue" title="{{SQLInfo.key}}">
            <dx-text-area id="sql-text-area" [value]="SQLInfo.value" [readOnly]="true" spellcheck="false"></dx-text-area>
            <dx-button class="clipboardButton" text="Copy query to clipboard" hint="Copy query to clipboard" icon="export" (onClick)="copySQL(SQLInfo.value)"></dx-button>
        </dxi-item>
    </dx-tab-panel>
</dx-popup>

<dx-popup #sqlPopup [showCloseButton]="true" [(visible)]="processLogPopupVisible" height="'700px'" title="Process Log" >
    <div>
        <app-process-log-grid [dateRange]="plvDateRange" [height]="'700px'"></app-process-log-grid>
    </div>
</dx-popup>