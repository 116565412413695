export const menuItemNames = {
    accountAttributes: 'Attributes',
    accountManagement: 'Accounts',
    analytics: 'Analytics',
    audit: 'Audit',
    createDashboard: 'Create Dashboard',
    dashboards: 'Dashboards',
    disputeManagement: 'Tickets',
    email: 'Email',
    etl: 'ETL',
    favorites: 'Favorites',
    fields: 'Fields',
    home: 'Home',
    plans: 'Plans & Rules',
    processing: 'Processing',
    processLogViewer: 'Logs',
    reports: 'Reports',
    setup: 'Setup',
    setupRules: 'Setup Rules',
    system: 'System',
    tagGroups: 'Tag Groups',
    whatIf: 'What-If',
    workflow: 'Workflow'
};

export const reservedEmailLayoutNames = [
    '<Report Email>',
    '<Dashboard Email>',
    '<Credentials Email>'
];

export const disputeSystemNames = ['Approved Adjustment', 'Submit Date', 'Resolve Date', 'Submitted By', 'Resolved By'];

export const dynamicXactionColumns = [
    { columnPattern: 'date_', dataType: 'datetime', sortValue: 3 }, // 0
    { columnPattern: 'qty_', dataType: 'number', sortValue: 4 }, // 1
    { columnPattern: 'tag_id_', dataType: 'string', sortValue: 2 }, // 2
    { columnPattern: 'text_', dataType: 'string', sortValue: 1 }, // 3
    { columnPattern: 'seller_id_', dataType: 'string', sortValue: 5 } // 4
];

export const yesNoArray = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
];

// This is the corresponding value for the '<blank>' option in the core dropdown component
export const coreDropdownBlankItemValue = -1;

// used on the processing page and in xaction service to append to the column name
export const importStr = ' (import)';

export const processAllPlanName = 'Process All';

export const defaultMappingNameValue = '<Default>';

export const toastrConstants = {
    lockedPeriodDelete: 'Locked periods cannot be deleted',
    lockedPeriodCalculationDelete: 'Affects calculations from locked periods which cannot be deleted',
    duplicatePeriod: 'Period not added: Another period for this recurrence exists with the same begin and end date',
    allowPopups: 'Please ensure popups are allowed, if the issue persists contact support',
    noSelectionForPopup: 'Please select records to view using the grid toolbar\'s check button',
    processingInProgress: 'Other processing is in progress, action can not be completed at this time',
    errorImportingFile: 'An error occurred and the file could not be imported',
    errorEditingXaction: 'An error occurred and the datasource could not be edited',
    planSegmentProcessingError: 'An error occurred while processing the Plan/Segment',
};
